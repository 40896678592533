import styled from "styled-components";
import { ReactComponent as BackSvg } from '../assets/back.svg';
import { ReactComponent as CloseSvg } from '../assets/close.svg';
import { QuestionMarkInCircleIcon as HelpIcon } from "src/assets/icons/system/system-icons";
import { openCalcExplanation } from "./CalcExplanation";
import { observer } from "mobx-react-lite";
import { gtmSend } from "src/helpers/url";
import store from "src/store/Store";

const  PersonHeader = observer(({
    onBack,
    onClose,
    title
}: {
    onBack: () => void
    onClose: () => void
    title?: string
}) => {
    const userId = store.sessionData?.id ?? ''

    const onHelp = () => {
      openCalcExplanation()
      gtmSend({ 'event': 'indi_persdescr_about', 'user_id': userId })
    }

    return <Container>
        <BackContainer onClick={onBack}><BackSvg /></BackContainer>
        <Title>{title}</Title>
        <HelpIcon onClick={onHelp}/>
        <CloseContainer onClick={onClose}><CloseSvg /></CloseContainer>
    </Container>
})

export default PersonHeader

const Container = styled.div`
    display: flex;
    padding: 0.75rem;
    align-items: center;
`

const BackContainer = styled.div`
    cursor: pointer;
    margin-right: 0.5rem;
`

const Title = styled.div`
    margin-right: auto;
    font-size: 0.875rem;
    color: var(--text-third);
`

const CloseContainer = styled.div`
    cursor: pointer;
    margin-left: 0.75rem;
`
