import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/ui/Button/Button';
import { Colors, PromocodeButton } from 'src/modules/promocode/PromocodeButton';
import styled from "styled-components";
import { history } from '../../../../MainRouter';
import Policy from "../../../../components/Policy";
import Loader from '../../../../components/ui/Loader';
import { Select } from '../../../../components/ui/Select';
import { gtmSend, searchParams } from '../../../../helpers/url';
import { IndiProductCode, PartnerLinkTargets, getUTMCommentForCP, show, toDateTime, PARTNER_TARGET } from '../../../../libs';
import { IGetSign, signsIcons } from '../../../../store/Astrology';
import { IProductCode } from "../../../../store/Payments";
import store from "../../../../store/Store";
import { windowsStore } from '../../../../store/Windows';
import {
  getPaymentIdLS,
  getProductDataLS,
  getPromocodeDataLS,
  setConfirmationStepLS
} from "../../../../store/localStorageHelpers";
import { ICard, cards, useCard } from '../../../payment/helpers/tariffs';
import { EMOTIONS, PERSON, SOLAR, SYNASTRY, WINDOW_ID_CONFIRMATION, WINDOW_ID_EMOTIONS, WINDOW_ID_PERSON, WINDOW_ID_SOLAR, WINDOW_ID_SUCCESS_PAGE, WINDOW_ID_SYNASTRY, productsWindowData } from '../../helpers/constants';
import { sendProductGtm } from '../../helpers/gtm';
import Emotions from '../../products/Emotions/Emotions';
import { Person } from '../../products/Person';
import Solar from '../../products/Solar/Solar';
import Synastry from '../../products/Synastry/Synastry';
import { SignResponseType } from "../../products/Synastry/types";
import PersonCard from './PersonCard';
import SuccessPage from './SuccessPage';


export interface ISynastryData {
  name1: string
  date1: string;
  time1: string;
  lat1: string;
  lon1: string;
  gmt1?: string;
  name2: string
  date2: string;
  time2: string;
  lat2: string;
  lon2: string;
  gmt2?: string;
}

interface IPartnersListProps {
  product: IProductCode
  lastStep: number
  setEdit(value: boolean): void
  setStep(value: number): void
  productTitle: string
}

export default observer(function Payment(props: IPartnersListProps) {
  const [signs, setSigns] = useState<number[] | null>(null);
  const [card, setCard] = useState(useCard());
  const [price, setPrice] = useState<string>(`${store.products.productData?.price?.value ?? getProductDataLS()?.price.value ?? 0}`);
  const [finalPrice, setFinalPrice] = useState<string>('')
  const [loading, setLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const freePayTimer = useRef<any>(null);

  const userId = store.sessionData?.id
  const headers = store.isAuth ? undefined : { tentative: store.auth.token }
  const email = store.payments.email || '';
  const partner = store.profile.partner;
  const { promoCode, promoData } = store.promocode;

  const sp = searchParams();
  const update = sp.get('update') === 'true';
  const purchasedProductId = sp.get('purchasedProductId');

  const promocode = useCallback(() => promoCode ?? getPromocodeDataLS()?.title, [promoCode])
  const promoId = () => promoData?.id ?? getPromocodeDataLS()?.id;

  const getDiscount = useCallback(() => {
    const storePromoDiscount = promoData?.actions?.discount;
    const lsPromoDiscount = getPromocodeDataLS().actions?.discount;

    if (storePromoDiscount && storePromoDiscount > 0 && storePromoDiscount < 1) {
      return storePromoDiscount
    }

    if (lsPromoDiscount && lsPromoDiscount > 0 && lsPromoDiscount < 1) {
      return lsPromoDiscount
    }

    if (
      store.partner.partnerDiscount?.products?.includes(props.product as IndiProductCode) ||
      store.partner.partnerDiscount?.originalTarget?.includes(PARTNER_TARGET.ChronosPlus)
    ) {
      return store.partner.partnerDiscount?.partnerDiscount
    }

    return 0
  }, [promoData?.actions?.discount, props.product])

  const isFreeCharge = () => {
    const storePromoDiscount = promoData?.actions?.discount!;
    const lsPromoDiscount = getPromocodeDataLS().actions?.discount;
    return storePromoDiscount === 1 || lsPromoDiscount === 1;
  }

  

  const getPrice = useCallback(async (product: IProductCode, promo: string, card: ICard) => {
    setIsWaiting(true);

    // captchaRef.current?.reset();
    // const captchaToken = await captchaRef.current?.executeAsync();

    const result = await store.payments.getPaymentConfig(product, promo, true, card.country, undefined, undefined, getData(product));

    if (result?.options.amount) {
      setPrice(`${result?.options.amount} ${card.symbol}`);

      let price = Math.round(result?.options.amount * (1 - getDiscount()) + Number.EPSILON);
      if (card.id !== 'ru') price = Math.round((result?.options.amount * (1 - getDiscount())) * 100) / 100;
      setFinalPrice(`${price} ${card.symbol}`)

      setIsWaiting(false);
    }
  }, [getDiscount])


  useEffect(() => {
    store.partner.setPartnerDiscount(PartnerLinkTargets.IndiProduct, partner)
  }, [partner])

  useEffect(() => {
    setIsWaiting(true);

    getSigns(getData(props.product));
    getPrice(props.product, promocode(), card);

  }, [card, props.product, getPrice, promocode]);

  const getData = (product: IProductCode): any[] => {
    switch (product) {
      case SYNASTRY:
        return store.synastry.getPartners()
      case EMOTIONS:
        return [store.emotions.getPerson()]
      case SOLAR:
        return [store.solar.getPerson()]
      case PERSON:
        return [store.personalityDescription.getPerson()]
    }
  }

  const getSigns = async (data: any[]) => {

    const getSignsData: IGetSign[] = []

    data.forEach((item, index) => {
      const { date, time } = toDateTime(item.dateTime);
      const lon = item.place!.lon
      const lat = item.place!.lat
      getSignsData.push({ date, time, lon, lat })
    })

    const result = await store.astrology.getSigns(getSignsData)
    const arrSigns = result.map((item: SignResponseType) => ({
      key: item.birthSign,
      icon: signsIcons[item.birthSign]
    }))

    setSigns(arrSigns)
  }

  const getFreeCharge = (product: IProductCode) => {
    const data = {
      paymentId: getPaymentIdLS() ?? store.payments.paymentId,
      promocodeTitle: promocode,
      language: 'ru',
      country: card.country,
      promoId: promoId(),
      hash: store.auth.hash,
      forUserEmail: email,
      comment: getUTMCommentForCP(),
      user_id: store.sessionData?.id
    }

    switch (product) {
      case SYNASTRY:
        store.synastry.freeChargeSynastry({
          ...data,
          ...store.synastry.getPartnersData(),
        }, headers)
          .then(() => success())
        break
      case EMOTIONS:
        store.emotions.freeChargeEmotions({
          ...data,
          ...store.emotions.getPersonData(),
        }, headers)
          .then(() => success())
        break
      case SOLAR:
        store.solar.freeChargeSolar({
          ...data,
          ...store.solar.getPersonData(),
        })
          .then(() => success())
        break
      case PERSON:
        store.personalityDescription.freeChargePerson({
          ...data,
          ...store.personalityDescription.getPersonData(),
        })
          .then(() => success())
        break
    }
  }

  const goToSuccessPage = (trial?: boolean) => {
    if (trial) {
      windowsStore.open(WINDOW_ID_SUCCESS_PAGE, <SuccessPage mode={'result'} />)
      return
    }

    windowsStore.open(WINDOW_ID_SUCCESS_PAGE, <SuccessPage mode={'payment'} />)
  }

  const getPaymentData = (product: IProductCode) => {
    switch (product) {
      case SYNASTRY:
        return store.synastry.getPartnersData()
      case EMOTIONS:
        return store.emotions.getPersonData()
      case SOLAR:
        return store.solar.getPersonData()
      case PERSON:
        return store.personalityDescription.getPersonData()
    }
  }

  const resetData = (product: IProductCode) => {
    switch (product) {
      case SYNASTRY:
        store.synastry.resetSynastry()
        break
      case EMOTIONS:
        store.emotions.resetEmotions()
        break
      case SOLAR:
        store.solar.resetSolar()
        break
      case PERSON:
        store.personalityDescription.resetPersonalityDescription()
        break
    }
  }

  const success = async () => {
    let attempt = 0;
    const promo = promocode()

    setLoading(true)

    if (promo) await store.promocode.usePromocode(promo, undefined, headers, email)

    if (freePayTimer.current) {
      clearTimeout(freePayTimer.current);
    }

    setIsWaiting(true);

    const send = async () => {
      freePayTimer.current = setTimeout(async () => {
        try {
          const res = await store.products.checkProduct(getPaymentIdLS() ?? store.payments.paymentId)
          if (res.isPaid) {
            await store.getProfile();
            history.push('/')
            windowsStore.close(WINDOW_ID_CONFIRMATION)

            switch (props.product) {
              case SYNASTRY:
                windowsStore.open(WINDOW_ID_SYNASTRY, <Synastry token={res.purchasedProductId} />, undefined, true)
                break
              case EMOTIONS:
                windowsStore.open(WINDOW_ID_EMOTIONS, <Emotions token={res.purchasedProductId} />, undefined, true)
                break
              case SOLAR:
                windowsStore.open(WINDOW_ID_SOLAR, <Solar token={res.purchasedProductId} />, undefined, true)
                break
              case PERSON:
                windowsStore.open(WINDOW_ID_PERSON, <Person token={res.purchasedProductId} />, undefined, true)
                break
            }

            setLoading(false)
            setIsWaiting(false)

            gtmSend({ 'event': `${productsWindowData[props.product].gtm}_payment-success_Payment`, 'user_id': userId ? userId : '' })
          } else {
            if (attempt === 5) {
              const id = store.promocode.promoData?.id ?? getPromocodeDataLS()?.id;
              id && store.promocode.cancelPromocode(id, undefined, headers);
              setIsWaiting(false);
              show({
                type: 'error',
                text: "Произошла ошибка. Обратитесь в службу поддержки support@chronos.mg",
                timeout: 5000
              });
            } else {
              attempt++
              send()
            }
          }
        } catch (error) {
          console.log(error)
          setIsWaiting(false);
        }
      }, 1000)
    }

    if (store.isAuth) {
      send()
    } else {
      goToSuccessPage()
    }

    setTimeout(() => {
      store.payments.resetPaymentConfig()
      store.promocode.resetPromocode()
      store.partner.resetPartner()
      resetData(props.product)
      setConfirmationStepLS('')
    }, 3000)
  }

  const submit = async () => {
    try {
      sendProductGtm(props.product, 'paywall_buy', userId)

      //setIsWaiting(true);

      store.payments.setProductCode(props.product);

      // captchaRef.current?.reset();
      // const captchaToken = await captchaRef.current?.executeAsync();

      const result = await store.payments.getPaymentConfig(
        props.product,
        promocode(),
        update,
        card.id,
        undefined,
        headers,
        getPaymentData(props.product)
      )

      if (!result) return;

      if (isFreeCharge()) {
        getFreeCharge(props.product)
      } else {
        const dataPost = {
          ...getPaymentData(props.product),
          hash: store.auth.hash,
          forUserEmail: email,
          comment: getUTMCommentForCP(),
        }

        store.payments.paymentCloud(
          {
            data: dataPost,
            success,
            update,
            purchasedProductId: purchasedProductId ? +purchasedProductId : undefined,
            discount: promocode() ? 0 : getDiscount(),
            headers,
            country: card.country
          }
        )
          .then(() => {
            gtmSend({ 'event': `${productsWindowData[props.product].gtm}_payment-start_Payment`, 'user_id': userId ? userId : '' })
          })
      }

      if (props.product === SYNASTRY) {
        if (store.synastry.trialSynastry) {
          gtmSend({ 'event': 'sinastry_payment-start_without-trial', 'user_id': userId ? userId : '' })
        } else {
          gtmSend({ 'event': 'sinastry_payment-start_after-trial', 'user_id': userId ? userId : '' })
        }
      }
    } catch (err) {
      console.error(err);
      const id = promoId();
      id && store.promocode.cancelPromocode(id, undefined, headers)
    } finally {
      setIsWaiting(false);
    }
  }

  const getTrial = async (product: string) => {
    switch (product) {
      case SYNASTRY:
        const dataPost = {
          ...store.synastry.getPartnersData(),
          hash: store.auth.hash,
          forUserEmail: email,
          comment: getUTMCommentForCP(),
        }
        const result = await store.synastry.getTrialSynastry(dataPost, headers)

        if (result?.paymentId) {
          if (store.isAuth) {
            store.payments.setPaymentId(result.paymentId)
            success()
            store.synastry.resetSynastry()
          } else {
            goToSuccessPage(true)
          }

        }

        break
    }
  }

  const onCardSelectHandler = (card: ICard) => {
    setCard(card)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      <PersonsCards>
        {
          getData(props.product).map((item, index) => {
            return <PersonCard
              key={`person${index + 1}_${item.dateTime}`}
              signs={signs}
              index={index}
              data={item}
              product={props.product}
              hasTitle={props.product !== PERSON}
              setStep={props.setStep}
              setEdit={props.setEdit}
            />
          })
        }
      </PersonsCards>

      <Footer>
        <Total>
          <div className={'title'}>Итоги</div>
          <div className={'description'}>
            <div className={'product'}>{props.productTitle}</div>
            <div>
              {
                isFreeCharge()
                  ? <>
                    <span className={'oldPrice'}>{price}</span>
                    <span className={'finalPrice'}>0</span>
                  </>
                  : <>
                    <span className={'oldPrice'}>{getDiscount() > 0 ? price : ''}</span>
                    <span className={'finalPrice'}> {parseFloat(price) > 0 ? finalPrice : ''}</span>
                  </>
              }
            </div>
          </div>
        </Total>

        <BuyAttributes>
          <div className="left side">
            <Select plain title="" options={cards} value={card} onSelect={onCardSelectHandler} />
          </div>
          <div className="right side">
            <PromocodeButton
              applied={!!promoData}
              disabled={isWaiting}
              color={Colors.GRAY}
              product={props.product}
            />
          </div>
        </BuyAttributes>

        <StyledButton
          onClick={submit}
          color={'green'}
          disabled={isWaiting}
        >
          <ButtonContent>
            <Price>
              {
                isFreeCharge()
                  ? <span>Получить продукт</span>
                  : <>
                      <span>Купить за</span>
                      <span className={'finalPrice'}> {finalPrice}</span>
                    </>
              }
            </Price>
          </ButtonContent>
        </StyledButton>

        {
          props.product === SYNASTRY && (
            <StyledButton
              onClick={() => getTrial(props.product)}
              color={'black'}
              disabled={isWaiting}
            >
              <ButtonContent>Пробная версия</ButtonContent>
            </StyledButton>
          )
        }

        <StyledPolicy country={card.id} fixedPosition={false} />
      </Footer>
    </Container>
  );
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-white);
  height: 2.3rem;
  font-size: 0.8rem;
  letter-spacing: 0.2px;
  margin-top: 0.1rem;

  & svg {
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`
export const Price = styled.span`
  span {
    color: var(--text-primary);
    font-size: 0.875rem;
    font-weight: 500;
    margin-right: 0.2rem;
  }

  .oldPrice {
    text-decoration: line-through;
  }
`

export const PaymentWarning = styled.div`
  font-weight: 400;
  text-align: left;
  color: var(--orange-color);
`

const PersonsCards = styled.div`
  padding-bottom: 1rem;
`

const Footer = styled.div`
  width: calc(100%);
  max-width: 550px;
  position: relative;
`

const Total = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    color: var(--text-secondary);
  }

  .description {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 0.5rem;
  }

  .product {
    font-weight: 500;
  }

  .oldPrice {
    color: var(--text-third);
    font-size: 1.5rem;
    text-decoration: line-through;
    margin-right: 0.5rem;
  }

  .finalPrice {
    font-size: 1.5rem;
    font-weight: 500;
  }
`

const BuyAttributes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;

  margin-top: 1rem;
  position: relative;

  .side {
    flex: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }
`

const StyledButton = styled(Button)`
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  color: var(--text-primary);
`

const StyledPolicy = styled(Policy)`
  margin-top: 0.5rem;
  width: 100%;
`
