import { makeAutoObservable } from 'mobx';
import api from "../../helpers/api";
import { Indi } from '../../libs';

interface PostLeads {
  type: Indi.IndiLeads.Leads,
  request?: Indi.IndiLeads.Spheres,
  requestSubject?: string,
}

export class Banners {
  constructor() {
    makeAutoObservable(this)
  }

  async getBanners(userId: number) {
    try {
      const result = await api.indiGetBanners({userId})
      return result
    } catch(e) {
      console.error(`Getting Banners error: ${e}`)
    }
  }

  async putBanner(userId: number, bannerId: Indi.IndiBanners.BannersId) {
    try {
      const dataPut = {
        userId,
        bannerId,
      }

      await api.indiPutBanners(dataPut)
    } catch(e) {
      console.error(`Putting Banner error: ${e}`)
    }
  }

  async postLeads(data: PostLeads) {
    try {
      await api.indiPostLeads(data)
    } catch(e) {
      console.error(`Posting Leads error: ${e}`)
    }
  }
}
