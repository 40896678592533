import { ReactNode } from "react"
import styled, { css } from "styled-components"

interface BlurProps {
  className?: string
  isBlur: boolean
  children: ReactNode
  onClick?: () => void
}

export const Blur = (props: BlurProps) => {
  const {
    className = '',
    isBlur,
    children,
    onClick,
  } = props

  return (
    <Container
      className={className}
      isBlur={isBlur}
      onClick={onClick}
    >
      {children}
    </Container>
  )
}

export const Container = styled.div<{ isBlur: boolean }>`
  ${p => p.isBlur && css`
    filter: blur(4px);
    cursor: pointer;
  `}
`
