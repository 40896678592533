import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import ym from "react-yandex-metrika";
import Policy from "src/components/Policy";
import { Button } from "src/components/ui/Button/Button";
import { Select } from "src/components/ui/Select";
import api, { getHTTPMessage } from "src/helpers/api";
import { gtmSend } from "src/helpers/url";
import { getUTMCommentForCP, show } from "src/libs";
import { cards, ICard } from "src/modules/payment/helpers/tariffs";
import { TrialClose } from "src/modules/paywall/components/Close/TrialClose";
import { PromocodeButton } from "src/modules/promocode/PromocodeButton";
import { getBannersLS, setBannersLS } from "src/store/localStorageHelpers";
import { popupsStore } from "src/store/Popups";
import store from "src/store/Store";
import { windowsStore } from "src/store/Windows";
import styled, { css } from "styled-components";
import { history } from "../../../MainRouter";
import bell from "./assets/bell.png";
import logo from "./assets/cards.png";
import lock from "./assets/Lock.png";
import star from "./assets/star.png";


export const WINDOW_SUBSCRIBE_BANNER_WITH_CARD = 'WINDOW_SUBSCRIBE_BANNER_WITH_CARD';

export const AUTOTEST_EMAILS = process.env.REACT_APP_AUTOTEST_EMAILS?.replace(/\s/g, '').split(',');

const TRIAL_PERIOD_DEFAULT = 7
const EXTRA_DAYS = 7

export const openSubscribeBannerWithCard = (trialPeriod?: number) => {
  windowsStore.open(
    WINDOW_SUBSCRIBE_BANNER_WITH_CARD,
    <SubscribeBannerWithCard trialPeriod={trialPeriod} />,
    'fade'
  )
}

interface SubscribeBannerWithCardProps {
  trialPeriod?: number
}

export const SubscribeBannerWithCard = observer(function (props: SubscribeBannerWithCardProps) {
  const {
    trialPeriod = TRIAL_PERIOD_DEFAULT
  } = props

  const [ card, setCard ] = useState(cards[0])
  const [ isWaiting, setIsWaiting ] = useState(false)

  const userId = store.sessionData?.id || undefined;
  const email = store.profile.email || '';
  const isAutotest = AUTOTEST_EMAILS?.includes(email || '');
  const userStatus = store.auth.userStatus;
  const promoData = store.promocode.promoData
  const tariff = store.events.tariffs[0]
  const price = tariff.price
  const oldTrialEnd = dayjs(store.sessionData?.indi?.trialExpiryDate).add(trialPeriod, 'day')
  const newTrialEnd = oldTrialEnd.add(EXTRA_DAYS, 'day');

  const formatDate = (date: dayjs.Dayjs) => date.locale('ru').format('D MMM');

  const list = [
    {
      title: 'Продление пробного периода',
      subtitle: 'Неограниченный доступ к прогнозу на 7 дней',
      date: formatDate(oldTrialEnd),
      icon: lock,
    },
    {
      title: 'Напоминание об окончании',
      subtitle: 'Напомним по почте, сможете отменить подписку',
      date: formatDate(oldTrialEnd.add(5, 'day')),
      icon: bell,
    },
    {
      title: 'Конец пробного периода',
      subtitle: 'Спишем плату за месяц, если не отмените подписку',
      date: formatDate(oldTrialEnd.add(7, 'day')),
      icon: star,
    },
  ];

  useEffect(() => {
    gtmSend({event: 'indi_freetrial_7dextensioncard_view', user_id: userId })
  }, [userId]);

  useEffect(() => {
    store.events.loadTariffs(card.country)
  }, [card])

  const onCardSelectHandler = (card: ICard) => {
    store.events.loadTariffs(card.country)
    setCard(card)
  }

  const closePaymentPaywall = async () => {
    gtmSend({ event: 'indi_prognos_trialpage_free7_click', user_id: userId })
    await store.trial.getTrialWithoutCard(7)
    await store.refresh()
    popupsStore.closeAll()
    store.promocode.resetPromocode()
    history.push({pathname: '/'})
    window.location.reload()
  }

  const setTelegramAndExtendedTrialDateBanners = (expiryDate: string) => {
    const banners = getBannersLS()

    const newBanners = {
      ...banners,
      extendedTrialDateBanner: {
        expiryDate: expiryDate
      },
      telegramBanner: {
        show: true
      }
    }

    setBannersLS(newBanners)
  }

  const onPayment = async () => {
    setIsWaiting(true)

    gtmSend({event: 'indi_freetrial_7dextensioncard_ok', user_id: userId });
      ym('reachGoal','indi_paywall_trial-start_click')

      if (promoData) {
        const result = await store.promocode.usePromocode(promoData.title, undefined, undefined, email)
        const isFreeCharge = result.subscription === 1 || result.subscription === 2
        if (isFreeCharge) {
          closePaymentPaywall()
          return
        }
      }

      if (isAutotest) {
        await api.indiActivateTrialForTest(userId, email);
        closePaymentPaywall();
        return;
      }

      try {
        const data = {
          type: 'indiTrial',
          trialPeriod: trialPeriod + EXTRA_DAYS,
          subscription: tariff.id,
          period: tariff.period,
          country: card.id,
          language: 'ru',
          userStatus,
          forUserEmail: email,
          comment: getUTMCommentForCP(),
          promocodeTitle: promoData?.title
        }

        const result = await api.payment(data, 'auth');

        if (!result.failed) {
          await store.refresh();
          const expiryDate = store.sessionData?.indi?.trialExpiryDate ?? '';
          setTelegramAndExtendedTrialDateBanners(expiryDate)
          closePaymentPaywall();
        }

        if (result.failed) {
          promoData && store.promocode.cancelPromocode(promoData.id, undefined)

          show({
            type: 'error',
            text: getHTTPMessage(result?.error?._errors),
          });
        }
      } catch (e) {
        promoData && store.promocode.cancelPromocode(promoData.id, undefined)
        console.error(`Payment trial error: ${e}`)
      }

    setIsWaiting(false)
  }

  const handleClose = async () => {
    gtmSend({ event: 'indi_prognos_trialpage_free7_click', user_id: userId })
    await store.trial.getTrialWithoutCard(trialPeriod)
    await store.refresh()
    windowsStore.close(WINDOW_SUBSCRIBE_BANNER_WITH_CARD);
    window.location.reload()
  };

  return (
    <PaymentPaywallTariffsContainer>
      <Header>
        <div className="controls"><TrialClose onClick={handleClose} /></div>
        <img src={logo} alt="logo" />
        <div className="title">Привяжите карту и получите <br/>
        <span>+{EXTRA_DAYS} дней пробного периода</span>
        </div>
      </Header>

      <Main>
        <List>
          {
            list.map((item, index) => (
              <Item key={index}>
                <IconLine>
                   <img src={item.icon} alt="icon" />
                   {index < list.length - 1 && <div className="vertical-line" />}
                </IconLine>
                <div className="description">
                  <div className="title-wrapper">
                   <div className="title">{item.title}</div>
                   <span className='item-date'>{item.date}</span>
                  </div>
                  <div className="subtitle">{item.subtitle}</div>
                </div>
              </Item>
            ))
          }
        </List>
        <div className="buy-attributes">
          <div className="left side">
            <Select plain title="" options={cards} value={card} onSelect={onCardSelectHandler} />
          </div>
          <div className="right side">
            <PromocodeButton subscription applied={!!promoData} disabled={isWaiting} />
          </div>
        </div>

        <div className="caption">
          Пробный период до {formatDate(newTrialEnd)}, далее {price} ₽/мес,отменить можно в любой момент
        </div>
      </Main>

      <Footer>
        <StyledButton onClick={onPayment} color="green" disabled={isWaiting}>Привязать карту</StyledButton>

        <StyledPolicy
          country={card.id}
          action={<span>Нажимая «Привязать карту»</span>}
          fixedPosition={false}
          bottom={10}
        />
      </Footer>
    </PaymentPaywallTariffsContainer>
  );
});

const PaymentPaywallTariffsContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem 1rem;
  background: var(--color-white);
  gap: 0.5rem;
  padding-bottom: 4.5rem;
  overflow: hidden auto;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 30%;
  flex-shrink: 0;
  flex-grow: 0;

  gap: 0.5rem;

  & .controls {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  & .title {
    max-width: 100%;
    margin-top: auto;
    margin-bottom: 0rem;
    font-size: 1.5rem;
    line-height: 1.1;
    text-align: center;

    & > span {
      font-size: 2rem;
      background-image: linear-gradient(to right, #7D79EA, #D646D9, #E8AA63);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  flex: 55%;
  /* overflow-y: scroll; */

  & .future-info {
    position: relative;
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.313rem;
    font-weight: 400;
    color: var(--text-secondary);
  }

  & .buy-attributes {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 0.25rem;

    & .side {
      flex: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  & .caption {
      width: 100%;
      margin-bottom: 1rem;

      font-size: 0.688rem;
      color: var(--text-secondary);
      text-align: center;
    }
`;

const Item = styled.li`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;

  &:first-child img {
    background-color: #000000B2;
  }

  .description {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.22rem;
    }

    .subtitle {
      font-size: 0.875rem;
      color: var(--text-secondary);
      margin-bottom: 0.5rem;
      max-width: 80%;
    }
    .item-date {
      display: inline-block;
      white-space: nowrap;
      background-color: #fff;
      border-radius: 32px;
      padding: 4px 8px;

      box-shadow:
      0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
      0px 3.2px 7.2px rgba(0, 0, 0, 0.13);

      font-size: 0.75rem;
      color: #000;
    }
  }
`;

const IconLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }

  .vertical-line {
    width: 2px;
    background-color: #00000033;
    margin-top: 0.5rem;
    flex-grow: 1;
  }
`
const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 15%;
  flex-shrink: 0;
  flex-grow: 0;

  text-align: center;
`;

const List = styled.ul`
  margin-top: 2.5rem;
`;
const StyledButton = styled(Button)<{ disabled: boolean }>`
  color: var(--text-primary);

  ${p => p.disabled && css`
    opacity: 0.2;
    pointer-events: none;
  `}
`;

const StyledPolicy = styled(Policy)`
  font-size: 0.688rem;
  line-height: 1.3;
`;
