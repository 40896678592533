import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { ButtonContent } from "src/components/ui/Button/ButtonContent"
import { CloseButton } from "src/components/ui/CloseButton"
import { gtmSend } from "src/helpers/url"
import { openConsultationSignUp } from "src/modules/consultation-sign-up/ConsultationSignUp"
import { getAppLS, getBannersLS, setBannersLS } from "src/store/localStorageHelpers"
import store from "src/store/Store"
import styled from "styled-components"
import { ChevronRightIcon } from '../../../assets/icons/system/system-icons'
import { Banner } from "../Banner/Banner"
import bg from "./assets/bg.jpg"
import { Indi } from "../../../libs"

export const SignUpConsultationBanner = observer(() => {
  const [show, setShow] = useState(false)
  const [send, setSend] = useState(false)

  const userId = store.sessionData?.id
  const phoneNumber = store.profile.phoneNumber

  useEffect(() => {
    const appLS = getAppLS()
    const consultationBannerLS = getBannersLS()?.consultationBanner

    if (
      consultationBannerLS?.signedUp ||
      (consultationBannerLS?.hidden && consultationBannerLS?.hidden > 1 ) ||
      consultationBannerLS?.sessionNumber === appLS.sessionCount
    ) return

    setShow(true)
  }, [])

  const getTitle = () => {
    if (send) {
      return 'Заявка на консультацию отправлена!'
    } else {
      return 'Консультация, которая поможет лучше понять себя'
    }
  }

  const getText = () => {
    if (send) {
      return 'Менеджер Chronos скоро с вами свяжется'
    } else {
      return 'Разберите ключевые аспекты вашей жизни и получите план действий'
    }
  }

  const getButtonContent = () => {
    if (!send) {
      return (
        <ButtonContent
          title={'Записаться на консультацию'}
          icon={<ChevronRightIcon/>}
        />
      )
    }
  }

  const signUpConsultation = async (addedNumber?: boolean) => {
    try {
      const dataPost = {
        type: Indi.IndiLeads.Leads.PROFILE_CONSULTATION,
        requestSubject: 'Описание личности',
      }

      await store.banners.postLeads(dataPost)

      const bannersLS = getBannersLS()

      const newBannersLS = {
        ...bannersLS,
        consultationBanner: {
          ...bannersLS?.consultationBanner,
          signedUp: true,
        }
      }

      setBannersLS(newBannersLS)

      setSend(true)

      gtmSend({
        'event': addedNumber ? 'indi_persdescr_consult_number_done' : 'indi_persdescr_consult',
        'user_id': userId ? userId : ''
      })
    } catch(e) {
      console.error(`Sign up a consultation error: ${e}`)
    }
  }

  const onClick = () => {
    if (phoneNumber) {
      signUpConsultation()
    } else {
      openConsultationSignUp(
        () => signUpConsultation(true),
        'Запись на консультацию'
      )
    }
  }

  const onClose = () => {
    const bannersLS = getBannersLS()
    const hidden = bannersLS?.consultationBanner?.hidden ? 2 : 1
    const sessionNumber = getAppLS()?.sessionCount ?? 1

    const newBannersLS = {
      ...bannersLS,
      consultationBanner: {
        hidden,
        sessionNumber,
      }
    }

    setBannersLS(newBannersLS)

    setShow(false)
  }

  if (!show) return null

  return (
    <Container>
      <StyledBanner
        title={getTitle()}
        text={getText()}
        buttonContent={getButtonContent()}
        onClick={onClick}
        backgroundImg={bg}
        buttonColor={'blue'}
      />

      <StyledCloseButton top={1} right={1} onClose={onClose} />
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  position: relative;
`

const StyledCloseButton = styled(CloseButton)`
  svg {
    color: var(--text-third);
  }
`

const StyledBanner = styled(Banner)<{ rectificationSent?: boolean }>`
  .title {
    color: var(--text-primary);
    width: 100%;
  }

  .text {
    color: var(--text-secondary);
    font-weight: 500;
  }

  .banner_button {
    margin-top: 1.25rem;
    width: fit-content;

    svg {
      color: var(--color-white);
    }
  }

  @media (max-width: 400px) {
    .title {
      width: 90%
    }
  }
`
