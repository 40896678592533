import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import { Logging } from 'src/libs';
import styled from 'styled-components';
import { history } from '../../MainRouter';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { searchParams } from '../../helpers/url';
import { useMoveShutter } from '../../hooks/popup-hooks';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { WindowHeader } from '../windows/components/WindowHeader';
import Confirmation from './Confirmation';
import Payment from './Confirmation/Payment';
import PersonalData from './Confirmation/PersonalData';
import ProfessionalDetails from './ProfessionalDetails';
import Professionals from './Professionals';
import ServiceConfirmation from './ServiceConfirmation';
import Services from './Services';
import SessionDetails from './SessionDetails';


export const WINDOW_ID_CONSULTATIONS: string = 'WINDOW_ID_CONSULTATIONS';

export const openConsultations = () => windowsStore.open(
  WINDOW_ID_CONSULTATIONS,
  <Consultations />,
  undefined,
  false,
  true,
)

export interface IServiceRoute {
  path: string;
  title?: string;
}

export const servicesRoutes: {
  [key: string]: IServiceRoute;
} = {
  Services: {
    path: '/services',
    title: 'Выбор услуги'
  },
  ServiceSelect: {
    path: '/services/:id',
    title: 'Выбор услуги'
  },
  Professionals: {
    path: '/professionals',
    title: 'Выбор астролога'
  },
  Professional: {
    path: '/professional/:id',
    title: 'Выбор астролога'
  },
  ProfessionalSelect: {
    path: '/professional/:id/:direct_link',
    title: 'Выбор астролога'
  },
  ServiceConfirmation: {
    path: '/service-confirmation',
    title: 'Выбор услуги'
  },
  Confirmation: {
    path: '/confirmation',
    title: 'Выбор времени'
  },
  Phone: {
    path: '/phone',
    title: 'Подтверждение телефона'
  },
  PersonalData: {
    path: '/personal-data',
    title: 'Персональные данные'
  },
  Payment: {
    path: '/payment',
    title: 'Оформление покупки'
  },
  TinkoffPayed: {
    path: '/tinkoff-payed/:id/:status',
    title: 'Покупка'
  },
  Session: {
    path: '/session/:id',
    title: 'Консультация'
  },
  CommonChoice: {
    path: '/common-choice',
    title: 'Общий выбор'
  }
};

export const CONSULTATION_MODAL = 'consultation_modal'

export const Consultations = observer(() => {
  // useBackWindow(ROUTES.SERVICES, WINDOW_ID_CONSULTATIONS, { exact: true });

  const [title, setTitle] = useState('Консультация')

  const containerRef = useRef<HTMLDivElement | null>(null)

  const location = useLocation()

  const shutterRef = store.shutter.shutterRef

  useMoveShutter(containerRef, shutterRef)

  const sp = searchParams()

  const updateUrl = () => {
    let key: string
    for (key in servicesRoutes) {
      const regexp = new RegExp(servicesRoutes[key].path.split('/')[1])
      const match = history.location.pathname.slice(1).match(regexp)

      if (match) return
    }

    history.push({pathname: '/services', search: sp.toString()})
  }

  useEffect(() => {
    updateUrl()
    document.title = `Консультации • Выбор услуги`;
    store.logger.startLoggingSession(Logging.Screens.CONSULTATIONS)

    return () => {
      history.push({pathname: '/', search: sp.toString()})
      store.logger.createLoggingSession(Logging.Screens.CONSULTATIONS)
    }
  }, [])

  const toggleShutter = useCallback(() => {
    const modal = sp.get('modal') === CONSULTATION_MODAL

    if (modal) return

    if (window.location.pathname.indexOf('services') !== -1 ) {
      store.shutter.showShutter()
    } else {
      store.shutter.hideShutter()
    }
  }, [sp])

  useEffect(() => {
    toggleShutter()
  }, [location, toggleShutter])

  const PageTitleProvider = () => {
    const location = useLocation();

    React.useEffect(() => {
      const [, currentPath = '', id] = location.pathname.split('/');
      const route: IServiceRoute = Object.values(servicesRoutes).find(({ path }) => path.split('/')[1] === currentPath)!;
      if (route?.title) {
        document.title = `Консультации • ${route.title}`;
        setTitle(route.title)
      }
    }, [location]);

    return (null);
  };

  const goBackHandler = () => {
    history.goBack();
  }

  return (
    <WindowContainer ref={containerRef}>
      <WindowHeader title={title} onBack={window.location.pathname.indexOf('services') !== -1 ? undefined : goBackHandler}/>
      <Container>
        <PageTitleProvider/>
        <Router history={history}>
          <Switch>
    				{/* <Redirect from='/' exact to={{pathname: servicesRoutes.Services.path, search: sp.toString()}}/> */}
            <Route path='/' exact component={Services}/>

            <Route path={servicesRoutes.Services.path} exact component={Services}/>
            <Route path={servicesRoutes.ServiceSelect.path} exact component={Services}/>
            <Route path={servicesRoutes.Professionals.path} exact component={Professionals}/>
            <Route path={servicesRoutes.Professional.path} exact component={ProfessionalDetails}/>
            <Route path={servicesRoutes.ProfessionalSelect.path} exact component={ProfessionalDetails}/>
            <Route path={servicesRoutes.ServiceConfirmation.path} exact component={ServiceConfirmation}/>
            <Route path={servicesRoutes.Confirmation.path} exact component={Confirmation}/>
            <Route path={servicesRoutes.PersonalData.path} exact component={PersonalData}/>
            <Route path={servicesRoutes.Payment.path} exact component={Payment}/>
            <Route path={servicesRoutes.Session.path} exact component={SessionDetails}/>
    				{/*<Route path={servicesRoutes.TinkoffPayed} exact component={TinkoffPayed}/>
    				<Route path={servicesRoutes.CommonChoice} exact component={CommonChoice}/> */}
          </Switch>
        </Router>
      </Container>
    </WindowContainer>
  )
})

const Container = styled(MainPadding)`
  padding-bottom: 8rem;
`
