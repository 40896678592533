import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { FullMoon, GrowingMoon1, GrowingMoon2, NewMoon, WaningMoon1, WaningMoon2 } from "../../../assets/icons/moon/moon-icons"
import { gtmSend } from "../../../helpers/url"
import store from "../../../store/Store"
import { openMoonPhase } from "../../moon-phase/moon-phase"
import { gmtLocal } from "../../prognoses/prognoses-daily"
import { Indi, MoonPhases } from "../../../libs"
import { openSubscribeBanner } from "src/modules/subscription/SubscribeBanner/SubscribeBanner"

interface MainPageWidgetMoonProps {
  className?: string
  day: string
  onClick?(): void
}

const titles = {
  [Indi.GetMoon.MoonPhase.NEW]: 'Новолуние',
  [Indi.GetMoon.MoonPhase.GROWING]: 'Растущая Луна',
  [Indi.GetMoon.MoonPhase.FULL]: 'Полнолуние',
  [Indi.GetMoon.MoonPhase.DECREASING]: 'Убывающая Луна'
}

export const MainPageWidgetMoon = observer((props: MainPageWidgetMoonProps) => {
  const [moon, setMoon] = useState<Indi.GetMoon.Response | null>(null)
  const dayData = store.prognoses.getDay(dayjs(props.day).local())
  const userId = store.sessionData?.id
  const isTrialEnded = store.events.status === 'pause'
  const getMoon = async () => {
    let result
    if(dayjs(props.day).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')){
      result = await store.prognoses.getMoon(dayjs().toISOString(), gmtLocal)
    } else {
      const dayMinusGMT = dayjs(props.day).subtract(gmtLocal, 'hour').toISOString();
      result = await store.prognoses.getMoon(dayMinusGMT, gmtLocal)
    }
    setMoon(result)
  }

  useEffect(() => {
    getMoon()
  }, [props.day])

  const grtMoonImage = () => {
    if (moon?.phase === Indi.GetMoon.MoonPhase.NEW) return <NewMoon />
    if (moon?.phase === Indi.GetMoon.MoonPhase.FULL) return <FullMoon />

    if (moon?.phase === Indi.GetMoon.MoonPhase.GROWING) {
      if (moon.day < 8) {
        return <GrowingMoon1 />
      } else {
        return <GrowingMoon2 />
      }
    }

    if (moon?.phase === Indi.GetMoon.MoonPhase.DECREASING) {
      if (moon.day < 22) {
        return <WaningMoon1 />
      } else {
        return <WaningMoon2 />
      }
    }
  }

  const openMoonPopup = () => {
    if (isTrialEnded) return openSubscribeBanner()
    if (!dayData) {
      gtmSend({ 'event': 'indi_astroevent_moon', 'user_id': userId ? userId : '' })
      props.onClick?.()
      return
    }

    openMoonPhase(dayData.id, moon?.phase as MoonPhases | undefined)

    gtmSend({ 'event': 'ind_prognosis_main-page_moon-widget-click', 'user_id': userId ? userId : '' })
  }

  return (
    <Container className={`astroEvent ${props.className ?? ''}`} onClick={openMoonPopup}>
      <div>
        <div className='subtitle'>{moon?.day ?? ''} лунные сутки</div>
        <div className='title'>{moon?.phase && titles[moon.phase]}</div>
      </div>
      <div>
        {grtMoonImage()}
      </div>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  background: var(--color-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  padding: 1rem;

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 0.25rem;
  }

  .subtitle {
    font-size: 0.75rem;
    font-weight: 500;
  }
`
