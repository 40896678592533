import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import store from '../../../store/Store';
import { TUserState } from '../helpers/states';
import { openSmsAuthWindow } from 'src/modules/registration/sms/SmsAuth';

export const MainPageHeaderLoginButton = observer(({
  state
}: {
  state: TUserState
}) => {
  const trialExpiryDate = store.sessionData?.indi?.trialExpiryDate;
  const trialActive = trialExpiryDate && dayjs().isBefore(trialExpiryDate);

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(state.key === 'unlogin') {
      openSmsAuthWindow();
      e.stopPropagation();
    }
  }

  let stateTitle = state.title;
  if(state.key === 'active' && trialActive) {
    stateTitle = 'Пробный период'
  }

  return <Container onClick={onClick}>
    {state.color && <Circle color={state.color}></Circle>}
    {stateTitle}
  </Container>
})

const Container = styled(FlexContainer)`
  align-items: center;
  border: 1px solid var(--element-border-10);
  border-radius: 40px;
  padding: 0.375rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
  /* line-height: 100%; */
  white-space: nowrap;
  cursor: pointer;
`

const Circle = styled.div<{color: string}>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.375rem;
  background-color: ${p => p.color}
`
