import { observer } from "mobx-react-lite"
import styled, { css } from "styled-components"
import { Button } from "../../components/ui/Button/Button"
import background from "../../assets/images/notifications/notifications_modal_bg.jpg"
import { Modal } from "../../components/Modal"
import { isMobile } from 'src/utils';
import store from "src/store/Store"
import { useEffect } from "react"
import { gtmSend } from "src/helpers/url"


export const NotificationsModal = observer(() => {
  const userId = store.sessionData?.id || '';

  useEffect(() => {
    store.shutter.hideShutter()

    return () => {
      store.shutter.showShutter()
    }
  }, [])

  const turnOn = () => {
    gtmSend({ 'event': 'indi_push_permission_on', 'user_id': userId ? userId : '' })
    store.webPush.initWebPush()
    userId && store.webPush.setModal(userId, true)
    store.modal.showHandler.notificationsModal = false
  }

  const onClose = () => {
    gtmSend({ 'event': 'indi_push_permission_not_now', 'user_id': userId ? userId : '' })
    store.modal.showHandler.notificationsModal = false
    userId && store.webPush.setModal(userId, true)
  }

  return (
    <StyledModal isMobile={isMobile}>
      <Container background={background}>
        <Content>
          <Description>
            <p className={'title'}>Включите напоминания о самом важном</p>
            <p>Напомним вам о важных событиях и ежедневных прогнозах. Вы всегда можете отключить уведомления в настройках</p>
          </Description>

          <Footer>
            <Button
              onClick={turnOn}
            >
              Включить уведомления
            </Button>

            <Button
              onClick={onClose}
              color={'gray'}
            >
              Не сейчас
            </Button>
          </Footer>
        </Content>
      </Container>
    </StyledModal>
  )
})

const StyledModal = styled(Modal)<{isMobile: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

	width: ${isMobile ? '100vw' : '100%'};
  background: var(--color-white);
`

const Container = styled.div<{background: string}>`
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;

  max-width: 430px;
  height: 100dvh;
  min-height: 700px;

  ${p => p.background && css`
    background-image: url(${p.background});
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: cover;
  `};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 50vh;
  padding: 1rem;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  text-align: center;
  padding: 0 1rem;

  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  width: 100%;
`
