import { observer } from 'mobx-react-lite';
import { formatNumber } from 'src/helpers/numbers';
import styled from "styled-components";
import { ArrowRight } from '../../../assets/icons/system/system-icons';
import { GetPartnerDiscount, PARTNER_TARGET } from '../../../libs';
import { IProduct } from '../../../store/Products';

function ProductCard(props: {
  data: IProduct
  onClick(): void
	discount?: GetPartnerDiscount.Response
}) {

  const getDiscount = () => {
    if (props.discount?.originalTarget?.includes(PARTNER_TARGET.ChronosPlus)) {
      return props.discount?.partnerDiscount
    }

    if (props.discount?.products?.includes(props.data.code)) {
      return props.discount?.partnerDiscount
    }

    return 0
  }

	const finalPrice = Math.round(props.data.price.value * (1 - getDiscount()) + Number.EPSILON)

  return (
    <ProductCardContainer onClick={props.onClick}>
      <Header>
        {props.data.emoji && <IconContainer background={props.data.emoji.background}>
            <Emoji>{props.data.emoji.text}</Emoji>
        </IconContainer>}

        <Price>
					<span className={'oldPrice'}>{getDiscount() > 0 ? formatNumber(props.data.price?.value) : ''}</span>
          <span className={'finalPrice'}> {formatNumber(finalPrice)}</span>
          <Currency> ₽</Currency>
        </Price>
      </Header>
      <Content>
        <Title>{props.data.name}</Title>
        <Description>{props.data.description}</Description>
        <CTA>Продолжить <ArrowRight /></CTA>
      </Content>
    </ProductCardContainer>
  )
}

export default observer(ProductCard);

export const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem;
  background: #FFFFFF;
  box-shadow: 0 3.2px 14px rgba(0, 0, 0, 0.06), 0 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  margin-bottom: 16px;
  transition: all 0.2s ease;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
  position: relative;

  :active {
    background: rgba(0, 0, 0, 0.04);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: 18px;
  color: #4760E7;;
`;

export const IconContainer = styled.div<{ background: string; }>`
  width: 32px;
  height: 32px;
  background: ${props => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background 0.2s ease;
  margin-right: 1rem;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const Emoji = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: Arial;
`;

const Price = styled.p`
  margin: 0 0 0 auto;
  color: rgba(71, 96, 231, 1);
  font-size: 18px;

  & + span {
    margin-left: 5px;
  }

  .oldPrice {
    text-decoration: line-through;
  }
`;

const Currency = styled.span`
  font-weight: normal;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 22px;
  margin-top: 0.5rem;
`;

const CTA = styled.div`
  color: rgba(71, 96, 231, 1);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 14px;
  margin-top: 0.875rem;

  > svg {
    width: 24px;
    height: 24px;

    > path {
      fill: rgba(71, 96, 231, 1);
    }
  }
`
