import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TWindow, windowsStore } from '../../store/Windows';
import {CSSTransition} from 'react-transition-group';
import { isMobile } from 'src/utils';
import { DESKTOP_WIDTH, DESKTOP_GAP } from '../../helpers/ui';

export const Windows = observer(() => {
  const windows = windowsStore.getWindows();
  const nodeRef = useRef(null);

  const Container = isMobile ? Mobile : Desktop;

  // useEffect(() => {
  //   if(windows.length > 0) disableBodyScroll(nodeRef.current!);
  //   else enableBodyScroll(nodeRef.current!);
  // }, [windows.length])

  return <Container id="windows" ref={nodeRef}>
    {windows.map((window, idx) => <Window window={window} key={`${window.id}_${window.depth}`} />)}
  </Container>
})

const Window = ({
  window
}: {
  window: TWindow
}) => {
  const nodeRef = useRef(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const animation  = window.animate === 'fade' ? 'fade-animate' : 'right-animate';

  return <CSSTransition nodeRef={nodeRef} in={visible} timeout={500} classNames={animation} unmountOnExit>
    <WindowContainer ref={nodeRef} depth={window.depth}>
      {window.content}
    </WindowContainer>
  </CSSTransition>
}

const Mobile = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 10;

  @media print {
    position: absolute;
    width: 100% !important;
  }
`
const Desktop = styled.div`
  width: ${DESKTOP_WIDTH}px;
  height: 100vh;
  position: absolute;
  left: 50%;
  transform: translateX(calc(100% + ${DESKTOP_GAP/2}px));

  @media print {
    left: 0 !important;
    width: 100% !important;
    z-index: 10;
    transform: translateX(calc(100%));
  }
`

const WindowContainer = styled.div<{depth: number}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${p => p.depth};
  background-color: var(--color-white);
  /* overflow: auto; */

  @media print {
    position: relative;
    width: 100% !important;
  }
`
