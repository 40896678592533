import {makeAutoObservable} from 'mobx';
import store from './Store';
import { searchParams } from '../helpers/url';
import { setPartnerLS } from './localStorageHelpers';
import Api from "../helpers/api";
import { GetPartnerDiscount, PartnerLinkTargets } from '../libs';

export default class Partner {
  constructor() {
    makeAutoObservable(this);
  }

  partner: string | undefined = undefined
  partnerDiscount: GetPartnerDiscount.Response | null = null

  setPartner() {
		if (store.isAuth) {
			this.partner = store.profile.partner
		} else {
				const partnerToken = searchParams().get('partner')

		    if (partnerToken) {
		      setPartnerLS(partnerToken)
		      this.partner = partnerToken
		    }
		}
  }

  resetPartner() {
    setPartnerLS('')
    this.partner = undefined
  }

  async setPartnerDiscount(target: PartnerLinkTargets, partner?: string) {
    const result = await Api.getPartnerDiscount(target, partner)
    this.partnerDiscount = result

		return result
  };

  getPartnerParams() {
    const profile = store.profile;
    const partnerId = this.partner;
    let partnerParams: any = {};

    if (profile.email) {
    // если пользователь авторизован
      partnerParams = {
        partner: !!partnerId
      }

      if (partnerId) {
        partnerParams.partner_id = partnerId;
      }
    } else {
      const _partnerId = searchParams().get('partner');
      partnerParams = {
        from_partner: _partnerId || false
      }
    }

    return partnerParams
  }
}
