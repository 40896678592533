import { Notifications } from '@chronos/ui';
import '@chronos/ui/styles/indi.css';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import styled from 'styled-components';
import { OpenMobileModal } from './components/OpenMobileModal';
import ScheduleModal from './modules/consultations/components/ScheduleModal';
import ServicesModal from './modules/consultations/components/ServicesModal';
import { MainPage } from './modules/main-page/main-page';
import { NotificationsModal } from './modules/notifications/NotificationsModal';
import { Popups } from './modules/popups/Popups';
import { checkProduct } from './modules/products/helpers/check-product';
import { Windows } from './modules/windows/Windows';
import store from './store/Store';
import { isMobile } from './utils';

export default observer(function App() {
  const status = store.events.status;

  useEffect(() => {
    store.init()
    .then(checkProduct)

    const setPwaInstalled = () => {
      store.pwa.addPwaInstallation(store.sessionData?.id)
    }

    store.pwa.addPwaInstallationForIOS(store.sessionData?.id)

    const setUserActive = () => {
      store.logger.setAppSessionInfo()
    }


    window.addEventListener('appinstalled', setPwaInstalled);

    window.addEventListener('touchstart', setUserActive)
    window.addEventListener('mousedown', setUserActive)

    return () => {
      window.removeEventListener('appinstalled', setPwaInstalled);

      window.removeEventListener('touchstart', setUserActive);
      window.removeEventListener('mousedown', setUserActive);
    }
  }, []);

  useEffect(() => {
    if(status) document.body.style.pointerEvents = 'initial';
  }, [status])

  return <>
    <MainPage />

    <Windows />
    <Popups />

    <StyledNotifications/>

    {!isMobile && <OpenMobileModal />}

    {store.modal.showHandler.servicesModal && <ServicesModal />}
    {store.modal.showHandler.scheduleModal && <ScheduleModal />}
    {store.modal.showHandler.notificationsModal && <NotificationsModal />}
  </>
})

const StyledNotifications = styled(Notifications)`
  font-size: 0.8rem;
`
