import dayjs from 'dayjs';
import { makeAutoObservable, onBecomeObserved } from 'mobx';
import { HarmonicIcon, MediumIcon, TenseIcon } from '../assets/icons/favorability';
import badBackgroundPath from "../assets/images/main/bg-bad.jpg";
import luckyBackgroundPath from "../assets/images/main/bg-lucky.jpg";
import typicalBackgroundPath from "../assets/images/main/bg-typical.jpg";
import api from '../helpers/api';
import { toISOString } from '../helpers/dates';
import { TUserStateKeys } from '../modules/main-page/helpers/states';
import store from './Store';
import { Indi, Region } from '../libs';

export interface IDayDescription {
  atmosphere: string;
  attention: string[];
  importance: {
    description1: string;
    description2: string;
  };
  toDo: string[];
  notToDo: string[];
}

export interface IEvents {
	day: Indi.Events.Day;
  events: Indi.Events.Event[];
  feedback?: Indi.Events.Feedback;
}

export default class Events {
  month: any = {};
  events: Record<string, {
    day: Indi.Events.Day;
    events: Indi.Events.Event[];
    feedback?: Indi.Events.Feedback;
  }> = {};
  status: TUserStateKeys | null = null;
  tariffs: Indi.indiGetTariffs.Tariff[] = [];
	percents: Record<string, number> = {}

  myBlockLoaded: boolean = false;
  monthBlockLoaded: boolean = false;
	showEvents: boolean = false;

  constructor() {
    makeAutoObservable(this);
    onBecomeObserved(this, 'month', this.loadMonth.bind(this))
    this.loadTariffs();
  }

  setStatus() {
    if (store.isAuth) {
      const info = store.sessionData?.indi!;
      if (dayjs(info.expiryDate).isAfter(dayjs())) {
        this.status = 'active';
      } else if (!dayjs(info.expiryDate).isAfter(dayjs())) {
        this.status = 'pause';
      } else if (!info.expiryDate) {
        this.status = 'inactive';
      } else {
        this.status = 'inpayed';
      }
    } else {
      this.status = 'unlogin';
    }
  }

  loadEventsFirst() {
    const from = dayjs().startOf('day');
    const to = dayjs().add(1, 'week').add(-1, 'second').startOf('day');
    this.loadEventsByDate(from, to)
  }

  async loadEventsByDate(date: dayjs.Dayjs, to?: dayjs.Dayjs) {
    const fromDay = date.startOf('day');
    
    let toDay = to 
      ? to.startOf('day') 
      : date.add(1, 'day').add(-1, 'second').startOf('day');

    const datesInfo = store.getIndiDates();
    const endPlan = dayjs.tz(datesInfo.endPlan);

    if (store.events.status !== 'pause') {
      if (toDay.isAfter(endPlan)) {
        toDay = endPlan;
      }
    } 

    const allDays: dayjs.Dayjs[] = [];
    let d = fromDay.clone();
    while (d.isBefore(toDay) || d.isSame(toDay)) {
      allDays.push(d);
      d = d.add(1, 'day');
    }

    const missingDays = allDays.filter(dayObj => {
      const isoKey = toISOString(dayObj.startOf('day'));
      return !this.events[isoKey];
    });

    if (missingDays.length === 0) return;
    
    const missingFrom = toISOString(missingDays[0].startOf('day'));
    const missingTo = toISOString(missingDays[missingDays.length - 1].startOf('day'));

			const now = dayjs().toISOString()

    await api.indiGetEvents(
      {
        from: missingFrom,
        to: missingTo,
      },
      datesInfo.startPlan,
      datesInfo.endPlan,
      now
    )
    .then(data => {
      for (const key in data) {
        if (key === 'debug') continue;
        const dateForCache = toISOString(dayjs(key).startOf('day'));
        const rawEvents = data[key].events ?? [];
        const sortedEvents = rawEvents
          .sort((a: Indi.Events.Event, b: Indi.Events.Event) => {
            if (dayjs(a.endDate).diff(dayjs(b.endDate)) < 0) return -1;
            return 1;
          })
          .sort((a: Indi.Events.Event) => (a.isImportant ? -1 : 1));
    
        this.events[dateForCache] = {
          day: data[key].day,
          feedback: data[key]?.feedback,
          events: sortedEvents,
        };
      }
      })
  }

  async loadMonth(currentDate?: string) {
    if (store.isAuth) {
      const dates = store.getIndiDates();
			const now = dayjs().toISOString()
      const result = await api.indiGetMonth(dates.startPlan, dates.endPlan, currentDate ?? now);
			this.percents = {...this.percents, ...result.percents}
			this.month = result
    }
  }

  async loadTariffs(country: Region = Region.ru) {
    this.tariffs = await api.indiGetTariffs(country);
  }

  async registration(email: string, language: string, firstName?: string, birth?: any, partner?: string) {
    const data = {
      channel: email,
      language,
      info: {
        firstName, birth
      },
      partner
    } as Indi.signUpOrSignIn.Request;
    await api.indiSignUpOrSignInWithEmail(data);
    const cb = await api.cb({ conversion: '' });
    await store.initLogin(cb.code);
  }

  async setMyBlockLoaded(value: boolean) {
    this.myBlockLoaded = value;
  }

  async setMonthBlockLoaded(value: boolean) {
    this.monthBlockLoaded = value;
  }

	setShowEvents(value: boolean) {
		this.showEvents = value
	}

	getIcon(date: dayjs.Dayjs) {
		const percent = this.percents[date.format('DD.MM.YYYY')]

		if (!percent) return

		if (percent < 31) {
			return TenseIcon
		}

		if (30 < percent && percent < 51) {
			return	MediumIcon
		}

		return HarmonicIcon
	}

	getColor(date: dayjs.Dayjs) {
		const percent = this.percents[date.format('DD.MM.YYYY')]
		if (!percent) {
			return {
				color: 'var(--text-primary)',
				background: '#FFFFFF',
        highligh: '#FFFFFF',
			}
		}

		if (percent < 31) {
			return {
				color: '#E78060',
				background: badBackgroundPath,
        highligh: '#F3DEDB',
			}
		}

		if (30 < percent && percent < 51) {
			return	{
				color: '#F0A066',
				background: typicalBackgroundPath,
        highligh: '#FDEADC',
			}
		}

		return {
			color: '#7DCA55',
			background: luckyBackgroundPath,
      highligh: '#D9EECD',
		}
	}

  getPercent(date: dayjs.Dayjs) {
    return this.percents[date.format('DD.MM.YYYY')]
  }
}
