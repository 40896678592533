import { ReactNode, useEffect } from "react"
import styled, { css } from "styled-components"

interface ToasterProps {
  className?: string
  title: string
  onClose?: () => void
  autoClose?: number
  btnTitle?: string
  icon?: ReactNode
  onClick?(): void
  color?: string
  background?: string
}

export const Toaster = (props: ToasterProps) => {
  const {
    className = '',
    title,
    btnTitle,
    onClose,
    autoClose = 0,
    icon,
    onClick,
    color,
    background,
  } = props

  useEffect(() => {
    if (autoClose > 0) {
      const timer = setTimeout(() => {
        onClose?.();
      }, autoClose);
  
      return () => clearTimeout(timer);
    }
  }, [onClose, autoClose]);

  return (
    <Container
      className={className}
      onClick={onClick}
      background={background}
    >
      <div className="left">
        {icon && icon}
        <div>{title}</div>
      </div>
      {btnTitle && onClick && <Button color={color}>{btnTitle}</Button>}
      {onClose && (
        <CloseIcon onClick={(e) => {
          e.stopPropagation()
          onClose?.();
        }}>
          ×
      </CloseIcon>
      )}
    </Container>
  )
}

const Container = styled.div<{background?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  bottom: 3rem;

  color: var(--color-white);
  font-size: 0.875rem;
  font-weight: 300;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  z-index: var(--z-index-modal);
  background: ${p => p.background ?? '#222222'};
  max-width: 380px;

  .left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`
const Button = styled.div<{color?: string}>`
  ${p => p.color && css`
    color: ${p.color};
  `}
`
const CloseIcon = styled.div`
  font-size: 1rem;
  cursor: pointer;
  margin-left: 1rem;
`;