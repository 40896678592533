import { Dayjs } from "dayjs";
import { makeAutoObservable } from "mobx";

export class MainPage {
  constructor() {
    makeAutoObservable(this)
  }

  selectedDay?: Dayjs

  setSelectedDay(day: Dayjs) {
    this.selectedDay = day
  }
}
