import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { RectificationRequest } from '../../../components/RectificationRequest'
import { Button } from '../../../components/ui/Button/Button'
import { TextInput } from '../../../components/ui/TextInput'
import api from '../../../helpers/api'
import {
  isDateInvalid,
  isNameInvalid,
  isPlaceInvalid,
  isTimeInvalid,
} from '../../../helpers/fields'
import { gtmSend } from '../../../helpers/url'
import store from '../../../store/Store'
import dayjs from 'dayjs'
import { IPlace, InputContainer } from '../../../libs'
import { defaultPlace } from 'src/helpers/places'


const titles = [
  {
    title: 'Укажите данные о рождении',
    subtitle: 'Они необходимы для построения прогноза',
  },
  {
    title:
      'Для высокой точности прогноза укажите время рождения с точностью до минуты',
    subtitle: null,
  },
]

interface OnboardingFormProps {
  startLoading(): void
}

export const Form = (props: OnboardingFormProps) => {
  const defaultDateTime = '2000-01-01T12:00:00.000Z'

  const [step, setStep] = useState(0)
  const [rectification, setRectification] = useState(false)

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  const [date, setDate] = useState(defaultDateTime)
  const [dateError, setDateError] = useState('')

  const [time, setTime] = useState(defaultDateTime)
  const [timeError, setTimeError] = useState('')

  const [place, setPlace] = useState<IPlace>(defaultPlace)
  const [placeError, setPlaceError] = useState('')

  const [disableButton, setDisableButton] = useState(false)

  const userId = store.sessionData?.id || ''

  useEffect(() => {
    if (step === 0)
      gtmSend({ event: 'ind_prognosis_birth_data_start', user_id: userId })
    if (step === 1)
      gtmSend({ event: 'ind_prognosis_birth_time_start', user_id: userId })
  }, [step])

  const onChangeNameHandler = (value: string) => {
    setName(value)
    setNameError('')
  }

  const onChangeDateHandler = (value: string) => {
    setDate(value)
    setDateError('')
  }

  const onChangeTimeHandler = (time: string) => {
    setTime(time)
    setTimeError('')
  }

  const onChangePlaceHandler = (value: any) => {
    if (typeof value === 'object') {
      setPlace({ name: value.name, lat: value.lat, lon: value.lon })
      setPlaceError('')
    } else {
      setPlace({ name: value, lat: 0, lon: 0 })
    }
  }

  const checkInvalidData = () => {
    const { nameError, dateError, placeError, timeError } = {
      nameError: isNameInvalid(name),
      dateError: isDateInvalid(date),
      timeError: isTimeInvalid(time),
      placeError: isPlaceInvalid(place),
    }

    if (nameError) setNameError(nameError)
    if (dateError) setDateError(dateError)
    if (timeError) setTimeError(timeError)
    if (placeError) setPlaceError(placeError)

    return nameError || dateError || timeError || placeError
  }

  const submit = async () => {
    try {
      setDisableButton(true)

			await api.updateProfile({
				firstName: name,
				birth: {
					//FIXME: fix types
					//@ts-ignore
					dateTime: {
						date: dayjs.utc(date).format('DD.MM.YYYY'),
	          time: dayjs.utc(time).format('HH:mm:ss'),
					},
					place,
				},
			} as any) // FIXME: any

      await store.getProfile()
      await store.events.loadMonth()

      rectification && await api.orderRectification({ isDelayed: true })
    } catch (e) {
      console.error(`Submit error: ${e}`)
    }
  }

  const onClickHandler = () => {
    if (checkInvalidData()) return

    if (step === 0) {
      setStep((prev) => prev + 1)

      gtmSend({ event: 'ind_prognosis_birth_data_done', user_id: userId })
    } else {
      submit()
      props.startLoading()

      gtmSend({ event: 'ind_prognosis_birth_time_done', user_id: userId })
      rectification &&
        gtmSend({
          event: 'ind_prognosis_birth_time_rectification',
          user_id: userId,
        })
    }
  }

  const firstStep = (
    <Blank>
      <TextInput
        value={name}
        onChange={onChangeNameHandler}
        title={'Имя'}
        error={nameError}
      />

      <TextInput
        value={place.name}
        place={place}
        onChange={onChangePlaceHandler}
        title={'Место рождения'}
        type={'place'}
        error={placeError}
      />

      <TextInput
        value={date}
        onChange={onChangeDateHandler}
        title={'Дата рождения'}
        type={'date'}
        error={dateError}
        onInvalid={() => setDateError('Введите корректную дату')}
      />
    </Blank>
  )

  const secondStep = (
    <Blank>
      <TextInput
        value={time}
        onChange={onChangeTimeHandler}
        title={'Время рождения'}
        type={'time'}
        error={timeError}
        onInvalid={() => setTimeError('Введите корректное время')}
      />

      <div className='subtitle'>
        Если не помните, оставьте 12:00. Сможете изменить в настройках
      </div>

      <RectificationRequest
        rectification={rectification}
        setRectification={setRectification}
      />
    </Blank>
  )

  return (
    <Container>
      <Header>
        <div className='title'>{titles[step].title}</div>
        <div className='subtitle'>{titles[step].subtitle}</div>
      </Header>

      {step === 0 ? firstStep : secondStep}

      <Footer>
        <Button onClick={onClickHandler} disabled={disableButton}>
          Дальше
        </Button>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	padding: 1.5rem 1rem;
	border-radius: 1rem;
	background: #f8f1ff;
	max-width: 18.25rem;
	margin-bottom: 6rem;

	.title {
		font-size: 1.125rem;
		font-weight: 500;
	}

	.subtitle {
		font-size: 0.75rem;
		color: var(--text-secondary);
		margin-top: 0.25rem;
	}
`

const Header = styled.div`
	margin-bottom: 1rem;
`

const Blank = styled.div`
	height: 100%;
`

const CustomInputContainer = styled(InputContainer) <{ type?: string }>`
	& + & {
		margin-top: 1rem;
	}

	label {
		padding-left: 0.1rem;
		margin-bottom: 1rem;
	}

	input {
		top: -5px;
		font-family: 'Apercu Pro', sans-serif;
		margin-bottom: 0.5rem;
		border: 0.125rem solid var(--border-dark);
	}

	.ui-dropdown:not(.mobile) {
		left: 10px;
	}

	${(p) =>
    p.type === 'time' &&
    css`
			input {
				background: var(--input-background) !important;
			}
		`}
`

const Footer = styled.div`
	margin-top: 1.5rem;
`
