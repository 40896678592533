import styled from 'styled-components';
import ServiceCard from './ServiceCard';
import { IServiceGroup } from '../Services';
import { IServiceDataEx, SessionFormat } from '../../../libs';

interface IServiceGroupsProps {
  groups: IServiceGroup[] | null;
  onServiceClick: (service: IServiceDataEx) => void;
  sessionFormat: SessionFormat;
}

export default function ServiceGroups({
  groups,
  onServiceClick,
  sessionFormat,
}: IServiceGroupsProps) {
  return <>
    {
			groups && groups.map(group => {
		    return <Group key={`group_${group.type.id}`}>
		      <GroupTitle>{group.type.name}</GroupTitle>

		      {
						group.services.map(service => {
			        return <ServiceCard
							          key={`service_${service.id}`}
							          { ...service }
							          onClick={() => onServiceClick(service)}
							          sessionFormat={sessionFormat}
			        				/>;
			      })
					}
    		</Group>;
  		})
		}
  </>
}

const Group = styled.div`
  display: flex;
  flex-direction: column;

	margin-top: 1.5rem;
`;

const GroupTitle = styled.p`
  color: rgba(0, 0, 0, 0.37);
`;
