import styled from "styled-components"
import { FlexContainer } from "../layout/elements"
import { ReactNode } from "react"
import { Blur } from "./Blur"

interface ListProps {
	className?: string
	icon: ReactNode
	title: string
	items: string[]
  isBlur?: boolean
  onClick?: () => void
}

export const List = (props: ListProps) => {
  const {
    className = '',
    icon,
    title,
    items,
    isBlur = false,
    onClick,
  } = props

	return (
		<Container className={className}>
			<Title>
        {icon}
				{title}
      </Title>
      <Blur isBlur={isBlur} onClick={onClick}>
        <Items>
          {items.map((item, i) => <Item key={i}>{item[0].toUpperCase() + item.substring(1)}</Item>)}
        </Items>
     </Blur>
		</Container>
	)
}

const Container = styled.div`
`

const Title = styled(FlexContainer)`
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: 500;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const Items = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
`

const Item = styled.li`
	display: flex;
  background: var(--input-background);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
`
