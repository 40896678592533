import { makeAutoObservable } from 'mobx';
import api from '../helpers/api';
import { setPromocodeDataLS, setPromocodeLS, setPromocodeStateLS } from "./localStorageHelpers";
import store from './Store';
import { IndiProductCode, IPromocode, show } from '../libs';
import { IProductCode } from './Payments';

export type PromocodeErrorsType = {
  'PROMO_NOT_FOUND': string
  'PROMO_ALREADY_USED': string
  'PROMO_HAVE_INDI_ALREADY': string
  'PROMO_EXPIRED': string
  'PROMO_INCORRECT': string
  'PROMO_USELESS': string
  'APP_MISMATCH': string
  'PRODUCT_MISMATCH': string
  'SUBSCRIPTION_REQUIRED': string
  'UNAUTHORIZED': string
  'PARTNER_DISCOUNT_IS_MORE': string
}

export const promocodeErrors: {
  [key: string]: string
} = {
  'PROMO_NOT_FOUND': 'Промокода не существует',
  'PROMO_ALREADY_USED': 'Промокод уже использован',
  'PROMO_HAVE_INDI_ALREADY': 'Промокод уже использован',
  'PROMO_EXPIRED': 'Срок действия промокода истек',
  'PROMO_INCORRECT': 'Промокод поврежден. Обратитесь в тех. поддержку',
  'PROMO_USELESS': 'Все построения из промокода уже куплены',
  'APP_MISMATCH': 'Промокод не может быть применен в данном приложении',
  'PRODUCT_MISMATCH': 'Промокод не соответствует выбранному продукту',
  'SUBSCRIPTION_REQUIRED': 'Для применения промокода требуется активная подписка',
  'UNAUTHORIZED': 'Сбой авторизации',
  'PARTNER_DISCOUNT_IS_MORE': 'Скидка от партнёра больше'
}

export default class Promocode {
  constructor() {
    makeAutoObservable(this);
  }

  promoCode = '';
  promoData: IPromocode | null = null
  promoError: string = ''

  async checkPromocode(promo: string, headers?: { tentative: string }, subscription?: boolean, product?: IProductCode): Promise<{ type: 'success' | 'error'; text?: string }> {
    let result;
    try {
      const email = store.payments.email || '';
      result = await api.checkPromocode(promo, headers, email)

      // Проверка на корректность приложения
      if (result.actions.app !== 'indi') {
        throw Error('APP_MISMATCH');
      }

      // Проверка на наличие подписки, если она требуется
      if (subscription && !result.actions.subscription) {
        throw new Error('SUBSCRIPTION_REQUIRED');
      }

      // Проверка на соответствие продукта
      if (product && result.actions.products !== product) {
        throw new Error('PRODUCT_MISMATCH');
      }

      if (this.compareDiscounts(result.actions.discount, product)) {
        throw new Error('PARTNER_DISCOUNT_IS_MORE');
      }

      this.promoData = result
      this.promoCode = promo
      setPromocodeDataLS(result)

      return {
        type: 'success',
        text: 'Промокод применён',
      }
    } catch (e: any) {
      api.sendNotify(`promo error 1: ${e.message}; promo: ${promo}; result: ${result ? JSON.stringify(result) : 'null'}`);
      this.promoError = promocodeErrors[e.message as keyof PromocodeErrorsType]
      return {
        type: 'error',
        text: promocodeErrors[e.message as keyof PromocodeErrorsType],
      }
    }
  }

  async usePromocode(promo: string, formationId: number | undefined = undefined, headers?: { tentative: string }, email?: string) {
    try {
      const result = await api.usePromocode(promo, formationId, headers, email)
      return result
    } catch (e: any) {
      api.sendNotify(`promo error 2: ${e.message}; email: ${email}; promo: ${promo}`);
      this.promoError = promocodeErrors[e.message as keyof PromocodeErrorsType]
      show({
        text: promocodeErrors[e.message as keyof PromocodeErrorsType],
        type: 'error'
      })
      throw e
    }
  }

  async cancelPromocode(promoId: string | number, data?: any, headers?: { tentative: string }) {
    await api.cancelPromocode(promoId, data, headers)
  }

  resetPromocode() {
    this.promoCode = ''
    this.promoData = null
    setPromocodeLS('')
    setPromocodeStateLS('')
    setPromocodeDataLS('')
  }

  setPromoError(value: string) {
    this.promoError = value
  }

  compareDiscounts(promoDiscount?: number, product?: IProductCode,) {
    if (!promoDiscount || !product) return

    let partnerDiscount = 0

    if (store.partner.partnerDiscount?.products?.includes(product as IndiProductCode)) {
      partnerDiscount = store.partner.partnerDiscount?.partnerDiscount
    }

    return partnerDiscount > promoDiscount
  }
}
