import { ReactNode } from "react";
import { Button } from "src/components/ui/Button/Button";
import { formatNumber } from "src/helpers/numbers";
import styled from "styled-components";
import background from '../../../assets/images/backgrounds/bg_component.jpg';
import { GetPartnerDiscount, IndiProductCode, PARTNER_TARGET } from '../../../libs';


interface NewProductProps {
  icon: ReactNode
  title: string
  description: string
  price: number
  oldPrice: number
  discount?: GetPartnerDiscount.Response
  onClick:() => void
  code: IndiProductCode
  badge?: string | ReactNode
}

export const NewProduct = (props: NewProductProps) => {
  const {
    icon,
    title,
    description,
    price,
    oldPrice,
    discount,
    onClick,
    code,
    badge,
  } = props

  const getDiscount = () => {
    if (discount?.originalTarget?.includes(PARTNER_TARGET.ChronosPlus)) {
      return discount?.partnerDiscount
    }

    if (discount?.products?.includes(code)) {
      return discount?.partnerDiscount
    }

    return 0
  }

  const finalPrice = Math.round(price * (1 - getDiscount()) + Number.EPSILON)

  return (
    <Container background={background}>
      <StyledBadge>Новинка!</StyledBadge>
      <IconContainer>{icon}</IconContainer>
      <Text>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Text>

      <Footer>
        <Price>
          <span>{formatNumber(finalPrice)} ₽</span>
          <span className={'oldPrice'}>{formatNumber(oldPrice)} ₽</span>
        </Price>

        {badge && <Badge>{badge}</Badge>}

        <StyledButton color={'green'} onClick={onClick}>Купить</StyledButton>
      </Footer>
    </Container>
  )
}

const Container = styled.div<{background: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;

  position: relative;
  background: ${p => `url(${p.background}) 0 0 / cover no-repeat`};
  border-radius: 1.5rem;
  padding: 2rem 1.5rem;
`

const Badge = styled.div`
  color: var(--color-white);
  background: var(--color-gradus-blue);
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 1rem;
  left: 1rem;
`

const IconContainer = styled.div`
  margin-top: 2rem;
`

const Text = styled.div`
  text-align: center;
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
`

const Description = styled.div`
  font-size: 0.875rem;
  text-align: center;
  line-height: 21px;
  letter-spacing: -0.4300000071525574px;
  margin-top: 0.5rem;
`

const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;

  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-gradus-blue);

  .oldPrice {
    text-decoration: line-through;
    color: var(--text-third);
  }
`

const StyledButton = styled(Button)`
  width: fit-content;
  padding: 0.875rem 1rem;
  color: var(--text-primary);
  font-weight: 500;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`
