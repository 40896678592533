import { observer } from "mobx-react-lite";
import { Button } from "src/components/ui/Button/Button";
import { TrialClose } from "src/modules/paywall/components/Close/TrialClose";
import store from "src/store/Store";
import { windowsStore } from "src/store/Windows";
import styled from "styled-components";
import { gtmSend } from "src/helpers/url";
import { openPaymentSubscription } from "src/modules/payment/components/payment-subscription";
import { CheckboxIcon } from "src/assets/icons/system/system-icons";
import logo from "./assets/logo.png"
import oneDay from "./assets/one_day.png"
import threeDays from "./assets/three_days.png"
import { useEffect } from "react";


const list = [
  'Планируйте свои дела по всем сферам жизни',
  'Персональный прогноз на каждый день',
  'Улучшайте качество жизни, применяйте персональные рекомендации',
  'Будьте в курсе будущих событий, влияющих на вас',
]

export const WINDOW_SUBSCRIBE_BANNER = 'WINDOW_SUBSCRIBE_BANNER'

export const openSubscribeBanner = (daysLeft?: number) => {
  windowsStore.open(
    WINDOW_SUBSCRIBE_BANNER,
    <SubscribeBanner daysLeft={daysLeft}/>,
    'fade'
  )
}

interface SubscribeBannerProps {
  daysLeft?: number
}

export const SubscribeBanner = observer((props: SubscribeBannerProps) => {
  const {
    daysLeft,
  } = props

  const userId = store.sessionData?.id ?? ''

  useEffect(() => {
    const eventSuffix = daysLeft === 3 ? '3dendbanner_view' : daysLeft === 1 ? '1dendbanner_view' : 'banner_view';
    gtmSend({ event: `indi_subscription_${eventSuffix}`, user_id: userId });
  }, [userId, daysLeft])

  const onClose = () => {
    windowsStore.close(WINDOW_SUBSCRIBE_BANNER)

    gtmSend({ event: 'indi_subscription_banner_cross', user_id: userId })
  }

  const onPayment = () => {
    openPaymentSubscription()

    const eventSuffix = daysLeft === 3 ? '3dendbanner_ok' : daysLeft === 1 ? '1dendbanner_ok' : 'banner_ok';
    gtmSend({ event: `indi_subscription_${eventSuffix}`, user_id: userId });
  }

  const getLogo = () => {
    switch(daysLeft) {
      case 3:
        return threeDays
      case 1:
        return oneDay
      default:
        return logo
    }
  }

  return (
    <Container>
      <div>
        <Header>
          <div className="controls"><TrialClose onClick={onClose}/></div>

          <img src={getLogo()} alt="logo" />
          {daysLeft && <TrialEnd>до окончания триала</TrialEnd>}

          <Title>Подключите подписку Chronos Plus</Title>
          <Subtitle>от 499₽/мес</Subtitle>
        </Header>

        <ListWrapper>
          <Subtitle>Начните лучше понимать себя</Subtitle>
          <List>
            {
              list.map((item, index) => (
                <Item key={`subscribe_banner_${index}`}>
                  <div><CheckboxIcon/></div>
                  <div>
                    {item}
                  </div>
                </Item>
              ))
            }
          </List>
        </ListWrapper>
      </div>

      <Footer>
        <Button onClick={onPayment} color="green">Оформить подписку</Button>
      </Footer>
    </Container>
  )
})


const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 1rem;
  overflow: auto;
`

const Header = styled.header`
  text-align: center;
  padding-top: 1rem;

  img {
    max-width: 324px;
  }
`

const TrialEnd = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--text-third);
`

const Title = styled.div`
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 32.2px;
  letter-spacing: -0.025em;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
`

const Subtitle = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--text-third);
  text-align: center;
`

const ListWrapper = styled.div`
  margin-top: 2rem;
`

const List = styled.ul`
  margin-top: 1rem;
`

const Item = styled.li`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.75rem;

  svg {
    width: 2.5rem;
    height: 2rem;
  }

  .description {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .subtitle {
      font-size: 0.75rem;
      color: var(--text-secondary);
    }
  }
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  margin-top: 3rem;
`
