import React from 'react';
import { observer } from 'mobx-react-lite';
import { isMobile } from 'src/utils';
import Skeleton from 'react-loading-skeleton';
import { Indi, searchParams } from 'src/libs';
import styled from 'styled-components';
import { LogoBlackIcon } from '../../../assets/icons/system/system-icons';
import prognosisForYouPath from '../../../assets/images/main-banner/prognosis-for-you.png';
import resubscribePath from '../../../assets/images/main-banner/re-subscribe.png';
import { FlexContainer } from '../../../components/layout/elements';
import { Button } from '../../../components/ui/Button/Button';
import { numberWord } from '../../../helpers/strings';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';
import { windowsStore } from '../../../store/Windows';
import { FreePeriod, SubscribeModes } from '../../payment/helpers/tariffs';
import { PayLanding, WINDOW_ID_PAYLANDING } from '../../paywall/PayLanding';


interface IMainPageBlocksSubscriptionBannerProps {
	page?: string;
}

export const openPayLandingWindow = (freePeriod?: FreePeriod) => {
	windowsStore.open(
		WINDOW_ID_PAYLANDING,
		<PayLanding freePeriod={freePeriod} />,
		'fade'
	)
}


const sp = searchParams();
const period = Number(sp.get('d'));
const trialPeriod = [3, 14].includes(period) ? period : 7;


const titles = {
	trial: {
		title: 'Персональные ежедневные прогнозы',
		subtitle: 'Рекомендации по самым важным сферам жизни на месяцы вперед',
		buttonTitle: `Попробовать ${trialPeriod} ${numberWord(trialPeriod!, ['день', 'дня', 'дней'])}`,
	},
	subscribe: {
		title: 'Персональные ежедневные прогнозы',
		subtitle: 'Рекомендации по самым важным сферам жизни на месяцы вперед',
		buttonTitle: 'Оформить подписку',
	},
	resubscribe: {
		title: 'Возобновите подписку',
		subtitle: 'Чтобы сохранить уверенность и контроль над будущим в семи ключевых сферах',
		buttonTitle: 'Возобновить подписку',
	},
	trialFourteen: {
		title: '14 дней Chronos Plus в подарок',
		subtitle: 'Рекомендации по самым важным сферам жизни на месяцы вперед',
		buttonTitle: 'Активировать 14 дней',
	}
}

export const MainPageBlocksSubscriptionBanner = observer((props: IMainPageBlocksSubscriptionBannerProps) => {
	const isAuthFinished = store.isAuthFinished
	const status = store.events.status
	const userId = store.sessionData?.id || ''
	const info = store.sessionData?.indi

	const _mode = React.useMemo(() => {
		const hasFourteenTrialOffer = info?.offers
			.find(item => item.type === Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS)

		if (hasFourteenTrialOffer?.available) {
			return SubscribeModes.TRIAL_FOURTEEN
		}

		if (!info?.trialExpiryDate) {
			return SubscribeModes.TRIAL
		}

		if (info?.trialExpiryDate !== info?.expiryDate) {
			return SubscribeModes.RESUBSCRIBE
		}

		return SubscribeModes.SUBSCRIBE
	}, [info]);

	const onClickHandler = () => {
		store.payments.moveUserToPaymentScreen()
		gtmSendHandler()
	}

	const gtmSendHandler = () => {
		if (_mode === SubscribeModes.TRIAL_FOURTEEN) {
			gtmSend({ 'event': 'indi_main-sub-bunner-14days_click', 'user_id': userId })
		} else {
			gtmSend({ 'event': `ind_prognosis_${props.page || 'general'}_banner_click`, 'user_id': userId })
		}
	}

	if (!isAuthFinished || !status) return <div><Skeleton style={{ 'height': '374px', 'width': '100%', 'borderRadius': '20px' }} /></div>

	return (
		<Container
			onClick={onClickHandler}
			className="ob-0"
			background={_mode === SubscribeModes.RESUBSCRIBE ? 'red' : 'blue'}
		>
			<LogoBlackIcon />
			<Image src={[SubscribeModes.RESUBSCRIBE, SubscribeModes.TRIAL_FOURTEEN].includes(_mode) ? resubscribePath : prognosisForYouPath} />
			<Title>{titles[_mode].title}</Title>
			<SubTitle>{titles[_mode].subtitle}</SubTitle>

			<StyledButton
				onClick={() => { }}
				background={_mode === SubscribeModes.RESUBSCRIBE ? 'red' : 'blue'}
			>
				{titles[_mode].buttonTitle}
			</StyledButton>
		</Container>
	)
})

const Container = styled(FlexContainer) <{ background?: 'red' | 'blue' }>`
	flex-direction: column;
	align-items: center;
	text-align: center;
	background-image: ${p => p.background === 'red' 
		? 'radial-gradient(100% 80% at 50% -35%, #F9C8F7 50%, #073AFF00 100%),radial-gradient(120% 80% at 51% 23%, #F8F0E5 50%, #FF000000 99%),radial-gradient(120% 90% at 48% 122%, #FDFDFB 70%, #FF000000 99%)' 
		: 'radial-gradient(100% 80% at 49% -29%, #CBBFFF 50%, #073AFF00 100%),radial-gradient(120% 80% at 48% 28%, #DEEEFE 50%, #FF000000 99%),radial-gradient(120% 90% at 47% 121%, #FBFCFE 70%, #FF000000 99%)'
	};
	
  	border-radius: 1.25rem;
  	padding: 1rem;
`

const Image = styled.img`
	width: ${isMobile ? '5.75rem' : '7.75rem'};
	margin-top: 2rem;
`

const Title = styled.div`
  	margin-bottom: 0.625rem;
  	font-weight: 500;
	margin-top: 2rem;
`

const SubTitle = styled.div`
  	margin-bottom: 1rem;
  	font-size: 0.75rem;
  	line-height: 1rem;
  	padding: 0 1rem;
`

const StyledButton = styled(Button) <{ background: 'red' | 'blue' }>`
	background-image: ${p => p.background === 'red' 
		? 'linear-gradient(265.95deg, rgba(231, 182, 110, 0.8) 14.59%, rgba(231, 182, 110, 0) 78.75%), linear-gradient(0deg, #EC91E8, #EC91E8)' 
		: 'linear-gradient(264.22deg, rgba(118, 184, 255, 0.8) 5.77%, rgba(152, 202, 255, 0) 99.67%), linear-gradient(0deg, #844BFD, #844BFD)'
	};
`
