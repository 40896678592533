import styled from "styled-components"
import { ReactComponent as StarIcon } from '../assets/star.svg';
import { FlexContainer } from "src/components/layout/elements";
import { useEffect, useState } from "react";
import { MoreButton } from "./PersonMainBlock";
import PersonFeedbackForm from "./PersonFeedbackForm";
import api from "src/helpers/api";


export default function PersonFeedback() {
    const [rating, setRating] = useState(0);
    const [showRating, setShowRating] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [sentFeedback, setSentFeedback] = useState(false)

    const title = sentFeedback ? 'Спасибо за ваш отзыв!' : 'Было полезно?'
    const text = sentFeedback
        ? <p>Мы используем вашу обратную связь,<br /> чтобы улучшать продукт</p>
        : <p>Пожалуйста, оцените ваши впечатления<br /> об Описании личности</p>

    const loadData = async () => {
        try {
            await api.indiGetPersonFeedbackByUser();
            setShowRating(true);
        } catch(error) {
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    if(showRating) return null;

    const onClick = (index: number) => {
        if (sentFeedback) return

        setRating(rating => {
            if (rating === index + 1) {
                return 0
            } else {
                return index + 1
            }
        });
    }

    const onSubmit = () => setShowForm(true);

    const onClose = () => {
        setSentFeedback(true)
        setShowForm(false);
    }

    return <Container>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Stars>
            {Array(5).fill(0).map((_item, index) =>
                <Star key={index} onClick={() => onClick(index)}  className={rating > index ? 'active' : ''}>
                    <StarIcon  />
                </Star>
            )}
        </Stars>
        {!sentFeedback && <MoreButton disabled={rating === 0} color={'var(--color-green)'} onClick={onSubmit}>Оценить</MoreButton>}

        {showForm && <PersonFeedbackForm onClose={onClose} rating={rating} />}
    </Container>
}

export const Container = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2.375rem 1rem 0.875rem;
    text-align: center;

    border: 0.5px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 4px 14px 0px rgba(129, 136, 157, 0.1);
    color: var(--text-primary);
    border-radius: 1.5rem;
`

export const Title = styled.div`
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
`

export const Text = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
`

export const Stars = styled(FlexContainer)`
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
`

export const Star = styled.div`
    &.active{
        path {
            fill: rgba(244, 149, 80, 1);
            stroke: rgba(244, 149, 80, 1);
        }
    }
`
