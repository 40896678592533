import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';

import {
  capitalize,
  copyToClipboard,
} from '../../helpers/strings';

import {
  getYearText
} from '../../helpers/dates';


import {
  getAge,
  getExperience,
  getNameForButton,
  getSpecialistText,
  groupingServices,
  IServiceGroup,
} from './helpers/services';

import store from '../../store/Store';
import { sessionFormatOptions } from './Services';

import NorthMaps from './components/NorthMaps';
import Skill from './components/Skill';
import Loader from '../../components/ui/Loader';
import ButtonSwitcher from '../../components/ui/ButtonSwitcher';
import DescriptionBlock from './components/DescriptionBlock';
import ServiceGroups from './components/ServiceGroups';
import useLS, { useLSState } from '../../hooks/useLS';

import AvatarImage from '../../assets/images/profile-picture.png';
import PrivateImage from '../../assets/images/private.png';
import { Ok as OkIcon, LinkBlueIcon, ArrowRight as ArrowIcon, UsersIcon, AwardIcon } from '../../assets/icons/system/system-icons';
import CreditCardImage from '../../assets/images/credit-card.png';
import HatImage from '../../assets/images/hat.png';
import LockImage from '../../assets/images/lock.png';

import AdvancedTooltipWrapper from './components/AdvancedTooltipWrapper';
import { InfoIcon } from '../../assets/icons/system/system-icons';
import { SERVICE_ID } from '../../helpers/ls/consultations';
import { searchParams } from '../../helpers/url';
import { history } from '../../MainRouter';
import { Button, InterviewStatus, IServiceDataEx, IWorkProfileEx, SessionFormat, WorkFormat } from '../../libs';


dayjs.extend(relativeTime);

export default observer(function ProfessionalDetails(): JSX.Element {
  const params = useParams<{ id: string, direct_link: string }>();
  const sp = searchParams()

  const [serviceGroups, setServiceGroups] = useState<IServiceGroup[] | null>(null);
  const [professional, setProfessional] = useState<IWorkProfileEx | null>(null);
  const [copiedProfileLink, setCopiedProfileLink] = useState(false);
  const [, , saveProfessionalId] = useLS('professional-id');
  const [sessionFormat, setSessionFormat] = useLSState('session-format', SessionFormat.Online);
  const [serviceId, setServiceId] = useLSState(SERVICE_ID, null);
  const [, , saveSessionCreator] = useLS('session-creator');
  const [sessionCreatorDirectProf, , saveSessionCreatorDirectProf] = useLS('session-creator-direct-prof');

  const service = serviceId && store.getService(serviceId);
  const price = serviceId && service?.price.value;
  const duration = service?.duration;
  const fullName = `${professional?.profile?.firstName || ''} ${professional?.profile?.lastName || ''}`;

  useEffect(() => {
    const directLink = params.direct_link;
    if (professional && store.services && store.serviceCategories) {
      const profServices = professional.services.reduce((acc, service, i) =>  {
        //@ts-ignore
        if (directLink !== 'direct_link' && directLink !== 'direct' && service.userId) return { ...acc }; // если это не direct_link, то не показываем персональные услуги ( признак - наличие у услуги userId)
        const calcService = (store.services![service.id] || service);
        const serviceUnion = { ...calcService, price: { ...service.price }, format: +service.format, category: calcService.category || 1 };
        return { ...acc, [i + 1]: serviceUnion };
      }, {});

      const services = groupingServices(profServices, store.serviceCategories, sessionFormat);

      setServiceGroups(services);

    }
  }, [professional, store.services, store.serviceCategories, sessionFormat, params.direct_link]);

  useEffect(() => {
    const directLink = params.direct_link;
    if(directLink === 'direct_link' || directLink === 'direct') {
      saveSessionCreator('direct');
      saveSessionCreatorDirectProf(params.id);
    } else {
      if(params.id === sessionCreatorDirectProf) {
        saveSessionCreator('direct');
      }
    }
  }, [params.direct_link]);

  useEffect(() => {
    setProfessional(store.professional);
  }, [store.professional]);

  useEffect(() => {
    store.setProfessional(+params.id);
  }, [params.id]);

  const onCardClick = (service: IServiceDataEx) => {
    const { id } = service;
    setServiceId(id);
    store.setCurrentService(id);
    store.setProfessionals(id, sessionFormat);
  };

  const confirmHandler = async () => {
    saveProfessionalId(professional!.userId);

    const isWriting = sessionFormat === SessionFormat.Offline
    const isPredictOfTheYear = serviceId === 9
    await store.setScheduleSlots(professional!.userId, isWriting, isPredictOfTheYear, duration);

    if (serviceId === null) {
      return history.push({pathname: '/service-confirmation', search: sp.toString()});
    }

    history.push({pathname: '/confirmation', search: sp.toString()});
  };

  const copyProfileLink = () => {
    const currentHref = window.location.href;
    const [parsed] = currentHref.split('?');
    setCopiedProfileLink(true);
    copyToClipboard(parsed);
  }

  if (
    !professional
    || !store.specializations
    || !store.skills
    || !store.services
  ) return <Loader />;

  if (window.location.pathname.includes('/direct_link') && !professional?.data.formats.includes(WorkFormat.direct)) {
    history.replace(window.location.pathname.replace('/direct_link', ''));
    return <></>
  }

  if (!professional?.data.formats.includes(WorkFormat.indi) && !window.location.pathname.includes('/direct_link')) {
    return <ContainerLocked><h2>Запись к астрологу приостановлена, вы можете выбрать другого астролога для консультации</h2>
      <Button onClick={() => history.push({pathname: '/professionals', search: sp.toString()})}>Перейти к выбору</Button>
    </ContainerLocked>
  }

  return <Container>
    <Header>
      <Avatar src={professional.profile.avatarUrl || AvatarImage} />
      <Name>{fullName}</Name>
      <Row style={{ marginBottom: '12px' }} wrap={true}>
        {professional.profile.birth.dateTime && <TextItem>
          {getAge(professional.profile.birth.dateTime)} {getYearText(getAge(professional.profile.birth.dateTime))}
        </TextItem>}
        {professional.data.cv && <TextItem>
          {store.specializations[professional.data.cv?.specialization]?.name || ''}
        </TextItem>}
        {professional.data.interviewStatus === InterviewStatus.Passed || professional.data.interviewStatus === InterviewStatus.Succeed &&
          <BadgeItem>
            <GradientTextItem>Проверен Chronos</GradientTextItem>
            &nbsp;
            <AdvancedTooltipWrapper text="Специалист прошел экзамен в Chronos">
              <InfoIconBadge />
            </AdvancedTooltipWrapper>
          </BadgeItem>
        }
      </Row>
      <Row>
        {professional.data.cv?.practiceStart &&
          <Row style={{width: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.2)'}}>
            <AwardIcon/>
            <Column>
              <Digit>
                {getExperience(professional.data.cv.practiceStart)}
              </Digit>
              <Text>
                {capitalize(getYearText(getExperience(professional.data.cv.practiceStart) || 0)!)} опыта
              </Text>
            </Column>
          </Row>
        }
        <Row style={{ width: '100%' }}>
          <UsersIcon />
          <Column>
            <Digit>{professional.sessionsCount}</Digit>
            <Text>Консультаций</Text>
          </Column>
        </Row>
      </Row>
    </Header>

    <Main>

      {!professional.data.cv?.northMaps && <CustomNorthMaps size="big" name={fullName}/>}

      {!!professional.data.cv?.competencies.length &&
        <Section id="0">
          <SectionTitle>Компетенции</SectionTitle>
          <SectionBody>
            <List>
              {professional.data.cv?.competencies.map((c, i) => {
                return <Element key={`competencies_${i}`}>{c}</Element>
              })}
            </List>
          </SectionBody>
        </Section>
      }

      {!!professional.data.cv?.skills.length &&
        <Section id="1">
          <SectionTitle>
            Навыки <span style={{ color: '#4760E7', marginLeft: '8px' }}>
              {professional.data.cv?.skills.length}
            </span>
          </SectionTitle>
          <SectionBody>
            <ScrollRow>
              {professional.data.cv?.skills.map((c, i) => {
                return store.skills![c] && <Skill key={`competencies_${i}`} data={store.skills![c]} />;
              })}
            </ScrollRow>
          </SectionBody>
        </Section>
      }

      {professional.data.cv?.about &&
        <Section id="2">
          <SectionTitle>Обо мне</SectionTitle>
          <SectionBody>
            <Text>
              {professional.data.cv?.about.split('\n').map((paragraph: string, index: number) => (
                <p key={index}>{paragraph}</p>
              ))}
            </Text>
          </SectionBody>
        </Section>
      }

      <Section id="3">
        <SectionTitle>Квалификация</SectionTitle>
        <SectionBody>
          {professional.data.cv?.education.map((q, i) => {
            if(!q.id) return;
            return <Qualification key={`qualification_${i}`}>
              {!!q.start && <Years>{`${q.start}${q.end ? `-${q.end}` : ''}`}</Years>}
              <Text>{store.educations![q.id].name}</Text>
            </Qualification>
          })}
        </SectionBody>
      </Section>

      <Section id="4">
        <SectionTitle>Формат консультации</SectionTitle>
        <SectionBody>
          <List>
            {professional.data.cv?.format.map((f, i) => {
              return <Element key={`format_${i}`}>{f}</Element>
            })}
          </List>
        </SectionBody>
      </Section>

      <Section id={'5'}>
        <SectionTitle>
          {'Услуги'}
        </SectionTitle>

        <ButtonSwitcher
          options={sessionFormatOptions}
          value={sessionFormat}
          onChange={setSessionFormat}
        />

        <DescriptionBlock
          style={{ margin: '16px 0' }}
          text={getSpecialistText(sessionFormat)}
        />

        <SectionBody>
          <ServiceGroups groups={serviceGroups} sessionFormat={sessionFormat} onServiceClick={onCardClick}/>
        </SectionBody>
      </Section>

      <Section>
        <SectionTitle>
          <PrivateIcon src={PrivateImage} />
          Гарантии Chronos
        </SectionTitle>
        <SectionBody>
          <ScrollRow>
            <WarrantyCard>
              <img src={CreditCardImage} alt="" />
              <CardTitle>Безопасные платежи</CardTitle>
              <CardText>Никаких переводов на карту, наличных. Специалист получит оплату только после успешно оказанной услуги.</CardText>
            </WarrantyCard>
            <WarrantyCard>
              <img src={HatImage} alt="" />
              <CardTitle>Проверяем образование</CardTitle>
              <CardText>Все дипломы, сертификаты и грамоты тщательно проверяются на подлинность, а также изучаются те, кто выдал.</CardText>
            </WarrantyCard>
            <WarrantyCard>
              <img src={LockImage} alt="" />
              <CardTitle>Безопасность данных</CardTitle>
              <CardText>Только вы и специалист. Все специалисты подписывают соответствующее соглашение с нами.</CardText>
            </WarrantyCard>
          </ScrollRow>
        </SectionBody>
      </Section>
    </Main>

    <ButtonsFixedRow>
      <BottomButton
        onClick={confirmHandler}
        color="var(--main-gradient)"
        style={{opacity: 1,}}
      >
        <ButtonContent>
          <span dangerouslySetInnerHTML={{__html: getNameForButton(serviceId, price)}} />
          <ArrowIcon/>
        </ButtonContent>
      </BottomButton>
      <BottomButton
        color={copiedProfileLink ? 'var(--color-green)' : 'gray'}
        onClick={copyProfileLink}
        style={{
          width: 50,
        }}
      >
        { copiedProfileLink
            ? <OkIcon />
            : <LinkBlueIcon />
        }
      </BottomButton>
    </ButtonsFixedRow>
  </Container>;
});


const Container = styled.main`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin: auto;
    width: 100%;
    max-width: 550px;
  }
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 12px;
  border-radius: 50%;
  object-fit: cover;
`;

const Name = styled.h1`
  font-size: 1.5rem;
  margin: 0 0 6px;
  font-weight: 500;
`;

const Row = styled.div<{wrap?: boolean}>`
  display: flex;
  ${p => p.wrap
      ? css`flex-wrap: wrap;`
      : css``
  }
  align-items: center;
  &:nth-child(1) {
    margin-right: 12px;
  }

  > svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
`;

const DotSeparator = styled.div`
  :after {
    margin: 0 8px;
    content: '·';
  }

  &:last-child {
    :after {
      margin: initial;
      content: '';
    }
  }
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;

  p {
    margin-bottom: 1rem;
  }
`;

const TextItem = styled(DotSeparator)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
`;

const GradientTextItem = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  background: -webkit-linear-gradient(0deg, #C859FF, #4760E7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Digit = styled.span`
  font-size: 1.125rem;
  color: black;
  line-height: 21px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin: 18px auto 48px;
  max-width: 550px;
  width: 100%;
`;

const CustomNorthMaps = styled(NorthMaps)`
  margin-bottom: 16px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 0 16px;
  font-weight: 500;
  display: flex;
  align-items: center;

  > svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsFixedRow = styled.div<{inModal?: boolean; bottom?: number}>`
  position: fixed;
  bottom: calc(${p => ((p.bottom || 0) + 16) + 'px'} + ${p => '0px'});
  right: 1rem;
  left: 1rem;
  display: flex;
  max-width: 550px;
  margin: auto;

  & > button {
    width: 100%;
    color: var(--text-primary-white);
    margin-right: 0.75rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const BottomButton = styled(Button)`
  display: flex;
  justify-content: center;
  height: 48px;
  padding-top: 0.75rem;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0;
    vertical-align: middle;
  }

  & > span{
    align-self: center;
    font-size: 0.82rem;
    letter-spacing: 0.1px;
  }
`;

const Years = styled.p`
  font-size: 0.75rem;
  margin-bottom: 8px;
`;

const Qualification = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 16px;
  list-style: none;
`;

const Element = styled.li`
  margin-bottom: 14px;
  position: relative;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  :before {
    top: 10px;
    position: absolute;
    content: "";
    background: #4093F4;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: -1em;
  }
`;

const ScrollRow = styled.div`
  display: flex;
  overflow: scroll;
  gap: 1rem;

  @media (min-width: 768px) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    overflow: auto;
    gap: initial;
  }
`;

const PrivateIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const WarrantyCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 3.2px 14px rgba(0, 0, 0, 0.06), 0 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 22px;
  margin-right: 24px;
  min-width: 248px;
  @media (min-width: 768px) {
    margin-bottom: 24px;
    width: calc(50% - 12px);
    min-width: auto;
    box-sizing: border-box;
    &:nth-child(2) {
      margin-right: 0;
    }
    &:nth-child(3) {
      width: 100%;
      margin-right: 0;
    }
  }

  > img {
    width: 30px;
    height: 30px;
    margin-bottom: 48px;
  }
`;

const CardTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  line-height: 21px;
  font-size: 1.125rem;
  margin-bottom: 6px;
`;

const CardText = styled(Text)`
  line-height: 21px;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 2.4rem;
  font-size: 0.8rem;
  letter-spacing: 0.25px;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  & > span {
    margin-top: 0.3rem;
  }
`;

const ContainerLocked = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
  height: 80vh;

`;

const BadgeItem = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5rem;
`;

const InfoIconBadge = styled(InfoIcon)`
  height: 0.875rem;
  display: flex;
  align-items: center;

  path {
    fill: #4760E7;
  }
`;
