import React, { MouseEvent } from "react";
import styled from "styled-components";
import { sortBy, debounce } from 'lodash'
import { countries } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { getNameRu } from "./countryNames_ru";
import { Input } from "../../../../../libs";

const topCountries: string[] = ['ru', 'ua', 'us', 'gb', 'kz', 'de', 'by'];

export interface ICountryListItem {
  code: string;
  icon: string;
  name: string;
}

export default function CountryList({
  onSelect,
  className = '',
}: {
  onSelect(countryCode: string): void;
  className?: string;
}) {

  const [searchString, setSearchString] = React.useState<string>('');
  const [searchStringResult, setSearchStringResult] = React.useState<string>('');


  const countryList = React.useMemo(() => {
    let top: ICountryListItem[] = [];
    let other: ICountryListItem[] = [];

    countries.forEach((country) => {
      const name = getNameRu(country);
      const code = country.toLocaleLowerCase();
      const icon = getUnicodeFlagIcon(country);
      const countryItem = { code, icon, name };

      if (name && topCountries.includes(country.toLocaleLowerCase())) {
        top.push(countryItem);
      } else if (name) {
        other.push(countryItem);
      }
    })

    return [
      ...sortBy(top, (country) => topCountries.indexOf(country.code)),
      ...sortBy(other, 'name')
    ]
    .filter(({name: countryName}) => {
      const searchString = searchStringResult?.trim().toLowerCase() || '';
      return searchString.length > 2
        ? countryName.toLocaleLowerCase().includes(searchString)
        : true
    })
  }, [searchStringResult]);

  const onListClick = (evt: MouseEvent) => {
    const listItem: HTMLElement = (evt.target as HTMLElement).closest('li[data-country-code]')!;
    const code = listItem?.dataset.countryCode
    if (!code) return;
    onSelect(code);
  }

  const debounceSearch = React.useCallback(debounce(setSearchStringResult, 350), []);

  const onSearchInput = (val: string) => {
    debounceSearch(val);
    setSearchString(val);
  }

  return (
    <CountryListContainer className={className}>
      <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <Input
          value={searchString}
          onChange={onSearchInput}
          placeholder={'Поиск'}
        />
      </div>
      <List onClick={onListClick}>
        {
          countryList?.map((country, idx) => {
            return <li key={`country_key_${idx}`} data-country-code={country.code}>
              <span className="icon">{country.icon}</span>
              <span className="name">{country.name}</span></li>
          })
        }
      </List>
    </CountryListContainer>
  );
}

const CountryListContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  overflow-y: auto;

  & li {
    display: flex;
    position: relative;
    padding: 0.75rem 0;
    cursor: pointer;
    align-items: center;

    & + li {
      border-top: 1px solid var(--border);
    }

    & .icon {
      font-size: 1.7rem;
      margin-left: 1rem;
    }
    & .name {
      margin-left: 1rem;

      font-size: 0.875rem;
      max-width: 80%;
    }
  }
`;
