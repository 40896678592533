import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { TariffSwitcher } from "src/components/payment/TariffSwitcher";
import Policy from "src/components/Policy";
// import ReRecaptcha, { TReReCaptchaApi } from "src/components/ReRecaptcha";
import { Back } from "src/components/ui/Back";
import { Button } from "src/components/ui/Button/Button";
import { CloseButton } from "src/components/ui/CloseButton";
import { Select } from "src/components/ui/Select";
import { Colors, PromocodeButton } from "src/modules/promocode/PromocodeButton";
import store from "src/store/Store";
import { windowsStore } from "src/store/Windows";
import styled from "styled-components";
import { cards, ICard } from "../helpers/tariffs";
import { popupsStore } from "src/store/Popups";
import { history } from "src/MainRouter";
import { Indi, PARTNER_TARGET } from "../../../libs";


export const WINDOW_PAYMENT_SUBSCRIPTION = 'WINDOW_PAYMENT_SUBSCRIPTION'

export const openPaymentSubscription = () => {
  windowsStore.open(
    WINDOW_PAYMENT_SUBSCRIPTION,
    <PaymentSubscription />,
    'fade'
  )
}

export const PaymentSubscription = observer(() => {
  const tariffs = store.events.tariffs.filter(tariff => tariff.price > 0)
  const promoData = store.promocode.promoData
  const userId = store.sessionData?.id
  const partnerDiscount = store.partner.partnerDiscount

  const getDiscount = () => {
    if (partnerDiscount?.originalTarget?.includes(PARTNER_TARGET.ChronosPlus)) {
      return partnerDiscount.partnerDiscount ?? 0
    } else {
      return 0
    }
  }

  const [selectedTarifIndex, setSelectedTarifIndex] = useState(0);
  const [ currentTariff, setCurrentTariff ] = useState(tariffs[selectedTarifIndex]);
  const [ currentPrice, setCurrentPrice ] = useState(Math.round(currentTariff.price * (1 - getDiscount())))
  const [ card, setCard ] = useState(cards[selectedTarifIndex])
  const [ isWaiting, setIsWaiting ] = useState(false)

  // const captchaRef = useRef<TReReCaptchaApi | null>(null)

  useEffect(() => {
    store.events.loadTariffs(card.country)
  }, [])

  useEffect(() => {
    const tariffs = store.events.tariffs.filter(tariff => tariff.price > 0)
    setCurrentTariff(tariffs[selectedTarifIndex])
    setCurrentPrice(Math.round(tariffs[selectedTarifIndex].price * (1 - getDiscount())))
  }, [store.events.tariffs])

  // useEffect(() => {
  //   if (captchaRef.current) {
  //     setIsWaiting(false)
  //   }
  // }, [captchaRef.current])

  const onBack = () => {
    windowsStore.close(WINDOW_PAYMENT_SUBSCRIPTION)
  }

  const onClose = () => {
    windowsStore.closeAll()
  }

  const success = async () => {
    await store.refresh()
		history.push('/')
		windowsStore.closeAll()
		popupsStore.closeAll()
  }

  const onPayment = async () => {
    try {
      // const captchaToken = await captchaRef.current?.executeAsync()
      // if (!captchaToken) return

      setIsWaiting(true)
      await store.subscription.payment(currentTariff, card.id, success, userId)
    } catch(e) {
      promoData && store.promocode.cancelPromocode(promoData.id, undefined)
      console.error(`Payment subscription error: ${e}`)
    }

    setIsWaiting(false)
  }

  const onTariffSelectHandler = (tariff: Indi.indiGetTariffs.Tariff, price: number, index: number) => {
    setSelectedTarifIndex(index);
    setCurrentTariff(tariff);
    setCurrentPrice(price);
  }

  const onCardSelectHandler = (card: ICard) => {
    store.events.loadTariffs(card.country)
    setCard(card)
  }

  const getExpiryDate = (period: number) => {
    return dayjs().add(period, 'month').format('DD MMMM YYYY')
  }

  return (
    <Container>
      <Main>
        <Header>
          <div className="controls">
            <Back top={1} left={1} onBack={onBack} />
            <CloseButton top={1} right={1} onClose={onClose} circle/>
          </div>
          <div className="title">Начните лучше <br/> понимать себя <br/>с <span>Chronos Plus</span></div>
        </Header>

        <TariffSwitcher
          tariffs={tariffs}
          current={currentTariff}
          onTariffSelect={onTariffSelectHandler}
          discount={getDiscount()}
          symbol={card.symbol}
          useLocalTitle
          promoData={promoData}
        />

        <div className="buy-attributes">
          <div className="left side">
            <Select plain title="" options={cards} value={card} onSelect={onCardSelectHandler} />
          </div>
          <div className="right side">
            <PromocodeButton subscription applied={!!promoData} disabled={isWaiting} color={Colors.GRAY}/>
          </div>
        </div>

        <div className={'description'}>Оформим подписку за {currentPrice} {card.symbol} до {getExpiryDate(currentTariff?.period)}, отменить можно в любой момент</div>
      </Main>

      <Footer>
        <StyledButton disabled={isWaiting} onClick={onPayment} color='green'>Оформить подписку</StyledButton>
        <StyledPolicy country={card.id} fixedPosition={false}/>

        {/* <ReRecaptcha size="invisible" badge="bottomleft" ref={captchaRef}/> */}
      </Footer>
    </Container>
  )
})


const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;

  height: 100%;
  padding: 1rem;
  overflow: auto;
`

const Header = styled.header`
  text-align: center;
  margin-bottom: 2rem;

  .title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;

    & > span {
      background-image: linear-gradient(to right, #7D79EA, #D646D9, #E8AA63);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
    }
  }

  .subtitle {
    font-size: 1.5rem;
    font-weight: 500;
  }
`

const Main = styled.main`
  .buy-attributes {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    margin-top: 1rem;
    position: relative;

    .side {
      flex: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .description {
    font-size: 0.6875rem;
    color: var(--text-secondary);
    text-align: center;
    width: 100%;
    margin-top: 0.75rem;
  }
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

`

const StyledButton = styled(Button)`
  color: var(--text-primary);
`

const StyledPolicy = styled(Policy)`
  margin-top: 0;
`
