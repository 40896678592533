import dayjs from 'dayjs';
import { useEffect, useRef } from "react";
import { isMobile } from 'src/utils';
import { gtmSend } from '../helpers/url';
import { Logging } from '../libs';
import { getBlogLs, setBlogLs } from '../modules/blog/helpers/blog';
import { getMainPageLS, setMainPageLS } from '../store/localStorageHelpers';
import { shutterBottom, ShutterPositions } from '../store/Shutter';
import store from '../store/Store';


const minDistForClose = 80;

const getOpacityBackground = (color: string, opacity: number) => {
  return color.replace(')', `, ${opacity})`);
}

export const usePopupScroll = (nodeRef: React.RefObject<HTMLDivElement>, onClose: () => void) => {
  const startY = useRef(0);
  const diff = useRef(0);
  const background = useRef('');
  const isStarted = useRef(false);

  const touchStart = (e: TouchEvent) => {
    if (!nodeRef.current) return

    if (nodeRef.current!.querySelector('.popup-scroll')?.classList.contains('block-scroll')) return

    const scrollTop = nodeRef.current!.querySelector('.popup-scroll')!.scrollTop;

    if (scrollTop <= 0) {
      startY.current = e.touches[0].clientY;
      isStarted.current = true;

      nodeRef.current!.querySelector('div')!.style.transition = `none`;
    }
  }

  const touchMove = (e: TouchEvent) => {
    if (!nodeRef.current) return

    if (nodeRef.current!.querySelector('.popup-scroll')?.classList.contains('block-scroll')) return

    const scrollTop = nodeRef.current!.querySelector('.popup-scroll')!.scrollTop;

    if (scrollTop <= 0) {
      if (!isStarted.current) {
        touchStart(e);
      }

      diff.current = Math.max(e.touches[0].clientY - startY.current, 0);

      if (diff.current < 40) return;

      if (diff.current > 0) {
        nodeRef.current!.style.transition = `none`;
        nodeRef.current!.querySelector<HTMLDivElement>('.popup-scroll')!.style.overflow = 'hidden';
      }

      nodeRef.current!.querySelector('div')!.style.transform = `translateY(${diff.current}px)`;

      nodeRef.current!.style.backgroundColor = getOpacityBackground(background.current, 1 - diff.current / window.innerHeight);
    }
  }

  const touchEnd = (e: TouchEvent) => {
    if (!nodeRef.current) return

    if (nodeRef.current!.querySelector('.popup-scroll')?.classList.contains('block-scroll')) return

    isStarted.current = false;

    let y = '0px';
    let opacity = 1;
    if (diff.current > minDistForClose) {
      y = '100vh';
      opacity = 0;
      onClose();
    }

    nodeRef.current!.querySelector('div')!.style.transition = `transform 0.6s`;
    nodeRef.current!.querySelector('div')!.style.transform = `translateY(${y})`;

    nodeRef.current!.style.transition = `background-color 0.6s`;
    nodeRef.current!.style.backgroundColor = getOpacityBackground(background.current, opacity);

    nodeRef.current!.querySelector<HTMLDivElement>('.popup-scroll')!.style.overflow = 'auto';
  }

  useEffect(() => {
    setTimeout(() => {
      nodeRef.current?.addEventListener('touchstart', touchStart);
      nodeRef.current?.addEventListener('touchmove', touchMove);
      nodeRef.current?.addEventListener('touchend', touchEnd);

      if (nodeRef.current) {
        background.current = window.getComputedStyle(nodeRef.current).getPropertyValue('background-color');
      }
    }, 1000);

  }, [])
}

export const useMainPopupScroll = (
  popupRef: React.RefObject<HTMLDivElement> | null,
  overlayRef: React.RefObject<HTMLDivElement> | null,
  newsBannersRef: React.RefObject<HTMLDivElement> | null,
  userId: string,
) => {
  const startY = useRef(0);
  const diff = useRef(0);
  const posY = useRef(0);
  const state = useRef('bottom');
  const lastTop = useRef(0);
  const isStarted = useRef(false);
  const isActive = useRef(false);
  const gapRef = useRef(0);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const checkUsedShutter = () => {
    const mainPageLS = getMainPageLS()

    if (!mainPageLS.usedShutter) {
      const newData = {
        ...mainPageLS,
        usedShutter: true,
      }

      setMainPageLS(newData)
    }
  }

  const touchStart = (e: TouchEvent) => {
    if (!popupRef?.current || !overlayRef?.current || !isMobile) return

    const scrollTop = popupRef.current.scrollTop;

    if (scrollTop <= 0) {
      startY.current = e.touches[0].clientY;
      posY.current = popupRef.current.getBoundingClientRect().top;
      popupRef.current.style.transition = `none`;
      overlayRef.current.style.transition = `none`;
      overlayRef.current.style.display = 'block';
      isStarted.current = true;
      gapRef.current = Number(popupRef.current.style.transform.match(/\d/g)?.join('')) || shutterBottom
    }

    if (state.current === 'bottom') {
      gtmSend({ 'event': `indi_hub_open`, 'user_id': userId ? userId : '' })
    }
  }

  const touchMove = (e: TouchEvent) => {
    if (!popupRef?.current || !overlayRef?.current || !isMobile) return

    const scrollTop = popupRef.current!.scrollTop;

    if (scrollTop <= 0) {

      if (!isStarted.current) {
        touchStart(e);
      }

      let percent = 0;

      diff.current = e.touches[0].clientY - startY.current;

      if (state.current === 'bottom') {
        if (diff.current < 0) {
          popupRef.current!.style.transform = `translateY(${diff.current - gapRef.current}px)`;
          percent = Math.abs(diff.current) / window.innerHeight;
          overlayRef.current!.style.opacity = String(percent);
        }
      }

      if (state.current === 'middle') {
        popupRef.current!.style.transform = `translateY(${diff.current - lastTop.current}px)`;
        percent = Math.abs(lastTop.current) / window.innerHeight;
        overlayRef.current!.style.opacity = String(percent);
      }

      if (state.current === 'top') {
        if (e.touches[0].clientY >= window.innerHeight) {
          return false;
        }
        else if (diff.current > 0) {
          const gapForSmoothSwipe = 35
          popupRef.current!.style.overflow = 'hidden';
          popupRef.current!.style.transform = `translateY(${diff.current - lastTop.current - gapForSmoothSwipe}px)`;
          percent = Math.abs(diff.current - lastTop.current) / window.innerHeight;
          overlayRef.current!.style.opacity = String(percent);
        }
      }

      clearTimeout(timerRef.current)

      timerRef.current = setTimeout(() => {
        let y

        if (state.current === 'bottom') {
          y = `-${shutterBottom}px`;
        } else {
          y = `calc(-${lastTop.current}px)`;
        }

        popupRef.current!.style.transition = `transform 0.6s`;
        popupRef.current!.style.transform = `translateY(${y})`;
      }, 1000)
    }
  }

  const touchEnd = (e: TouchEvent) => {
    if (!popupRef?.current || !overlayRef?.current || !isMobile) return;

    isActive.current = true;

    let y = '';
    let opacity = ''
    const gap = 20

    if (state.current === 'top') {
      if (diff.current > minDistForClose) {
        store.shutter.setPosition(ShutterPositions.BOTTOM)
        state.current = 'bottom';
        y = `-${shutterBottom}px`;
        opacity = '0';
        lastTop.current = 0;
        popupRef.current.style.overflow = 'hidden';
        overlayRef.current.style.display = 'none';

        const subScreen = document.getElementById('main-page-active-subscription-screen')
        if (subScreen) subScreen.style.overflowY = 'auto';

        store.logger.createLoggingSession(Logging.Screens.MENU)

        setTimeout(() => {
          if (newsBannersRef?.current) newsBannersRef.current.style.display = 'flex'
        }, 300)
      } else {
        y = `calc(-${lastTop.current}px)`;
        opacity = '0.8';
        popupRef.current!.style.overflow = 'auto';
      }
    }

    if (state.current === 'middle') {
      if (diff.current < -1 * minDistForClose) {
        store.shutter.setPosition(ShutterPositions.TOP)
        state.current = 'top';
        y = `calc(-${(lastTop.current) * 2 - shutterBottom - gap}px)`;
        opacity = '0.6';
        popupRef.current!.style.overflow = 'auto';
        lastTop.current = lastTop.current * 2 - shutterBottom - gap;
      } else if (diff.current > minDistForClose) {
        store.shutter.setPosition(ShutterPositions.BOTTOM)
        state.current = 'bottom';
        y = `-${shutterBottom}px`;
        opacity = '0';
        popupRef.current!.style.overflow = 'hidden';
        overlayRef.current!.style.display = 'none';
        const subScreen = document.getElementById('main-page-active-subscription-screen')
        if (subScreen) subScreen.style.overflowY = 'auto';

        setTimeout(() => {
          if (newsBannersRef?.current) newsBannersRef.current.style.display = 'flex'
        }, 300)
      } else {
        y = `calc(-${lastTop.current}px)`;
        opacity = '0.6';
      }
    }

    if (state.current === 'bottom') {
      if (diff.current < -1 * minDistForClose) {
        store.shutter.setPosition(ShutterPositions.MIDDLE)
        state.current = 'middle';
        y = `calc(-${posY.current / 2 + shutterBottom}px)`;
        opacity = '0.6';
        lastTop.current = posY.current / 2 + shutterBottom;
        popupRef.current!.style.overflow = 'hidden';

        const subScreen = document.getElementById('main-page-active-subscription-screen')
        if (subScreen) subScreen.style.overflowY = 'hidden';

        if (newsBannersRef?.current) {
          newsBannersRef.current.style.display = 'none'
          store.blog.setShowBlogNew(false)
        }

        store.logger.startLoggingSession(Logging.Screens.MENU)

        checkUsedShutter()
      } else {
        y = `-${shutterBottom}px`;
        opacity = '0';
        popupRef.current!.style.overflow = 'hidden';
        overlayRef.current!.style.display = 'none';
      }
    }

    popupRef.current!.style.transition = `transform 0.6s`;
    popupRef.current!.style.transform = `translateY(${y})`;

    overlayRef.current!.style.transition = `opacity 0.6s`;
    overlayRef.current!.style.opacity = opacity;

    diff.current = 0

    const newBlogLs = getBlogLs();
    newBlogLs.lastOpen = +Date.now();
    setBlogLs(newBlogLs);
    clearTimeout(timerRef.current)
  }

  const rollDown = (event?: MouseEvent | TouchEvent) => {
    if (!popupRef?.current || !overlayRef?.current || !isMobile) return

    event && event.preventDefault()

    if (popupRef?.current) {
      popupRef.current.style.transition = `transform 0.6s`;
      popupRef.current.style.transform = `translateY(-${shutterBottom}px)`;
      popupRef.current.style.scrollBehavior = 'auto';
      popupRef.current.scrollTop = 0;
      popupRef.current.style.scrollBehavior = 'smooth';

      overlayRef.current.style.transition = `opacity 0.6s`;
      overlayRef.current.style.opacity = '0';
      overlayRef.current.style.display = 'none';

      store.shutter.setPosition(ShutterPositions.BOTTOM)
      state.current = 'bottom';
      lastTop.current = shutterBottom;
      popupRef.current.style.overflow = 'hidden';
      diff.current = 0

      const subScreen = document.getElementById('main-page-active-subscription-screen')
      if (subScreen) subScreen.style.overflowY = 'auto';
    }
  }

  useEffect(() => {
    setTimeout(() => {
      popupRef?.current?.addEventListener('touchstart', touchStart);
      popupRef?.current?.addEventListener('touchmove', touchMove);
      popupRef?.current?.addEventListener('touchend', touchEnd);

      overlayRef?.current?.addEventListener('touchend', rollDown);
    }, 2000);
  }, [popupRef])

  return {
    rollDown,
  }
}

export const usePopupScrollToTop = (selectedDay: dayjs.Dayjs) => {
  useEffect(() => {
    document.querySelectorAll('.popup-scroll').forEach(popup => popup.scrollTo(0, 0));
  }, [selectedDay])
}

export const useMoveShutter = (
  pageRef: React.RefObject<HTMLDivElement>,
  shutterRef: React.RefObject<HTMLDivElement> | null,
) => {
  const directionRef = useRef('down')
  const previousScrollTop = useRef(0);
  const turnPoint = useRef(0);

  const bottomPosition = 80

  useEffect(() => {
    const moveShutter = () => {
      if (!shutterRef?.current || !isMobile) return

      const scrollTop = pageRef.current?.scrollTop ?? 0

      if (directionRef.current === 'down' && scrollTop < previousScrollTop.current) {
        turnPoint.current = scrollTop
        directionRef.current = 'up'
      }

      if (directionRef.current === 'up' && scrollTop > previousScrollTop.current) {
        turnPoint.current = scrollTop
        directionRef.current = 'down'
      }

      if (scrollTop - turnPoint.current > 200) {
        shutterRef.current.style.transition = `transform 0.6s`
        shutterRef.current.style.transform = `translateY(${bottomPosition}px)`

        store.newsBanners.setShowNewsBanners(false)
      }

      if (scrollTop - turnPoint.current < -200) {
        shutterRef.current.style.transition = `transform 0.6s`
        shutterRef.current.style.transform = `translateY(-${shutterBottom}px)`

        setTimeout(() => {
          store.newsBanners.setShowNewsBanners(true)
        }, 300)
      }

      previousScrollTop.current = scrollTop

      const overlay = document.querySelector('.main-page_overlay') as HTMLElement
      if (overlay && overlay.style.display === 'block') {
        overlay.style.display = 'none'
      }
    }

    pageRef.current?.addEventListener('scroll', moveShutter)
  }, [pageRef, shutterRef])
}
