import { makeAutoObservable } from "mobx";
import api, { getHTTPMessage } from "../helpers/api";
import store from "./Store";
import { Indi, getUTMCommentForCP, show } from "src/libs";
import { getSubscriptionLS, setSubscriptionLS } from "./localStorageHelpers";
import { openSubscribeBanner } from "src/modules/subscription/SubscribeBanner/SubscribeBanner";
import dayjs from "dayjs";


export enum SubscriptionStatus {
  ACTIVE = 'Активна',
  INACTIVE = 'Неактивна',
  TRIAL = 'Пробный период',
  NOT_PASSED = 'Оплата не прошла',
}

export const subscriptionPaymentType = 'indiSubscribe'

export class Subscription {
  constructor() {
    makeAutoObservable(this)
  }

  subscriptionStatus: SubscriptionStatus = SubscriptionStatus.INACTIVE

  async updateSubscribe(auto_payment: boolean, reason?: string) {
    try {
      await api.indiUnsubscribe({auto_payment, reason})
      await store.refresh()
    } catch(e) {
      console.error(`Error in updating subscription: ${e}`)
    }
  }

  setSubscriptionStatus(status: SubscriptionStatus) {
    this.subscriptionStatus = status
  }

  async payment(
    tariff: Indi.indiGetTariffs.Tariff,
    country: string,
    closePaymentPaywall: () => void,
    userId?: number,
    captchaToken?: string,
  ) {
    const promoData = store.promocode.promoData

    try {
      if (promoData) {
        const result = await store.promocode.usePromocode(promoData.title)
        const isFreeCharge = result.subscription === 1 || result.subscription === 2
        if (isFreeCharge) {
          closePaymentPaywall()
          return
        }
      }

      const data = {
        captchaToken,
        type: subscriptionPaymentType,
        subscription: tariff.id,
        period: tariff.period,
        country,
        language: 'ru',
        comment: getUTMCommentForCP(),
        promocodeTitle: promoData?.title,
        userId,
      }

      // console.log('Subscription payment data -', data)

      const result = await api.payment(
        data,
        'charge',
      )

      if (result.failed) {
        promoData && store.promocode.cancelPromocode(promoData.id, undefined)

        show({
          type: 'error',
          text: getHTTPMessage(result?.error?._errors),
        });
      } else {
        closePaymentPaywall()
      }
    } catch(e) {
      promoData && store.promocode.cancelPromocode(promoData.id, undefined)
      console.error(`Error in subscription payment: ${e}`)
    }
  }

  showSubscribeBanner() {
    const subscriptionLS = getSubscriptionLS()

    const { trialExpiryDate, autoPayment } = store.sessionData?.indi || {}

    if (!trialExpiryDate || autoPayment) return

    if (
      !subscriptionLS.showedThreeDaysBeforeTrialEnd &&
      dayjs(trialExpiryDate).diff(dayjs(), 'day') === 3
    ) {
      openSubscribeBanner(3)

      subscriptionLS.showedThreeDaysBeforeTrialEnd = true
      setSubscriptionLS(subscriptionLS)

      return
    }

    if (
      !subscriptionLS.showedOneDayBeforeTrialEnd &&
      dayjs(trialExpiryDate).diff(dayjs(), 'day') === 1
    ) {
      openSubscribeBanner(1)

      subscriptionLS.showedOneDayBeforeTrialEnd = true
      setSubscriptionLS(subscriptionLS)

      return
    }

    if (
      (
        !subscriptionLS.showedTrialEnd ||
        !dayjs(subscriptionLS.showedTrialEnd).isToday()
      ) &&
      dayjs().isAfter(trialExpiryDate) &&
      dayjs().diff(dayjs(trialExpiryDate), 'day') < 8
    ) {
      openSubscribeBanner()

      subscriptionLS.showedTrialEnd = dayjs().toISOString()
      setSubscriptionLS(subscriptionLS)
    }
  }
}
