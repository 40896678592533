import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import banner from 'src/assets/icons/payment/banner.png';
import beAware from 'src/assets/icons/payment/be_aware.png';
import personal from 'src/assets/icons/payment/personal.png';
import plan from 'src/assets/icons/payment/plan.png';
import quality from 'src/assets/icons/payment/quality.png';
import { Button } from "src/components/ui/Button/Button";
import { gtmSend, searchParams } from "src/helpers/url";
import { openEditWindow } from "src/modules/edit/Edit";
import { openSubscribeBannerWithCard } from "src/modules/subscription/SubscribeBannerWithCard/SubscribeBannerWithCard";
import store from "src/store/Store";
import { trialDurations } from "src/store/Trial";
import { windowsStore } from "src/store/Windows";
import styled from "styled-components";

const list = [
  {
    title: 'Планируйте свои дела',
    subtitle: 'по всем сферам жизни',
    icon: plan,
  },
  {
    title: 'Персональный прогноз',
    subtitle: 'на каждый день',
    icon: personal,
  },
  {
    title: 'Будьте в курсе',
    subtitle: 'будущих событий влияющих на вас',
    icon: beAware,
  },
  {
    title: 'Улучшайте качество жизни',
    subtitle: 'применяйте персональные рекомендации',
    icon: quality,
  },
];

export const WINDOW_PAYMENT_WITHOUT_CARD = 'WINDOW_PAYMENT_WITHOUT_CARD'

export const openPaymentWithoutCard = () => {
  windowsStore.open(
    WINDOW_PAYMENT_WITHOUT_CARD,
    <PaymentWithoutCard />,
    'fade'
  )
}

export const PaymentWithoutCard = observer(() => {
  const profile = store.profile

  const sp = searchParams()
  const period = Number(sp.get('d'))
  const trialDuration = trialDurations.includes(period) ? period : 7

  useEffect(() => {
    gtmSend({ event: 'indi_trialpagenewdark_free7_view', user_id: store.userId });
  }, []);

  const onTrial = async () => {
    windowsStore.close(WINDOW_PAYMENT_WITHOUT_CARD);
    openSubscribeBannerWithCard(trialDuration)
  }

  const onTrialHandler = () => {
    gtmSend({ event: 'indi_trialpagenewdark_free7_click', user_id: store.userId })
    if (profile.email) {
      onTrial()
    } else {
      openEditWindow({
        showConsent: true,
        iconButton: true,
        onProfileUpdate: onTrial,
      });
    }
  }

  return (
    <Container>
      <Header>
        <div className="title">Попробуйте <br/> aстро-помощник <br/> <span>Chronos Plus</span></div>
        <div className="subtitle">Без привязки карты</div>
      </Header>

      <List>
        {
          list.map((item, index) => (
            <Item key={index}>
              <img src={item.icon} alt="icon"/>
              <div className="description">
                <div className="title">{item.title}</div>
                <div className="subtitle">{item.subtitle}</div>
              </div>
            </Item>
          ))
        }
      </List>

      <Footer>
        <StyledButton onClick={onTrialHandler} color="green">Попробовать {trialDuration} дней бесплатно</StyledButton>
      </Footer>
    </Container>
  )
})


const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1rem;

  background-image: url(${banner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  padding: 2.4rem;
  overflow-y: auto;
`

const Header = styled.header`
  text-align: center;

  .title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: var(--text-primary-white);

    & > span {
      color: var(--text-primary-white);
      background-clip: text;
      -webkit-background-clip: text;
    }
  }

  .subtitle {
    color: var(--color-green);
    font-size: 1.5rem;
    font-weight: 500;
  }
`

const List = styled.ul`
  margin-top: 2rem;
`

const Item = styled.li`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .description {
    display: flex;
    flex-direction: column;
    color: var(--text-primary-white);

    .title {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .subtitle {
      font-size: 0.75rem;
    }
  }
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

`

const StyledButton = styled(Button)`
  color: var(--text-primary);
`
