import { observer } from "mobx-react-lite";
import { Button } from "src/components/ui/Button/Button";
import { popupsStore } from "src/store/Popups";
import styled from "styled-components";


const WINDOW_ID_CALC_EXPLANATION = 'WINDOW_ID_CALC_EXPLANATION'
const CONSULTATION_CALC_EXPLANATION_HEIGHT = '-320px'

export const openCalcExplanation = () => {
  popupsStore.open(
    WINDOW_ID_CALC_EXPLANATION,
    <CalcExplanation />,
    '',
    undefined,
    undefined,
    CONSULTATION_CALC_EXPLANATION_HEIGHT,
  )
}

export const CalcExplanation = observer(() => {
  const onClose = () => {
    popupsStore.close(WINDOW_ID_CALC_EXPLANATION)
  }

  return (
    <Container>
      <Content>
        <div className={'title'}>Как читать результаты</div>
        <div className={'text'}>
          <p>Описание личности — это интерпретация натальной карты. Это ваши характеристики, потенциал и возможные ограничения, заложенные изначально.</p>
          <br/>
          <p>Описание личности помогает лучше понять себя и свои "базовые настройки". Используйте его как инструмент для самопознания и развития. Осознанные изменения и привычки помогут усилить сильные стороны и проработать слабые, улучшая качество жизни.</p>
        </div>
      </Content>

      <Button onClick={onClose}>Продолжить</Button>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;

  height: 17rem;
  padding: 0.25rem 1rem;
`

const Content = styled.div`
  .title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .text {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin-top: 0.5rem;
  }
`
