import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Title from './components/Title';
import store from '../../store/Store';
import {observer} from 'mobx-react-lite';
import SessionCard from '../../components/SessionCard';
import Loader from '../../components/ui/Loader';
import {getNameForButton, groupingServices} from './helpers/services';
import { ArrowSmall as ArrowIcon } from '../../assets/icons/system/system-icons';
import ButtonSwitcher from '../../components/ui/ButtonSwitcher';
import {sessionFormatOptions} from './Services';
import ServiceGroups from './components/ServiceGroups';
import { CustomButton } from './components/BottomButton';
import useLS, { useLSState } from '../../hooks/useLS';
import { SERVICE_ID } from '../../helpers/ls/consultations';
import { searchParams } from '../../helpers/url';
import { history } from '../../MainRouter';
import { IInfoEx, IServiceDataEx, IWorkProfileEx, SessionFormat } from '../../libs';

interface IServiceGroup {
  type: IInfoEx;
  services: IServiceDataEx[];
}

export default observer(function ServiceConfirmation(): JSX.Element {

  const [serviceGroups, setServiceGroups] = useState<IServiceGroup[] | null>(null);
  const [professional, setProfessional] = useState<IWorkProfileEx | null>(null);
  const [sessionFormat, setSessionFormat, saveSessionFormat] = useLS('session-format', SessionFormat.Online);
  const [serviceId, setServiceId] = useLSState(SERVICE_ID);
  const [professionalId] = useLS('professional-id');
  const [sessionCreator] = useLS('session-creator');

  const price = store.getService(serviceId!)?.price.value
  const sp = searchParams()

  useEffect(() => {
    setProfessional(store.professional);
  }, [store.professional]);

  useEffect(() => {
    if (professionalId) {
      store.setProfessional(professionalId);
    } else {
      history.push({pathname: '/services', search: sp.toString()});
    }
  }, []);

  useEffect(() => {
    if (store.services && store.serviceCategories && professional) {
      const profServices = professional.services.reduce((acc, service, i) =>  {
        //@ts-ignore
        if (sessionCreator !== 'direct' && service.userId) return { ...acc }; // если это не direct, то не показываем персональные услуги ( признак - наличие у услуги userId)
        const calcService: any = (store.services![service.id] || service);
        const serviceUnion = { ...calcService, price: { ...service.price }, format: +service.format, category: calcService.category || 1 };
        return { ...acc, [i + 1]: serviceUnion }
      }, {});

      const services = groupingServices(profServices, store.serviceCategories, sessionFormat);
      setServiceGroups(services);
    }
  }, [store.services, store.serviceCategories, professional, sessionFormat]);

  const onCardClick = async (service: IServiceDataEx) => {
    saveSessionFormat();
    setServiceId(service.id);
  };

  const confirmHandler = () => {
    history.push({pathname: '/confirmation', search: sp.toString()});
    serviceId !== null && store.setCurrentService(serviceId);
  };

  if (!serviceGroups || !professional) {
    return <Loader />;
  }

  return <ServiceConfirmationContainer>
    <Title text={`${professional.profile.firstName} ${professional.profile.lastName || ''}`} />

    <ButtonSwitcher
      options={sessionFormatOptions}
      value={sessionFormat}
      onChange={setSessionFormat}
    />

    <ServiceGroups groups={serviceGroups} sessionFormat={sessionFormat} onServiceClick={onCardClick} />

    {<CustomButton
      disabled={!serviceId}
      color={'gradient'}
      onClick={confirmHandler}
    >
      <span dangerouslySetInnerHTML={{ __html: getNameForButton(serviceId, price) }} /> <ArrowIcon/>
    </CustomButton>}
  </ServiceConfirmationContainer>;
});

const SessionContainer = styled.div`
  display: flex;
  overflow-x: scroll;
`;

const CustomSessionCard = styled(SessionCard)`
  width: 100%;
`;

const ServiceConfirmationContainer = styled.main`
  padding-bottom: 46px;
  display: flex;
  flex-direction: column;
`;
