import React from 'react';
import styled, { css } from "styled-components";
import { PopupHeader } from '../../../modules/popups/components/PopupHeader';
import { FlexContainer } from '../../layout/elements';
import { isMobile } from 'src/utils';
import { KnobIcon } from '../../../assets/icons/system/system-icons';

export const PopupContainer = ({
  children,
  onClose,
  title,
  background = 'var(--popup-background)',
  style = {},
  popupRef,
  className,
  fixed,
  firstScreen,
  userId,
  username,
  showNotification,
}: {
  children: React.ReactNode,
  onClose?: () => void,
  title?: string;
  background?: string,
  style?: React.CSSProperties,
  popupRef?: React.RefObject<HTMLDivElement>,
  className?: string,
  fixed?: boolean,
  firstScreen?: boolean
  userId?: number
  username?: string
  showNotification?: boolean
}) => {

  const closeHandler = () => {
    onClose?.();
  }

  const classNames = ['popup-scroll'];
  if(className) classNames.push(className);

  const gap = () => {
    if (isMobile) {
      if (window.innerHeight < 700) {
        return 5
      } else {
        return 4
      }
    } else {
      return 0
    }
  }

  return (
        <Wrapper
          className={classNames.join(' ')}
          style={style}
          ref={popupRef}
          showNotification={showNotification}
        >
          <Container background={background} gap={gap()}>
            <Header onClick={closeHandler} fixed={fixed}>{isMobile && <KnobIcon />}</Header>
            {title && <PopupHeader
                        title={title}
                        onBack={closeHandler}
                        firstScreen={firstScreen}
                        userId={userId}
                        username={username}/> }
            <Content>{children}</Content>
          </Container>
        </Wrapper>
  )
}

const Wrapper = styled.div<{showNotification?: boolean}>`
  position: relative;
  height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  -webkit-overscroll-behavior-y: contain;
`

const Container = styled.div<{background: string, gap: number}>`
  position: relative;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  -webkit-overscroll-behavior-y: contain;
  background: ${p => p.background};
  padding-bottom: ${p => p.gap}rem;
  border-radius: 32px 32px 0px 0px;

  ${isMobile && css`
    border-radius: 32px 32px 0px 0px;

    -webkit-box-shadow: 0px -2px 5px 2px rgba(0,0,0,0.1);
    box-shadow: 0px -2px 5px 2px rgba(0,0,0,0.1);
  `}
`

const Header = styled(FlexContainer)<{fixed?: boolean}>`
  position: relative;
  padding-bottom: 0.25rem;
  justify-content: center;

  ${p => p.fixed && css`
    position: fixed;
    width: 100%;
    border-radius: 32px 32px 0px 0px;
    z-index: 100;
  `}
`

const Content = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  -webkit-overscroll-behavior-y: contain;
`
