import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { openEditWindow } from 'src/modules/edit/Edit';
import { openPaymentWithoutCard } from 'src/modules/payment/components/payment-without-card';
import { openSubscribeBanner } from 'src/modules/subscription/SubscribeBanner/SubscribeBanner';
import Api from "../helpers/api";
import { getUTMCommentForCP, show } from '../libs';
import { openPaymentResultWindow } from '../modules/payment/PaymentResult';
import { ProductTokens } from '../modules/products/helpers/constants';
import { IAuthIndi } from './Auth';
import { getProductTokenLS, getPromocodeDataLS, setEmailLS, setPaymentIdLS, setProductTokenLS } from "./localStorageHelpers";
import { promocodeErrors, PromocodeErrorsType } from './Promocode';
import store from './Store';
import { openSmsAuthWindow } from 'src/modules/registration/sms/SmsAuth';
import api from '../helpers/api';
import { FreePeriod, TariffPeriod } from 'src/modules/payment/helpers/tariffs';
import { openPaymentWithTariffsWindow } from 'src/modules/payment/components/payment-paywall-tariffs';
import { windowsStore } from './Windows';

export type IProductCode = 'SYNASTRY' | 'EMOTIONS' | 'SOLAR' | 'PERSON'

type PaymentConfigType = {
  publicId: number | string;
  description: string;
  amount: number;
  currency: string;
  fullPrice: number;
}

interface CloudPaymentsItems {
  Label: string;
  Price: number;
  Quantity: string;
  Amount: number;
}

type CloudPaymentsType = {
  CustomerReceipt: {
    Items: CloudPaymentsItems[];
    TaxationSystem: number;
    isBso: boolean;
    Amounts: {
      electronic: number;
    };
  }
}

export default class Payments {
  constructor() {
    makeAutoObservable(this);
  }

  token: number = 0
  language: string = 'ru'
  type: string = 'indi'

  paymentConfig: PaymentConfigType | null = null
  paymentId: string = ''
  cloudPayments: CloudPaymentsType | null = null

  productCode: string | null = null
  K8S_NAMESPACE = '';

  userId: number = 0
  email: string | null = null
  phone: string | null = null

  async paymentConsultation(consultationId: number, cardCountry: string) {
    return await Api.payment({
      type: 'consultation',
      consultationId
    }, 'charge');
  }

  async paymentTinkoff(consultationId: number) {
    const url = `${window.location.origin}/tinkoff-payed/${consultationId}`;
    const successUrl = `${url}/success`;
    const failUrl = `${url}/fail`;
    const result: any = await Api.getSessionPaymentUrl(consultationId, {
      successUrl,
      failUrl
    })
    if (result?.url) window.location.href = result.url;

  }

  getProductToken(productCode: IProductCode) {
    const token = getProductTokenLS() ?? this.token

    if (token) {
      return token
    } else {
        return ProductTokens[productCode]
    }
  }

  async getPaymentConfig(productCode: IProductCode,
                         promocodeTitle: string,
                         update: boolean = false,
                         country: string,
                         purchasedProductId?: number,
                         headers?: {tentative: string},
                         partnersData: any = {},
                         captchaToken?: string | null | undefined) {
    let result;
    let data: {
      captchaToken: string | null | undefined;
      token: number;
      country: string;
      language: string;
      productCode: IProductCode;
      promocodeTitle: string;
      update: boolean;
      purchasedProductId: number | undefined;
      forUserEmail: string | null;
      comment?: string;
      data: any;
    } | undefined;

    try {
      await store.auth.checkToken()

      data = {
        captchaToken,
        token: this.getProductToken(productCode),
        country,
        language: this.language,
        productCode,
        promocodeTitle: promocodeTitle.trim(),
        update,
        purchasedProductId,
        forUserEmail: this.email,
        comment: getUTMCommentForCP(),

        data: {
          ...partnersData,
          K8S_NAMESPACE: this.K8S_NAMESPACE,
          cloudPayments: this.cloudPayments,
          user_id: this.userId,
          type: this.type,
          product_code: this.productCode,
          paymentId: this.paymentId,
          phone: this.phone,
          language: this.language,
          update,
          purchasedProductId,
        }

      }


      result = await Api.indiGetPaymentConfig(data, headers)
      //@ts-ignore
      if(result?.ioka?.success && result?.ioka?.url) {
        //@ts-ignore
        window.location.href = result.ioka.url;
        return null;
      }

      if (!result) throw new Error(`Can't get payment config`)

      this.paymentConfig = {...result.options};
      this.cloudPayments = {CustomerReceipt: {...result.receipt}}

      this.userId = result.user.id
      this.email = result.user.email
      this.phone = result.user.phone

      this.K8S_NAMESPACE = (result as any).K8S_NAMESPACE;

      this.setPaymentId(result.paymentId)

      return result;
    } catch(e: any) {
      show({
        text: promocodeErrors[e.message as keyof PromocodeErrorsType] || 'Неизвестная ошибка. Обратитесь в поддержку',
        type: 'error'
      });
      Api.sendNotify(`promo error 4: ${e.message}; result: ${result ? JSON.stringify(result) : 'null'}; data: ${data ? JSON.stringify(data) : 'null'}`);

      throw e
    }
  };

  async paymentCloud(
    {
      data,
      success,
      cancel,
      update = false,
      purchasedProductId,
      headers,
      discount,
      country
    }:{
      data: any,
      success: any,
      cancel?: any,
      update: boolean,
      purchasedProductId?: number,
      headers?: {
        tentative: string
      },
      discount: number,
      country: string
    }) {
    const promocodeId = getPromocodeDataLS()?.id

    const dataPost = {
      data: {
        ...data,
        K8S_NAMESPACE: this.K8S_NAMESPACE,
        cloudPayments: this.cloudPayments,
        user_id: this.userId,
        language: this.language,

        type: this.type,
        product_code: this.productCode,
        paymentId: this.paymentId,
        update,
        purchasedProductId,
        promo: promocodeId,
        phone: this.phone,
        forUserEmail: this.email,
      },
      country: country,
      accountId: this.userId,
      comment: getUTMCommentForCP(),
      email: this.email,
      ...this.paymentConfig,
    }

    if (discount && dataPost.amount) {
      let finalAmount = dataPost.amount * (1 - discount) + Number.EPSILON
      country === 'ru' ? 
      finalAmount = Math.round(finalAmount) :
      finalAmount = Math.round(finalAmount * 100) / 100

      const updatedItems = dataPost.data.cloudPayments.CustomerReceipt.Items.map((item: CloudPaymentsItems) => ({...item, Price: finalAmount, Amount: finalAmount}))

      dataPost.amount = finalAmount
      dataPost.data.cloudPayments.CustomerReceipt.Amounts.electronic = finalAmount
      dataPost.data.cloudPayments.CustomerReceipt.Items = updatedItems
    }

    try {
      await api.payment(dataPost, 'charge', { onSuccess: success, onCancel: cancel })
    } catch (e) {
      console.error(e);
    }
  }

  setToken(token: number) {
    setProductTokenLS(token.toString())
    this.token = token
  }

  setLanguage(token: number) {
    this.token = token
  }

  setProductCode(value: IProductCode) {
    this.productCode = value
  }

  resetPaymentConfig() {
    this.paymentConfig = null
  }

  setEmail(value: string) {
    this.email = value
    setEmailLS(value)
  }

  setPaymentId(id: string) {
    this.paymentId = id
    setPaymentIdLS(id)
  }

  paymentIndiProlongation = async () => {
    const email = store.profile.email || ''

    const data = {
      type: 'indiProlong',
      subscription: 2,
      period: 6,
      country: 'ru',
      language: 'ru',
      forUserEmail: email
    }

    console.log('paymentIndiProlongation payment data -', data)

    const result = await Api.payment(data, 'charge');

    if (result) {
      openPaymentResultWindow(email)
    }
  }

  moveUserToPaymentScreen(direct?: boolean, options?: { withCard?: boolean, trialDays?: number }) {
    const onAuthSuccess = (sessionData?: IAuthIndi | null) => {
      if (!sessionData) {
        console.error('Unable to redirect user to payment page: sessionData is empty')
        return
      }

      const { trialExpiryDate, expiryDate } = sessionData?.indi || {}

      if (
        dayjs().isBefore(expiryDate) &&
        !store.sessionData?.indi?.isTrial &&
        !store.sessionData?.indi?.isCardlessTrial
      ) return

      if (trialExpiryDate || expiryDate) {
        openSubscribeBanner()
      } else {
        if (options?.withCard) {
          openPaymentWithTariffsWindow(
            TariffPeriod.MONTH, 
            () => windowsStore.closeAll(),
            FreePeriod.TRIAL,
            options.trialDays
          );
        } else {
          openPaymentWithoutCard();
        }
      }
    }

    if (store.isAuth) {
      onAuthSuccess(store.sessionData)
    } else {
      openEditWindow({
        beforeAuth: true,
        onProfileUpdate: () => {
          openSmsAuthWindow((channel: string, sessionData?: any) => {
            onAuthSuccess(sessionData)
          })
        },
        direct
      })
    }
  }
}
