import dayjs from "dayjs";
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'src/utils';
import styled from 'styled-components';
import { history } from '../../../../MainRouter';
import IndiPrognosisBanner from '../../../../components/banners/IndiPrognosisBanner';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import ContextMenu from '../../../../components/ui/ContextMenu';
import DropDown from '../../../../components/ui/ContextMenu/DropDown';
import { contextButtonPositionType } from '../../../../components/ui/ContextMenu/types';
import Swiper, { ISwipeTab } from '../../../../components/ui/Swiper/Swiper';
import { ROUTES } from '../../../../helpers/routes';
import { copyToClipboard } from '../../../../helpers/strings';
import { searchParams } from '../../../../helpers/url';
import { useBackWindow } from '../../../../hooks/router-hooks';
import { Button, getGMT, IEmotionsData, ITextBlock, show } from '../../../../libs';
import { signsIcons } from '../../../../store/Astrology';
import { default as store } from "../../../../store/Store";
import { windowsStore } from '../../../../store/Windows';
import { getPromocodeLS, setConfirmationStepLS, setProductTokenLS } from "../../../../store/localStorageHelpers";
import { WindowHeader } from '../../../windows/components/WindowHeader';
import ProductConfirmation from '../../components/Confirmation';
import Footer from "../../components/Footer";
import { EMOTIONS, WINDOW_ID_CONFIRMATION, WINDOW_ID_EMOTIONS, PRODUCTS_SECTION } from '../../helpers/constants';
import { getContextMenuState, getContextMenuStateButton } from '../../helpers/context';
import { FreeFourteenBanner } from "../components/FreeFourteenBanner";
import Card from "./Card";
import Intro from "./Intro";
import PersonCard from "./PersonCard";

// FIXME: duplicate?
export interface IIntro {
  title: string
  tabTitle: string
  textBlocks: ITextBlock[]
}

interface IEmotionsCalcData {
  name: string
  date: string
  time: string
  lat: string
  lon: string
  gmt?: string
  language: string
  paymentId: string
  place?: string
}

interface IData {
  input: IEmotionsCalcData
  emotions: IEmotionsData
  id: number
}

interface IPersonData {
  name: string
  place: string
  dateTime: string
  sign: any
}

interface IEmotionsProps {
  token?: number
  sharedToken?: string
}

export default observer(function Emotions(props: IEmotionsProps) {
  const pathname = `${ROUTES.PRODUCTS_EMOTIONS}/${props.token}`
  useBackWindow(pathname, WINDOW_ID_EMOTIONS);

  document.title = `Продукты • Контакт со своими эмоциями`;

  const [data, setData] = useState<IData | null>(null)
  const [personData, setPersonData] = useState<IPersonData | null>(null)
  const [contextMenuPosition, setContextMenuPosition] = useState<contextButtonPositionType>(null)
  const [updatedPrice, setUpdatedPrice] = useState(0);
  const [showBanner, setShowBanner] = useState(false)

  // const id = document.location.pathname.split('/')[2]
  const promocode = () => getPromocodeLS() ?? store.promocode.promoCode;
  const sp = searchParams()
  const subscriptionInfo = store.sessionData?.indi

  const onClose = () => {
    windowsStore.close(WINDOW_ID_EMOTIONS)
    document.title = `Продукты`;
    store.synastry.setActiveSection(PRODUCTS_SECTION.CATALOG)
    history.push({ pathname: '', search: sp.toString() })
  }

  const emotionsProduct = useMemo(() => {
    return store.products?.products?.find(p => p.code === EMOTIONS);
  }, [store.products.products]);

  const currentProductData = useMemo(() => {
    return store.products?.purchasedProducts?.find(p => p.id === props.token);
  }, [store.products.purchasedProducts]);

  let newMajorVersion = false;
  if (emotionsProduct?.version && currentProductData?.version) {
    const [MV] = emotionsProduct?.version.split('.');
    const [curMV] = currentProductData?.version.split('.');
    newMajorVersion = +MV > +curMV;
  }

  useEffect(() => {
    if (props.sharedToken) {
      store.emotions.getSharedEmotions(props.sharedToken)
        //@ts-ignore
        .then(setData)

      store.products.setIsSharedProduct('emotions')
    } else {
      props.token && store.emotions.getEmotions(props.token)
        //@ts-ignore
        .then(setData)
    }

    store.products.getProducts()
    store.products.getPurchasedProducts()

    return () => {
      windowsStore.setContextMenu(null)
      props.sharedToken && store.products.setIsSharedProduct(null)
      store.products.removeActiveProductToken()
    }
  }, [])

  useEffect(() => {
    if (newMajorVersion) {
      getPrice();
    }
  }, [newMajorVersion])

  useEffect(() => {
    if (data) {
      getPersonData()
      windowsStore.setContextMenu(emotionsContextMenu)
    }
  }, [data])

  useEffect(() => {
    if (!subscriptionInfo?.trialExpiryDate) {
      setShowBanner(true)
    }
  }, [subscriptionInfo])

  const getPrice = async () => {
    const productCode = 'EMOTIONS';
    const promo = promocode();

    const result = await store.payments.getPaymentConfig(productCode, promo, true, 'ru', undefined, undefined, store.emotions.getPersonData());
    if (result?.options.amount) {
      setUpdatedPrice(result?.options.amount);
    }
  }

  const saveLink = async (id: string) => {
    try {
      const token = await store.emotions.getShareToken(id)
      const link = `${window.location.protocol}//${window.location.host}/emotions/${token}/shared`

      if (isMobile) {
        window.navigator.share({
          title: 'Хронос',
          text: 'Контакт со своими эмоциями',
          url: link
        })
      } else {
        copyToClipboard(link);

        show({
          text: 'Ссылка скопирована',
          type: 'success'
        })
      }
    } catch {
      show({
        text: 'Не удалось скопировать ссылку',
        type: 'error'
      })
    }
  }

  const buyEmotions = () => {
    const emotionsProductToken = 2

    windowsStore.open(WINDOW_ID_CONFIRMATION, <ProductConfirmation product={EMOTIONS} />)
    store.payments.setToken(emotionsProductToken)
  }

  if (!data) return <></>

  const emotionsContextMenu = <ContextMenu groupsItems={getContextMenuState(() => saveLink(String(data.id)), onClose)} />

  const getTabs = (): ISwipeTab[] => {
    const tabs: ISwipeTab[] = []

    let key: keyof IEmotionsData
    for (key in data.emotions) {
      tabs.push({ key, title: data.emotions[key].tabTitle })
    }

    return tabs
  }

  const getCards = () => {
    const cards = []

    let key: keyof IEmotionsData
    for (key in data.emotions) {
      if (key !== 'intro') {
        const card: any = {}
        card.title = data.emotions[key].title
        card.actions = data.emotions[key].actions
        card.key = key
        cards.push(card)
      }
    }

    return cards
  }

  const getPersonData = async () => {
    const dateTime = dayjs(data.input.date + ' ' + data.input.time, 'DD.MM.YYYY HH:mm:ss').format('DD MMM YYYY, HH:mm')

    const getSignsData = [{ date: data.input.date, time: data.input.time, lon: +(data.input.lon), lat: +(data.input.lat) }]
    const result = await store.astrology.getSigns(getSignsData)
    const sign = signsIcons[result[0].birthSign]

    const personData: any = {}

    personData.name = data.input.name
    personData.place = data.input.place
    personData.dateTime = dateTime
    personData.sign = sign

    setPersonData(personData)
  }

  const handleBuyButtonClick = () => {
    if (!emotionsProduct?.id || !data.input) {
      return
    }

    store.payments.setToken(emotionsProduct.id)
    setProductTokenLS(emotionsProduct.id.toString())
    store.products.setProductData(emotionsProduct)
    store.promocode.resetPromocode()

    let step = 1

    if (data.input.date && data.input.time && data.input.name && data.input.lat && data.input.lon) {
      const dateTime = dayjs.utc(`${data.input.date.split('.').reverse().join('-')} ${data.input.time}`).toISOString()

      store.emotions.setPerson({
        name: data.input.name,
        dateTime,
        place: {
          lat: +data.input.lat,
          lon: +data.input.lon,
          name: `${data.input.lat} ${data.input.lon}`,
        },
        gmt: `${getGMT(dateTime, +data.input.lat, +data.input.lon)}`,
      })

      step = 2
    }

    setConfirmationStepLS(`${step}`)
    sp.set('update', 'true')
    sp.set('purchasedProductId', `${currentProductData?.id ? currentProductData.id : ''}`)
    windowsStore.open(WINDOW_ID_CONFIRMATION, <ProductConfirmation product={EMOTIONS} />)
  }


  return (
    <WindowContainer className={'window-container'}>
      <WindowHeader title='Эмоции' onBack={onClose} />
      <Content>
        <Swiper tabs={getTabs()} />

        <PersonCard data={personData} />

        {
          newMajorVersion &&
          <UpdateBlock>
            <h3>Доступно обновление!</h3>
            <p>Мы выпустили крупное обновление для документа и специально для вас подготовили скидку</p>
            <Button onClick={handleBuyButtonClick}>
              <span>
                Обновить за&nbsp;
                {updatedPrice && <OldPrice>${emotionsProduct?.price?.value}₽ </OldPrice>}
                {updatedPrice ? updatedPrice : emotionsProduct?.price?.value}₽
              </span>
            </Button>
          </UpdateBlock>
        }

        <Intro data={data.emotions.intro} />

        {
          getCards().map(card => {
            return <Card key={`emotions_card_${card.key}`} data={card} />
          })
        }

        {showBanner && <FreeFourteenBanner product='emotions' />}

        {!props.sharedToken &&
          <Footer
            toPayments={onClose}
            setContextMenuPosition={(value: { top: number, left: number }) => setContextMenuPosition(value)}
          />
        }

        {contextMenuPosition &&
          <DropDown
            contextButtonPosition={contextMenuPosition}
            groupsItems={getContextMenuStateButton(() => saveLink(String(data.id)))}
            onClose={() => setContextMenuPosition(null)}
          />
        }
      </Content>
    </WindowContainer>
  );
})

const Content = styled(MainPadding)`
  /* margin-top: 3rem; TODO: Xo13 */

  @media print {
    margin-top: 3rem;
  }
`

const UpdateBlock = styled.div`
  background-color: var(--button-light-bg);
  padding: 1.3125rem 1rem 1rem;
  border-radius: 0.5rem;

  & > h3 {
    margin: 0 0 0.5rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: black;
  }

  & > p {
    margin: 0 0 0.75rem;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    color: var(--text-primary);
  }

  & > button {
    width: 100%;
  }

  margin-bottom: 1rem;
`

const OldPrice = styled.span`
  text-decoration: line-through;
`

const StyledIndiPrognosisBanner = styled(IndiPrognosisBanner)`
  margin-bottom: 1.125rem;
`;
