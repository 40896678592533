import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import store from 'src/store/Store'
import styled from 'styled-components'
import { getArticleWord } from '../../../helpers/words'
import { getBlogLs } from '../helpers/blog'


const words = ['статья', 'статьи', 'статей']

export const BlogNew = observer(() => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const blogs = store.products.blogs
  const showBlogNew = store.blog.showBlogNew

  useEffect(() => {
    store.products.getArticlesGroups()
  }, []);

  useEffect(() => {
    store.ui.setCounterBlogNewRef(containerRef)
  }, [blogs]);

  const blog = getBlogLs();

  const count = blogs.reduce((sum, group) => {
		const filteredArticles = group.articles.filter(article => !blog.isRead[article.id])
		return sum + filteredArticles.length
	}, 0)

  if (!showBlogNew || count === 0) return null;

  const newCountWord = (count % 10) === 1 ? 'новая' : 'новых';

  return (
    <Container ref={containerRef} className={'blog_new'}>
      {count} {newCountWord} {getArticleWord(words, count)}
    </Container>
  )
})

const Container = styled.div`
  color: var(--color-white);
  padding: 0.1rem 0.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  background: var(--color-black);
  transition: opacity 0.2s;
  z-index: var(--z-index-shutter);
`
