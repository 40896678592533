import { observer } from 'mobx-react-lite';
import { OkSmall } from 'src/assets/icons/system/system-icons';
import { GenderItem } from 'src/modules/edit/Edit';
import styled from 'styled-components';
import { IPlace } from '../../libs';
import { Switcher } from '../ui/Switcher';
import { TextInput } from '../ui/TextInput';


interface PrognosesInputsProps {
  name: string
  nameError: string
  namePlaceholder?: string
  nameOnChange: (value: string) => void
  nameOnFocus?: () => void
  date: string
  dateOnChange: (value: string) => void
  dateOnInvalid: () => void
  dateError: string
  dateOnFocus?: () => void
  minDate?: string
  maxDate?: string
  originalDate?: string
  time: string
  timeOnChange: (value: string) => void
  timeOnInvalid: () => void
  timeError: string
  timeNotion?: string
  timeOnFocus?: () => void
  place: IPlace
  placeOnChange: (value: IPlace) => void
  placeError: string
  placePlaceholder?: string
  placeOnFocus?: () => void
  email?: string
  emailOnChange?: (value: string) => void
  emailError?: string
  emailOnFocus?: () => void
  gender?: GenderItem
  genderOnChange?: (value: GenderItem) => void
  genderItems?: GenderItem[]
  genderOnFocus?: () => void
}

export const PrognosesInputs = observer((props: PrognosesInputsProps) => {
  const {
    name,
    nameOnChange,
    nameError,
    namePlaceholder,
    nameOnFocus,
    date,
    dateOnChange,
    dateOnInvalid,
    dateError,
    dateOnFocus,
    time,
    timeOnChange,
    timeOnInvalid,
    timeError,
    timeNotion,
    timeOnFocus,
    place,
    placeOnChange,
    placeError,
    placePlaceholder,
    placeOnFocus,
    minDate,
    maxDate,
    originalDate,
    email,
    emailOnChange,
    emailError,
    emailOnFocus,
    gender,
    genderOnChange,
    genderItems,
  } = props

  return <>
    <TextInput
      value={name}
      onChange={nameOnChange}
      title={'Имя'}
      error={nameError}
      onFocus={nameOnFocus}
      placeholder={namePlaceholder}
    />

    <TextInput
      value={place.name}
      place={place}
      onChange={placeOnChange}
      title={'Место рождения'}
      type={'place'}
      error={placeError}
      onFocus={placeOnFocus}
      placeholder={placePlaceholder}
    />

    <DateTimeContainer error={!!(timeError || dateError)}>
      <DateTime>
        <TextInput
          value={time}
          onChange={timeOnChange}
          title={'Время рождения'}
          notion={timeNotion}
          type={'time'}
          error={timeError}
          onInvalid={timeOnInvalid}
          onFocus={timeOnFocus}
        />

        <TextInput
          value={date}
          onChange={dateOnChange}
          title={'Дата рождения'}
          type={'date'}
          error={dateError}
          minDate={minDate}
          maxDate={maxDate}
          onInvalid={dateOnInvalid}
          notion={originalDate ? 'Дату можно изменить в пределах одного дня' : ''}
          onFocus={dateOnFocus}
        />
      </DateTime>

      {/* <div className='info'>Если не знаете время рождения, потом можно изменить</div> */}
    </DateTimeContainer>

    {
      gender && genderOnChange && genderItems && (
        <SwitcherContainer errorDateTime={!!(timeError || dateError)}>
          <Label>Ваш пол</Label>
          <Switcher
            items={genderItems}
            value={gender.value}
            onChange={genderOnChange}
            icon={<OkSmall />}
          />
        </SwitcherContainer>
      )
    }

    {
      typeof email !== 'undefined' && emailOnChange && (
        <EmailTextInput
          title={'Ваша почта'}
          value={email}
          onChange={emailOnChange}
          placeholder={'example@domain.ru'}
          error={emailError}
          errorDateTime={!!(emailError || dateError)}
          onFocus={emailOnFocus}
        />
      )
    }
  </>
})

const DateTimeContainer = styled.div<{ error: boolean }>`
  position: relative;

  .info {
    position: absolute;
    bottom: ${p => p.error ? '-1.5rem' : '0.5rem'};
    font-size: 0.75rem;
    color: var('--text-secondary');
  }
`

const DateTime = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`

const EmailTextInput = styled(TextInput) <{ errorDateTime: boolean }>`
  margin-top: ${p => p.errorDateTime ? '1.5rem' : '1rem'};
`

const Label = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
`

const SwitcherContainer = styled.div<{ errorDateTime: boolean }>`
  margin-top: ${p => p.errorDateTime ? '3rem' : '0.5rem'};
`
