import dayjs from "dayjs";
import React from "react";
import { searchParams } from 'src/libs';
import styled, { keyframes } from "styled-components";
import { history } from '../../MainRouter';
import { StarIcon } from "../../assets/icons/trial/trial-icons";
import AvaImg1 from '../../assets/images/paywall/ava-1.png';
import AvaImg2 from '../../assets/images/paywall/ava-2.png';
import AvaImg3 from '../../assets/images/paywall/ava-3.png';
import AvaImg4 from '../../assets/images/paywall/ava-4.png';
import AvaImg5 from '../../assets/images/paywall/ava-5.png';
import AvaImg6 from '../../assets/images/paywall/ava-6.png';
import AvaImg7 from '../../assets/images/paywall/ava-7.png';
import LogoImg from '../../assets/images/paywall/logo.png';
import ScreenImg2 from '../../assets/images/paywall/screen-2.png';
import ScreenImg3 from '../../assets/images/paywall/screen-3.png';
import ScreenImg4 from '../../assets/images/paywall/screen-4.png';
import ScreenImg5 from '../../assets/images/paywall/screen-5.png';
import Stars5 from '../../assets/images/paywall/stars5.png';
import WomanImg from '../../assets/images/paywall/woman.png';
import { WindowContainer } from "../../components/layout/elements";
import { Button } from '../../components/ui/Button/Button';
import { ROUTES } from '../../helpers/routes';
import { gtmSend } from '../../helpers/url';
import { useElementOnScreen } from "../../hooks/dom-hooks";
import { useBackWindow } from '../../hooks/router-hooks';
import { Indi } from '../../libs';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { FreePeriod } from "../payment/helpers/tariffs";
import { openSmsAuthWindow } from '../registration/sms/SmsAuth';
import { openPaymentWindow } from "./Paywall";
import { TrialClose } from './components/Close/TrialClose';


export const WINDOW_ID_PAYLANDING: string = 'WINDOW_ID_PAYLANDING';

interface PaywallProps {
  freePeriod?: FreePeriod
}

export const PayLanding = (props: PaywallProps) => {

  useBackWindow(ROUTES.PAYLANDING, WINDOW_ID_PAYLANDING);

  const sp = searchParams();
  const period = Number(sp.get('d'));
  const trialPeriod = [3,14].includes(period) ? period : 7;

  const tariffs = store.events.tariffs;
  const userId = store.sessionData?.id || '';
  const isAuth = store.isAuth;
  const info = store.sessionData?.indi;

  const [ tariff, setTariff ] = React.useState<Indi.indiGetTariffs.Tariff>();

  const [showBottomButton, setShowBottomButton] = React.useState<boolean>(false);

  const [shownBlocks, setShownBlocks] = React.useState<number[]>([]);

  React.useEffect(() => {
    const usedTrial = (info?.trialExpiryDate && dayjs().isAfter(info?.trialExpiryDate)) || info?.cardLastFour

		if (usedTrial) {
			windowsStore.close(WINDOW_ID_PAYLANDING);
			window.location.href.includes('trial') && history.push('/')
		}

    if (usedTrial && tariffs.length > 0) {
      const tariff6 = tariffs.find(item => item.period === 6)!;
      openPaymentWindow(tariff6, {});
    }
  }, [info, tariffs])

  const [startButtonRef, isStartButtonVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.8
  });

  React.useEffect(() => {
    setShowBottomButton(!isStartButtonVisible);
  }, [isStartButtonVisible]);


  const [screen2Ref, isScreen2Visible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.8
  });

  const [screen3Ref, isScreen3Visible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.8
  });

  const [screen4Ref, isScreen4Visible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.8
  });

  const [screen5Ref, isScreen5Visible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.8
  });

  const [screen6Ref, isScreen6Visible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.8
  });

  React.useEffect(() => {

    if (isScreen2Visible && !shownBlocks.includes(2)) {
      gtmSend({ 'event': 'indi_chronos-plus-landing_2block-shown', 'user_id': userId })
      setShownBlocks((state) => { state.push(2); return state; })
      // console.log(`visible block ${2}`)
    }
    if (isScreen3Visible && !shownBlocks.includes(3)) {
      gtmSend({ 'event': 'indi_chronos-plus-landing_3block-shown', 'user_id': userId })
      setShownBlocks((state) => { state.push(3); return state; })
      // console.log(`visible block ${3}`)
    }
    if (isScreen4Visible && !shownBlocks.includes(4)) {
      gtmSend({ 'event': 'indi_chronos-plus-landing_4block-shown', 'user_id': userId })
      setShownBlocks((state) => { state.push(4); return state; })
      // console.log(`visible block ${4}`)
    }
    if (isScreen5Visible && !shownBlocks.includes(5)) {
      gtmSend({ 'event': 'indi_chronos-plus-landing_5block-shown', 'user_id': userId })
      setShownBlocks((state) => { state.push(5); return state; })
      // console.log(`visible block ${5}`)
    }
    if (isScreen6Visible && !shownBlocks.includes(6)) {
      gtmSend({ 'event': 'indi_chronos-plus-landing_6block-shown', 'user_id': userId })
      setShownBlocks((state) => { state.push(6); return state; })
      // console.log(`visible block ${6}`)
    }
  }, [
      isScreen2Visible,
      isScreen3Visible,
      isScreen4Visible,
      isScreen5Visible,
      isScreen6Visible
    ]);


  React.useEffect(() => {
    if (store.visitLanding) return;

    gtmSend({ 'event': 'indi_chronos-plus-landing_visit_all', 'user_id': userId });
    gtmSend({ 'event': `indi_chronos-plus-landing_visit_${!isAuth ? 'non-': ''}auth` });

    store.visitLanding = true;

  }, [isAuth]);

  React.useEffect(() => {
    setTariff(tariffs.find(item => item.period === 1) || undefined)
  }, [tariffs])

  const onClose = () => {
    windowsStore.close(WINDOW_ID_PAYLANDING);
  }

  const onAuthSuccessOpenPayment = () => {
    onClose();
    openPaymentWindow(tariff!, {
      freePeriod: props.freePeriod ? props.freePeriod : FreePeriod.TRIAL,
      trialPeriod
    });
  }

  const onStart = () => {
    if (isAuth) {
      openPaymentWindow(tariff!, {
        freePeriod: props.freePeriod ? props.freePeriod : FreePeriod.TRIAL,
        trialPeriod
      });
    } else {
      openSmsAuthWindow(onAuthSuccessOpenPayment);
    }

    gtmSend({ 'event': 'indi_chronos-plus-landing_click', 'user_id': userId })
  }

  const signIn = () => {
		openSmsAuthWindow(onAuthSuccessOpenPayment)
    gtmSend({'event': 'ind_prognosis_trial_flow_signin', 'user_id': userId});
  }


  if (!tariff) return null;

  return (
    <>
      <PayLandingContainer>
        <header>
          {!isAuth && <button onClick={signIn}>Войти</button>}
          <LandingClose onClick={onClose} />
        </header>

        <ScreenBlock id="block-1">
          <div className="logo">
            <img src={LogoImg} alt="" />
          </div>

          <div className="title">Персональный прогноз на каждый день</div>
          <div className="subtitle">построенный по данным вашего рождения</div>

          <div className="rating">
            <div className="badge">4.9 <StarIcon /></div>
            <div className="badge">10 000+ пользователей</div>
          </div>

          <div className="picture woman">
            {/* @ts-ignore */}
            <div className="control" ref={startButtonRef}>
              <Button className="button" color="green" onClick={onStart} small>Начать {trialPeriod} дней бесплатно</Button>
              <div className="caption">Отменить можно в любой момент</div>
            </div>
          </div>

        </ScreenBlock>

        <ScreenBlock id="block-2">
          <div className="title">Начните лучше понимать себя</div>
          {/* @ts-ignore */}
          <div className="picture screen-2" ref={screen2Ref}></div>
        </ScreenBlock>

        <ScreenBlock id="block-3">
          <div className="title">Улучшайте качество жизни, применяйте персональные рекомендации</div>
          {/* @ts-ignore */}
          <div className="picture screen-3" ref={screen3Ref}></div>
        </ScreenBlock>

        <ScreenBlock id="block-4">
          <div className="title">Будьте в курсе будущих событий, влияющих на вас</div>
          {/* @ts-ignore */}
          <div className="picture screen-4" ref={screen4Ref}></div>
        </ScreenBlock>

        <ScreenBlock id="block-5">
          <div className="title">Планируйте свои дела по всем сферам жизни</div>
          {/* @ts-ignore */}
          <div className="picture screen-5" ref={screen5Ref}></div>
        </ScreenBlock>

        <ScreenBlock id="block-6">
          <div className="title">Более 10 000 пользователей оценили нас на 4.7 звезд </div>
          <div className="comments">
            {/* @ts-ignore */}
            <div className="comment" ref={screen6Ref}>
              <div className="stars"></div>
              <div className="text">
                Я была поражена точностью прогнозов Chronos Plus. Кажется, будто они знают меня лучше, чем я сама!
              </div>
              <div className="author">
                <div className="ava">
                  <img src={AvaImg1} alt="avatar" />
                </div>
                <div className="name">Арина</div>
              </div>
            </div>

            <div className="comment">
              <div className="stars"></div>
              <div className="text">
                Понравилось, что прогноз разделен по сферам. Больше всего мне откликнулся раздел про поездки, потому что я как раз готовилась к поездкам и планировала это. К каждой сфере дается и описание, и рекомендации: сделать то и то.
                <br /> <br />
                Прогноз мне помогает планировать свои дела, поездки и принима ть решения, касающиеся финансовых вопросов. Хочу предпринять действия сейчас, которые будут полезны в будущем.
              </div>
              <div className="author">
                <div className="ava">
                  <img src={AvaImg2} alt="avatar" />
                </div>
                <div className="name">Полина</div>
              </div>
            </div>
            <div className="comment">
              <div className="stars"></div>
              <div className="text">
                Обожаю Хронос! Он дает мне так много информации о моем знаке зодиака и помогает мне понять, что происходит со мной в жизни.
              </div>
              <div className="author">
                <div className="ava">
                  <img src={AvaImg3} alt="avatar" />
                </div>
                <div className="name">Елизавета</div>
              </div>
            </div>
            <div className="comment">
              <div className="stars"></div>
              <div className="text">
                Как только прочитала, удивилась, что очень совпадает с тем, что в голове. В сферах тоже увидела совпадения, и по отношениям совпало. Нравится, что это как карманный маленький навигатор, который можно посмотреть в любой момент. Удостовериться, что иду правильно, мыслю правильно.
              </div>
              <div className="author">
                <div className="ava">
                  <img src={AvaImg4} alt="avatar" />
                </div>
                <div className="name">Анастасия</div>
              </div>
            </div>
            <div className="comment">
              <div className="stars"></div>
              <div className="text">
                Я очень доволен этим приложением! Оно дает мне точные прогнозы, которые помогают мне планировать свой день
              </div>
              <div className="author">
                <div className="ava">
                  <img src={AvaImg5} alt="avatar" />
                </div>
                <div className="name">Павел</div>
              </div>
            </div>
            <div className="comment">
              <div className="stars"></div>
              <div className="text">
                Интересно, четко, структурировано. Понравилось, что компенсация есть. Все понравилось. Помогает планировать дни и понимать, чего ожидать в предстоящем месяце.
              </div>
              <div className="author">
                <div className="ava">
                  <img src={AvaImg6} alt="avatar" />
                </div>
                <div className="name">Елена</div>
              </div>
            </div>
            <div className="comment">
              <div className="stars"></div>
              <div className="text">
                Я использовала множество приложений, но это - мое любимое. Оно очень простое в использовании и имеет множество интересных функций
              </div>
              <div className="author">
                <div className="ava">
                  <img src={AvaImg7} alt="avatar" />
                </div>
                <div className="name">Елена</div>
              </div>
            </div>
          </div>
        </ScreenBlock>

      </PayLandingContainer>

      { showBottomButton &&
        <BottomButton>
          <Button className="button" color="green" onClick={onStart}>Начать {trialPeriod} дней бесплатно</Button>
          <div className="caption">Отменить можно в любой момент</div>
        </BottomButton>
      }
    </>
  );
};

const slideFromBottom = keyframes`
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
`

const PayLandingContainer = styled(WindowContainer)`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  header {
    display: flex;
    flex-direction: row;
    position: -webkit-sticky;
	  position: sticky;
    justify-content: space-between;
    top: 0px;
    height: 2.375rem;
    padding: 0.25rem 0;
    z-index: 2;

    button {
      all: unset;
      position: relative;
      top: 0.7rem;
      padding: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1;
      background-color: #fff;
      border-radius: 4.125rem;
      cursor: pointer;
    }
  }

  & .control {
      display: flex;
      position: fixed;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 2rem);
      padding: 0.75rem 0;
      bottom: 0;
      background-color: #fff;

      animation: ${slideFromBottom} 0.5s ease;

      & .button {
        width: 90%;
      }

      & .caption {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: var(--text-secondary);
      }
    }
`

const ScreenBlock = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  & + & {
    margin-top: 3rem;
  }

  & .logo {
    position: relative;
    margin-top: 1.375rem;
    margin-left: auto;
    margin-right: auto;
  }

  & .title {
    margin-top: 0.75rem;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }

  & .subtitle {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: -2.5%;
    color: var(--text-secondary);
  }

  & .rating {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;

    & .badge {
      display: flex;
      gap: 0.25rem;
      padding: 0.25rem 0.75rem;
      border: 1px solid var(--element-border-10);
      border-radius: 2.75rem;
      font-size: 1rem;
      font-weight: 400;

      & svg {
        color: var(--color-ocher);
        width: 1.375rem;
        height: 1.375rem;
      }
    }
  }

  & .picture {
    position: relative;
    margin-top: 1rem;
    width: 100%;

    background-size: cover;
    background-position: center center;

    &.woman {
      height: 30.25rem;
      background-image: url(${WomanImg});
    }

    &:not(.woman) {
      height: 23.938rem;
      margin-top: 1.25rem;
    }

    & .control {
      position: absolute;
      width: 100%;
      padding: 0;
      bottom: 1.35rem;
      background-color: transparent;
      animation: none;
    }

    &.screen-2 {
      background-image: url(${ScreenImg2});
    }
    &.screen-3 {
      background-image: url(${ScreenImg3});
    }
    &.screen-4 {
      background-image: url(${ScreenImg4});
    }
    &.screen-5 {
      background-image: url(${ScreenImg5});
    }
  }

  & .comments {
    display: block;
    margin-top: 1.25rem;
    margin-bottom: 7.5rem;
  }

  & .comment {
    position: relative;
    padding: 1.5rem 1rem;
    border: 1px solid rgba(108, 145, 241, 0.15);
    border-radius: 1rem;

    & + .comment {
      margin-top: 0.5rem;
    }

    & .stars {
      position: relative;
      width: 8rem;
      height: 1.5rem;
      background-size: cover;
      background-position: center center;
      background-image: url(${Stars5});
    }

    & .text {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 500;
      color: var(--text-secondary);
    }

    & .author {
      display: flex;
      position: relative;
      margin-top: 2rem;
      gap: 0.5rem;


      & .name {
        font-size: 1rem;
        font-weight: 400;
        color: var(--text-third);
      }
    }
  }
`;

const LandingClose = styled(TrialClose)`
  position: relative;
  right: 0;
  top: 0.7rem;
  margin-left: auto;
`;

const BottomButton = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  bottom: 0;
  background-color: #fff;

  animation: ${slideFromBottom} 0.5s ease;

  & .button {
    width: 90%;
  }

  & .caption {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--text-secondary);
  }
`
