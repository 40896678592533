import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { HeaderAvatar } from './main-page-header-avatar';
import store from '../../../store/Store';
import { gtmSend } from '../../../helpers/url';
import { PROFILE_NAME_PROFILE, TUserState } from '../helpers/states';
import { FlexContainer } from '../../../components/layout/elements';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Calendar } from '../../../components/calendar/Сalendar';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '../../../assets/icons/calendar';
import { ResetIcon } from '../../../assets/icons/system/system-icons';
import { capitalize } from '../../../helpers/strings';
import { WeekdayIndicator } from '../../../components/WeekdayIndicator';
import { blockScroll } from '../../../helpers/ui';
import { MainPageHeaderLoginButton } from './main-page-header-login-button';
import { Day, getMonthData } from '../../my-day/helpers/calendar';
import { openProfile } from '../../profile/Profile';
import { Sections } from '../../../store/Shutter';
import { openSubscribeBanner } from 'src/modules/subscription/SubscribeBanner/SubscribeBanner';

export const getCalendarTitle = (date: dayjs.Dayjs, month: number, year: number, format: string = 'DD MMMM') => {
	if (dayjs(date).month() !== month || dayjs(date).year() !== year) {
		return dayjs().set('year', year).set('month', month).format('MMMM, YYYY');
	}

  return dayjs(date).format(format)
}


interface HeaderProps {
  selected: dayjs.Dayjs,
  setSelected(value: dayjs.Dayjs): void
  scrolled: boolean
  state: TUserState
}

export const MainPageHeader = observer((props: HeaderProps) => {
  const {
    selected,
    setSelected,
    scrolled,
    state,
  } = props

  const [showCalendar, setShowCalendar] = useState(false)
  const [month, setMonth] = useState(dayjs(selected).month())
  const [year, setYear] = useState(dayjs(selected).year())

  const [sliderItems, setSliderItems] = useState<Day[][][]>([])
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const sliderContainerRef = useRef<HTMLDivElement>(null);
  const sliderItemRef = useRef<HTMLTableElement>(null);

  const date = capitalize(getCalendarTitle(selected, month, year, 'dd, DD MMMM'))
  const userId = store.sessionData?.id;
  const userName = store.profile.firstName ?? PROFILE_NAME_PROFILE
  const isToday = dayjs().startOf('day').isSame(selected.startOf('day'))
  const isAuth = store.isAuth
  const isTrialEnded = store.events.status === 'pause'
  const canUseCalendar = store.isAuth && (store.events.status === 'active' || isTrialEnded)
  const openProfileWindow = () => {
    if (isAuth) {
      openProfile();
      store.shutter.setActiveSection(Sections.PROFILE)
      gtmSend({ 'event': 'ind_prognosis_settings', 'user_id': userId ? userId : '' })
    }
  }

  const onClose = () => {
    setTimeout(() => {
      setShowCalendar(false)
    }, 500)
  }

  const toggleCalendar = () => {
    if (showCalendar) {
      calendarContainerRef.current!.style.transform = `translateY(-100%)`
      onClose()
    } else {
      setShowCalendar(true)
      calendarContainerRef.current!.style.transform = `translateY(100px)`
      gtmSend({ 'event': 'indi_main_open-calendar_click', 'user_id': userId ? userId : '' })
    }
  }

  const updateSliderItems = (year: number, month: number, selected: dayjs.Dayjs) => {
    const monthData = getMonthData(year, month, selected)

    const previousMonth = dayjs(selected).add(-1, 'month').month()
    const previousYear = previousMonth === 11 ? year - 1 : year
    const previousMonthData = getMonthData(previousYear, previousMonth, selected)

    const nextMonth = dayjs(selected).set('month', month).add(1, 'month').month()
    const nextYear = nextMonth === 0 ? year + 1 : year
    const nextMonthData = getMonthData(nextYear, nextMonth, selected)

    setSliderItems([previousMonthData, monthData, nextMonthData])
  }

  const setSelectedDate = () => {
    if (dayjs(selected).month() !== month || dayjs(selected).year() !== year) {
      setMonth(dayjs(selected).month())
      setYear(dayjs(selected).year())
    }
  }

  useEffect(() => {
    updateSliderItems(selected.year(), selected.month(), selected)
  }, [selected])

  useEffect(() => {
    blockScroll(showCalendar)
    setSelectedDate()
  }, [selected, showCalendar])

  const setSelectedHandler = (date: dayjs.Dayjs) => {
    if (isTrialEnded) return openSubscribeBanner()
    gtmSend({ 'event': 'indi_main_change-date-calendar_click', 'user_id': userId ? userId : '' })

    setSelected(date)

    const selectedMonth = date.month()

    if ((month === 0 && selectedMonth === 11) || (selectedMonth < month)) {
      toPreviousMonth(date)
    } else if ((month === 11 && selectedMonth === 0) || (selectedMonth > month)) {
      toNextMonth(date)
    }

    setTimeout(() => {
      toggleCalendar()
    }, 300)
  }

  const moveSliderItems = useCallback((next: boolean, year: number, month: number, selected: dayjs.Dayjs) => {
    if (sliderContainerRef.current) {
      sliderContainerRef.current!.querySelector('div')!.style.transition = `transform 0.5s`;
      sliderContainerRef.current!.querySelector('div')!.style.transform = `translateX(${next ? '-' : ''}${sliderItemRef.current!.offsetWidth}px)`;

      setTimeout(() => {
        updateSliderItems(year, month, selected)
        sliderContainerRef.current!.querySelector('div')!.style.transition = `transform 0s`;
        sliderContainerRef.current!.querySelector('div')!.style.transform = `translateX(0px)`;
      }, 250)
    }
  }, [sliderContainerRef])

  const toPreviousMonth = useCallback((_selected?: dayjs.Dayjs) => {
    let _year = year
    let _month = month

    if (_month === 0) {
      _year = _year - 1
      _month = 11
    } else {
      _month = _month - 1
    }

    setYear(_year)
    setMonth(_month)

    moveSliderItems(false, _year, _month, _selected ?? selected)
  }, [year, month, selected, moveSliderItems])

  const toNextMonth = useCallback((_selected?: dayjs.Dayjs) => {
    let _year = year
    let _month = month

    if (_month === 11) {
      _year = _year + 1
      _month = 0
    } else {
      _month = _month + 1
    }

    setYear(_year)
    setMonth(_month)

    moveSliderItems(true, _year, _month, _selected ?? selected)
  }, [year, month, selected, moveSliderItems])

  const setTodayDate = () => {
    setSelected(dayjs().startOf('day'));
    setMonth(dayjs().startOf('day').month())
    setYear(dayjs().startOf('day').year())
  }

  return (
    <Container>
      <HeaderContainer
        blur={scrolled || showCalendar}
        showCalendar={showCalendar}>
        <StyledHeaderAvatar
          avatarUrl={''}
          userId={userId}
          name={userName}
          onClick={openProfileWindow} />

        <Center>
          {
            showCalendar
              ? <div className='months-switcher'>
                  <IconContainer><ChevronLeftIcon onClick={() => toPreviousMonth()} /></IconContainer>
                  <Date>{date}</Date>
                  <IconContainer><ChevronRightIcon onClick={() => toNextMonth()} /></IconContainer>
                </div>
              : <Date>{date}</Date>
          }

          {canUseCalendar && !showCalendar && <WeekdayIndicator date={selected} />}
        </Center>


        {
          !isToday &&
          <ResetIconContainer onClick={setTodayDate} showCalendar={showCalendar}>
            <ResetIcon />
          </ResetIconContainer>
        }

        <Right>
          {
            isAuth
              ? canUseCalendar && <IconContainer onClick={toggleCalendar} showCalendar={showCalendar}>
                  <CalendarIcon />
                </IconContainer>
              : <MainPageHeaderLoginButton state={state} />
          }
        </Right>
      </HeaderContainer>

      <StyledCalendar
        showCalendar={showCalendar}
        sliderItems={sliderItems}
        calendarContainerRef={calendarContainerRef}
        sliderContainerRef={sliderContainerRef}
        sliderItemRef={sliderItemRef}
        setSelected={setSelectedHandler}
        toPreviousMoth={toPreviousMonth}
        toNextMoth={toNextMonth}
        onClose={onClose}
      />

      {
				showCalendar && <Overlay onClick={toggleCalendar}/>
			}
    </Container>
  )
})

const Container = styled.div`
`

const HeaderContainer = styled(FlexContainer) <{ blur: boolean, showCalendar: boolean }>`
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  text-align: center;
  transition: background 0.6s ease;
  z-index: var(--z-index-header);
  backdrop-filter: blur(56px);

  ${p => p.blur && css`
    background: var(--bg-header);
  `};

  ${p => p.showCalendar && css`
    background: var(--bg-header-light);
  `};
`

const Center = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .months-switcher {
    display: flex;
    align-items: center;
  }
`

const StyledHeaderAvatar = styled(HeaderAvatar)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 12;
`

const Date = styled.div`
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.75rem;
`

const IconContainer = styled.div<{ showCalendar?: boolean }>`
  padding: 0.3rem;
  border-radius: 0.5rem;

  ${p => p.showCalendar && css`
    background: rgba(0, 0, 0, 0.08);`
  }

  &:active {
    background: rgba(0, 0, 0, 0.08);
  }
`

const Right = styled(IconContainer)`
  position: absolute;
  top: 0.7rem;
  right: 1rem;
`

const ResetIconContainer = styled(IconContainer)`
  position: absolute;
  top: 1rem;
  right: 3.5rem;
`

const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: -2.25rem;
  left: 0;
  width: 100%;
  z-index: var(--z-index-calendar);
  background: var(--bg-header-light);
`

const Overlay = styled.div`
	position: fixed;
  width: 100%;
  height: 100%;
  background: var(--overlay-bg-37);
  top: 0;
  bottom: 0;
  left: 0;
	z-index: 1;
`
