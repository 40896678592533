import styled from "styled-components"

interface StubProps {
  type?: string
}

export const Stub = (props: StubProps) => {
  const {
    type,
  } = props

  const getTitle = () => {
    if (type === 'zones') {
      return 'Нет выраженного напряжения. Прислушивайтесь к своему самочувствию и своевременно обращайтесь к специалистам'
    }
    return 'Нет признаков напряжения или ограничений'
  }

  return (
    <Container>
      {getTitle()}
    </Container>
  )
}

const Container = styled.div`
  padding: 0.75rem;
  border-radius: 12px;
  background: rgba(246, 244, 242, 1);
  margin-bottom: 0.25rem;
`
