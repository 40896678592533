import { observer } from "mobx-react-lite"
import { RefObject, useEffect, useState } from "react"
import { ArrowRight } from "src/assets/icons/system/system-icons"
import styled from "styled-components"


interface ToTopButtonProps {
  className?: string
  containerRef: RefObject<HTMLDivElement>
}

export const ToTopButton = observer((props: ToTopButtonProps) => {
  const {
    className = '',
    containerRef,
  } = props

  const [show, setShow] = useState(false)

  useEffect(() => {
    const checkPage = () => {
      const top = containerRef.current?.scrollTop ?? 0
      const screenHeight = window.screen.height

      if (top > screenHeight * 2) {
        setShow(true)
      } else {
        show && setShow(false)
      }
    }

    containerRef.current?.addEventListener('scroll', checkPage)

    return () => {
      containerRef.current?.removeEventListener('scroll', checkPage)
    }
  }, [containerRef, show])

  const onClick = () => {
    if (containerRef.current) {
      containerRef.current.style.overflowY = 'hidden'
      containerRef.current.scrollTo(0, 0)
      containerRef.current.style.overflowY = 'auto'
    }
  }

  return (
    <Container className={className} onClick={onClick} show={show}>
      <ArrowUp />
    </Container>
  )
})

const Container = styled.div<{ show: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 2rem;
  right: 1rem;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--text-primary);

  pointer-events: ${p => p.show ? 'auto' : 'none'};
  opacity: ${p => p.show ? 1 : 0};
  transition: opacity 600ms;
`

const ArrowUp = styled(ArrowRight)`
  color: var(--color-white);
  transform: rotate(-90deg);
`
