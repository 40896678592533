import dayjs from 'dayjs';
import { IInfoEx, IServiceDataEx, SessionFormat } from '../../../libs';

export interface IServiceGroup {
  type: IInfoEx;
  services: IServiceDataEx[];
}

export const groupingServices = (services: Record<number, IServiceDataEx>, categories: Record<number, IInfoEx>, format: SessionFormat | null = null) => {
  const result: IServiceGroup[] = [];

  Object.keys(categories).forEach((categoryKey: string) => {
    const groupServices: IServiceDataEx[] = [];
    Object.keys(services).forEach((serviceKey: string) => {
      const service = services[+serviceKey];
      //@ts-ignore
      if (service.visible === 'all' && +service.category === +categories[+categoryKey].id) {
        if (format !== null) {
          if (
            service.format === format
            || service.format === SessionFormat.Any
          ) groupServices.push(service);
        } else {
          groupServices.push(service);
        }
      }
    });
    groupServices.length && result.push({
      type: categories[+categoryKey],
      services: groupServices
    });
  });

  return result;
};

export const getWord = (count: number): string => {
  if (count < 5) {
    return `${count === 1 ? 'астролог' : count === 0 ? 'астрологов' : 'астролога'}`;
  }
  if (count >= 5 && count <= 20) {
    return `астрологов`;
  }
  if (count > 20) {
    return `${count % 10 === 1 ? 'астролог' : count % 10 >= 5 ? 'астрологов' : 'астролога'}`;
  }
  return '';
};

export const declOfNum = (number: number, words: string[]): string => {
  return words[
    (number % 100 > 4 && number % 100 < 20)
      ? 2
      : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]
  ];
}

export const getDateText = (dateTime: string) => {
  const isTomorrow = dayjs(dateTime).isTomorrow();
  const isToday = dayjs(dateTime).isToday();
  return `${isTomorrow ? 'завтра' : ''} ${isToday ? 'сегодня' : ''} ${!isTomorrow && !isToday ? '' : 'в'} ${dayjs(dateTime).format(isTomorrow || isToday ? 'HH:mm' : 'DD MMM HH:mm')}`;
};

export const getFormatText = (format: SessionFormat) => {
  switch (format) {
    case SessionFormat.Online: return 'Устный';
    case SessionFormat.Offline: return 'Письменный';
    default: return 'Устный';
  }
};

export const getAge = (dateTime: string) => {
  return dayjs().diff(dateTime, 'year');
};

export const getExperience = (practiceStart: string) => {
  return dayjs().diff(dayjs(practiceStart), 'years');
};

export function getNameForButton(activeService: number | null, price?: number | null, discount?: number) {
  if(activeService === null) return 'Выбрать услугу';

  const price_ = price || 0;
  const discount_ = discount || 0;

  if(discount_ === 0) return `Записаться на сеанс за ${price_} руб.`;
  else return `Записаться на сеанс за ${Math.round(price_ * (1 - discount_))} ₽ <strike>${price_}</strike> ₽`
}

export const weekDaysAbbr = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

export function getDateWithNameFromTime(time: string) {
  const month = dayjs(time).format('MMMM')
  return `
    ${dayjs(time).isTomorrow() ? 'Завтра, ' : ''}
    ${weekDaysAbbr[dayjs(time).weekday()]},
    ${dayjs(time).format('DD')},
    ${month.charAt(0).toUpperCase() + month.slice(1)}
    `;
}

export const isHaveTimeInSchedule = (schedule: string[][], time: string): boolean => {
  return schedule.some(slot => slot.some(s => s === time));
};

export const getSpecialistText = (sessionFormat: SessionFormat) => {
  return sessionFormat === SessionFormat.Offline
  ? `Вы общаетесь со специалистом через чат. От 5 дней для записи на консультацию`
  : `Консультации проходят онлайн. Специалист начнет готовиться к вашей консультации сразу после оплаты.
  На подготовку уходит до 2 дней`
}
