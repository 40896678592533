import styled from "styled-components"
import { ReactComponent as BackSvg } from '../assets/back.svg';
import React from "react";
import { FontSizes } from "src/helpers/ui";

export default function Cell({
    icon,
    image,
    title,
    onClick,
    indicatorIcon,
    fontSize = FontSizes.SECONDARY,
}: {
    icon?: string,
    image?: React.ReactNode,
    title: string,
    onClick: () => void
    indicatorIcon?: React.ReactNode,
    fontSize?: number
}) {
    return <Container onClick={onClick} className={`cell_${title}`}>
        <CellHeader>
            <CellEmoji>
                {icon}
                {image}
            </CellEmoji>
            <StyledBackSvg />
        </CellHeader>
        <CellFooter>
          <CellTitle fontSize={fontSize}>{title}</CellTitle>
          <div>{indicatorIcon}</div>
        </CellFooter>
    </Container>
}

const Container = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 4px 14px 0px rgba(129, 136, 157, 0.10);
    border-radius: 24px;
    padding: 1rem;
    cursor: pointer;
    word-wrap: break-word;
    width: calc((100% - 1.1rem) / 2);
`

const CellHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
`

const StyledBackSvg = styled(BackSvg)`
    transform: rotate(180deg);
`

const CellTitle = styled.div<{fontSize: number}>`
    font-size: ${p => `${p.fontSize}rem`};
    line-height: 1.375rem;
    font-weight: 500;
    max-width: 95%;
`

const CellEmoji = styled.div`
    font-size: 1.75rem;
`

const CellFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  hyphens: auto;
  word-wrap: normal;
  overflow-wrap: break-word;
  hyphenate-limit-chars: 10 3 2;
`
