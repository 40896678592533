import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import IconRewind from '../assets/icons/blog/audioControl/rewind10.svg';
import IconPlay from '../assets/icons/blog/audioControl/hugePlay.svg';
import IconForward from '../assets/icons/blog/audioControl/forward10.svg';
import IconPause from '../assets/icons/blog/audioControl/pause.svg';
import IconRestart from '../assets/icons/blog/audioControl/restart.svg';
import { isMobile } from 'src/utils';

interface AudioArticlePlayerProps {
  audio: string;
}

export const AudioArticlePlayer: React.FC<AudioArticlePlayerProps> = ({
  audio,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  
  const trackRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    if(audioRef.current) {

      const onTimeUpdate = () => {
        setCurrentTime(audioRef.current?.currentTime || 0);
      }
      const onEnded = () => {
        setIsFinished(true);
      }
      const onPaused = () => {
        setIsPlaying(false);
      }
      const onPlay = () => {
        setIsPlaying(true);
        setIsFinished(false);
      }
    
      audioRef.current.addEventListener('timeupdate', onTimeUpdate);
      audioRef.current.addEventListener('ended', onEnded);
      audioRef.current.addEventListener('play', onPlay);
      audioRef.current.addEventListener('pause', onPaused);

      setCurrentTime(0);

      return () => {
        if(audioRef.current) {
          audioRef.current.removeEventListener('timeupdate', onTimeUpdate);
          audioRef.current.removeEventListener('ended', onEnded);
          audioRef.current.removeEventListener('play', onPlay);
          audioRef.current.removeEventListener('pause', onPaused);
        }
      }
    }
    
  }, [audioRef.current])

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10;
    }
  };

  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10;
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  };
  
  const handleTrackClick = (e: React.MouseEvent) => {
    if(audioRef.current) {
      const element = e.currentTarget as HTMLDivElement;
      const position = e.pageX - element.getBoundingClientRect().left;
      const procent = position / element.getBoundingClientRect().width;
      audioRef.current.currentTime =  (audioRef.current.duration || 0) * procent;
    }
  }

  const convertTime = (time: number) => {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = Math.floor(time - (+minutes * 60)).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }

  const touchStart = (e: React.TouchEvent) => {
    audioRef.current?.pause();
    document.addEventListener('touchmove', touchMove);
    document.addEventListener('touchend', touchEnd);
  }

  const mouseStart = (e: React.MouseEvent) => {
    audioRef.current?.pause();
    document.addEventListener('mousemove', mouseMove);
    document.addEventListener('mouseup', mouseEnd);
  }

  const touchEnd = (e: TouchEvent) => {
    audioRef.current?.play();
    document.removeEventListener('touchend', touchEnd);
    document.removeEventListener('touchmove', touchMove);
  }

  const mouseEnd = (e: MouseEvent) => {
    audioRef.current?.play();
    document.removeEventListener('mouseup', mouseEnd);
    document.removeEventListener('mousemove', mouseMove);
  }

  const touchMove = (e: TouchEvent) => {
    if(audioRef.current && trackRef.current) {
      const track = trackRef.current;
      const position = e.touches[0].clientX - track.getBoundingClientRect().left;
      const procent = position / track.getBoundingClientRect().width;
      audioRef.current.currentTime =  (audioRef.current.duration || 0) * procent;
    }
  }

  const mouseMove = (e: MouseEvent) => {
    if(audioRef.current && trackRef.current) {
      const track = trackRef.current;
      const position = e.pageX - track.getBoundingClientRect().left;
      const procent = position / track.getBoundingClientRect().width;
      audioRef.current.currentTime =  (audioRef.current.duration || 0) * procent;
    }
  }

  

  const indicatorEvents = isMobile ? {
    onTouchStart: touchStart
  } : {
    onMouseDown: mouseStart
  };

  const indicatorLeft = (audioRef.current?.currentTime || 0) / (audioRef.current?.duration || 0) * 100;

  return (
    <>
      <audio ref={audioRef} hidden>
        <source src={audio || ''} type="audio/mp3" />
        Ваш браузер не поддерживает воспроизведение аудио.
      </audio>
      <TrackContainer onClick={handleTrackClick} ref={trackRef}>
        <TrackProgress width={indicatorLeft} />
        <CurrentPositionIndicator 
          left={indicatorLeft} 
          {...indicatorEvents}
        />
        <StartTime>{convertTime(currentTime || 0)}</StartTime>
        <EndTime>{convertTime(audioRef.current?.duration || 0)}</EndTime>
      </TrackContainer>
      <ControlsContainer>
        <ControlButton onClick={handleRewind}>
          <img src={IconRewind} alt="Назад 10 сек" />
        </ControlButton>
        <ControlButton onClick={handlePlayPause}>
          <img
            src={isFinished ? IconRestart : (isPlaying ? IconPause : IconPlay)}
            alt={isPlaying ? 'Pause' : 'Play'}
          />
        </ControlButton>
        <ControlButton onClick={handleForward}>
          <img src={IconForward} alt="Вперед 10 сек" />
        </ControlButton>
      </ControlsContainer>
    </>
  );
};

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 24px;
`;

const ControlButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
const TrackContainer = styled.div`
  margin: 0 25px 16px;
  height: 4px;
  background-color: #0000001a;
  border-radius: 16px;
  position: relative;
`;

const TrackProgress = styled.div<{width: number}>`
  width: ${p => p.width}%;
  height: 4px;
  background-color: #000000e5;
  border-radius: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const CurrentPositionIndicator = styled.div<{left: number}>`
  width: 16px;
  height: 16px;
  background-color: #000000e5;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: ${p => p.left}%;
  transform: translate(-50%, -50%);
  transition: transform 0.2s;

  :hover {
    transform: translate(-50%, -50%) scale(1.5);
  }
`;
const StartTime = styled.div`
  color: #00000066;
  font-size: 12px;
  position: absolute;
  top: 16px;
  left: 0;
`;

const EndTime = styled.div`
  color: #00000066;
  font-size: 12px;
  position: absolute;
  top: 16px;
  right: 0;
`;
