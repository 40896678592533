import { RefObject, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs';
import { isMobile } from 'src/utils';

export const useCalendarVisible = (
  ref: React.RefObject<HTMLDivElement>
) => {
  useEffect(() => {
    if(ref.current){
      let lastScrollTop = 0;
      const onScroll = (e: any) => {
        const el = e.target!;
        const calendar = document.querySelector('#my-day-calendar')!;
        const st = el.scrollTop;

        if (st > lastScrollTop){
          calendar.classList.add('lined');
          if(st > 100) {
            calendar.classList.add('minimize')
          }
        } else {
          if(st <= 0) {
            calendar.classList.remove('lined');
          } else{
            calendar.classList.remove('minimize')
          }
        }
        lastScrollTop = st;
      };
      const el = ref.current.closest('.popup-scroll');
      el && el.addEventListener('scroll', onScroll);
      return () => {
        el && el.removeEventListener('scroll', onScroll);
      }
    }
  }, [ref.current])
}

export const useCalendarScroll = (
  nodeRef: React.RefObject<HTMLDivElement>,
  month: number,
  setMonth: (month: number) => void,
	year: number,
  setYear: (year: number) => void
) => {
  // const startX = useRef(0);
  // const diff = useRef(0);
  // const isStarted = useRef(false);
  // const _month = useRef(month);
  // const _year = useRef(year);
	// let x = 0

  // const touchStart = (e: TouchEvent | MouseEvent) => {
  //   const clientX = ('touches' in e) ? e.touches[0].clientX : e.clientX;

  //   startX.current = clientX;
  //   diff.current = 0;
  //   isStarted.current = true;

  //   nodeRef.current!.querySelector('div')!.style.transition = `none`;
  // }

  // const touchMove = (e: TouchEvent | MouseEvent) => {
	//   if (isStarted.current) {
	//     const clientX = ('touches' in e) ? e.touches[0].clientX : e.clientX;

	//     diff.current = startX.current;

	// 		const gap = x === 0 ? 0 ? x > 0 : 380 : -380
	//     diff.current = clientX - startX.current
	// 		if (gap) diff.current = diff.current + +(gap)

	//     if (Math.abs(diff.current) > 0) {
	//       // e.stopPropagation();
	//     }

	// 		nodeRef.current!.querySelector('div')!.style.transform = `translateX(${diff.current}px)`

	// 		let img = document.getElementById('my-day-calendar');
	// 		let matrix = nodeRef.current!.querySelector('div');
	// 		console.log('matrix', matrix)
	//   }
  // }

  // const touchEnd = () => {
  //   isStarted.current = false;

  //   if (Math.abs(diff.current) > minDistForClose) {
  //     if(diff.current > 0) {
  //       x = x < 0 ? 0 : 33.33
	// 			console.log('+x', x)
	// 			if (month === 0) {
	// 				_month.current = _month.current - 1
	// 				_month.current = 11
	// 			} else {
	// 					_month.current = _month.current - 1
	// 			}
  //     } else {
	// 				x = x > 0 ? 0 : -33.33
	// 				console.log('-x', x)
	// 				if (month === 11) {
	// 					_year.current = _year.current + 1
	// 					_month.current = 0
	// 				} else {
	// 						_month.current = _month.current + 1
	// 				}
  //     }

  //     setTimeout(() => {
  //       // setMonth(_month.current);
  //       // setYear(_year.current);
  //     }, 500);
  //   }

  //   nodeRef.current!.querySelector('div')!.style.transition = `transform 0.5s`;
  //   nodeRef.current!.querySelector('div')!.style.transform = `translateX(${x}${x === 0 ? 'px' : '%'})`;
  // }

  // useEffect(()=> {
	// 	const startEvent = isMobile ? 'touchstart' : 'mousedown';
  //   const moveEvent = isMobile ? 'touchmove' : 'mousemove';
  //   const endEvent = isMobile ? 'touchend' : 'mouseup';

  //   setTimeout(() => {
  //     nodeRef.current?.addEventListener(startEvent, touchStart);
  //     nodeRef.current?.addEventListener(moveEvent, touchMove);
  //     nodeRef.current?.addEventListener(endEvent, touchEnd);
  //   }, 1000);

	// 	return () => {
	// 		nodeRef.current?.removeEventListener(startEvent, touchStart);
  //     nodeRef.current?.removeEventListener(moveEvent, touchMove);
  //     nodeRef.current?.removeEventListener(endEvent, touchEnd);
	// 	}

  // }, [])
}
