import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import weekday from 'dayjs/plugin/weekday';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import store from '../../../store/Store';
import Title from '../components/Title';
import { observer } from 'mobx-react-lite';
import { ArrowSmall as ArrowIcon } from '../../../assets/icons/system/system-icons';
import { SERVICE_ID } from '../../../helpers/ls/consultations';
import { searchParams } from '../../../helpers/url';
import useLS, { useLSState } from '../../../hooks/useLS';
import { history } from '../../../MainRouter';
import { CustomButton } from '../components/BottomButton';
import { getDateWithNameFromTime, isHaveTimeInSchedule } from '../helpers/services';
import { SessionCreator, SessionFormat, show } from '../../../libs';
import { windowsStore } from '../../../store/Windows';
import { IAuthSmsProps, openSmsAuthWindow } from '../../registration/sms/SmsAuth';

dayjs.locale('ru');
dayjs.extend(isTomorrow);
dayjs.extend(weekday);

const withPlusSign = (phone: string) => phone?.startsWith('+') ? phone : `+${phone}`;
const openSmsRegistrationWindow = ({ title, subtitle, onlyPhoneNumberConfirm, codeConfirmSubtitle, onSuccess }: IAuthSmsProps) => {
  openSmsAuthWindow(onSuccess, title, subtitle, codeConfirmSubtitle, onlyPhoneNumberConfirm);
}

export default observer(function Confirmation(): JSX.Element {
  const [days, setDays] = useState<string[][] | null>(null);

  const [serviceId] = useLS(SERVICE_ID);
  const [professionalId] = useLS('professional-id');
  const [activeTime, setActiveTime, saveActiveTime] = useLS('active-time');
  const [sessionId, setSessionId] = useLSState('session-id');
  const [sessionCreator] = useLS('session-creator');
  const [sessionFormat] = useLS('session-format', SessionFormat.Online);

  const tariff = store.events.tariffs.find(item => item.period === 1)!;
  const isAuth = store.isAuth;

  const sp = searchParams()

  useEffect(() => {
    if (!serviceId || !professionalId) {
      store.clearStorage();
      history.push({pathname: '/', search: sp.toString()});
    }
  }, []);

  useEffect(() => {
    activeTime && saveActiveTime();
  }, [activeTime]);

  useEffect(() => {
    setDays(store.scheduleSlots);

    if (activeTime && store.scheduleSlots) {
      if (!isHaveTimeInSchedule(store.scheduleSlots, activeTime!)) {
        setActiveTime(null);
      } else {
        setActiveTime(activeTime);
      }
    }
  }, [store.scheduleSlots]);


  const confirmSessionHandler = async () => {
    const creator = sessionCreator as SessionCreator || 'indi';
    if (sessionId !== null) {
      const session = await store.getSession(sessionId!);

      if (session) {
        if (
          store.professional
          && store.currentService
          && activeTime
        ) {
          //if (store.professional.userId !== session.userId) {
            const newSessionId = await store.addSession(
              store.currentService,
              store.professional,
              activeTime,
              sessionFormat
                ? sessionFormat
                : SessionFormat.Any,
              creator,
            );
            if(newSessionId > 0) {
              setSessionId(newSessionId);
              return true;
            } else if (newSessionId === -2) {
              show({
                type: 'error',
                text: "Слот уже занят. Выберите другое время",
                timeout: 5000
              });
            } else {
              store.systemAlert('error', 'Произошла ошибка, попробуйте выбрать другое время', 4000);
            }
          return;
        }
      }
    }
    if (
      store.professional
      && store.currentService
      && activeTime
    ) {
      const newSessionId = await store.addSession(
        store.currentService,
        store.professional,
        activeTime,
        sessionFormat
          ? sessionFormat
          : SessionFormat.Any,
        creator,
      );
      if(newSessionId > 0) {
        setSessionId(newSessionId);
        return true;
      } else if (newSessionId === -2) {
        show({
          type: 'error',
          text: "Слот уже занят. Выберите другое время",
          timeout: 5000
        });
      } else {
        store.systemAlert('error', 'Произошла ошибка, попробуйте выбрать другое время', 4000);
      }
    }
  };

  const updateSession = async (phone: string) => {
    const currentSessionId = store.lsGet('session-id');
    const session = await store.getSession(currentSessionId);
    
    if (session.data) {
      session.data.phoneNumber = withPlusSign(phone);
      store.profile.email && (session.data.email = store.profile.email);

      if (session && store.sessionData && session.clientId === null) {
        session.clientId = store.sessionData.id;
      }

      await store.updateSession(currentSessionId, session);
    }
  };

  const confirmHandler = async () => {
    const moveToNextStep = await confirmSessionHandler();

    if (!moveToNextStep) {
      return;
    }

    await store.setSessions();

    if (store.isAuth && store.profile?.phoneNumber) {
      history.push({ pathname: '/personal-data', search: sp.toString() });
    } else {
      openSmsRegistrationWindow({
        onlyPhoneNumberConfirm: true,
        title: 'Подтвердите запись',
        subtitle: 'Введите номер телефона. Мы вышлем код для подтверждения записи',
        codeConfirmSubtitle: 'Мы отправили код для подтверждения записи',
        onSuccess: onPhoneConfirmSuccess
      });
    }
  };

  const onAuthSuccess = () => {
    confirmHandler();
  }

  const onPhoneConfirmSuccess = async (phoneNumber: string) => {
    const profile = {...store.profile};
    const withPlusPhone = withPlusSign(phoneNumber);
    profile.phoneNumber = withPlusPhone;

    await updateSession(phoneNumber);
    history.push({ pathname: '/personal-data', search: sp.toString() });

    // при отправке пустого профиля с одним лишь номером телефона Api.updateProfileNew ругается, но телефон записывает в базу
    try {
      await store.updateProfile(profile);
    } catch(err: any) {
      console.log('onPhoneConfirm update profile err -', err);
    }
  }

  const checkIsAuth = () => {
    if(isAuth) {
      confirmHandler();
    } else {
      openSmsRegistrationWindow({ onSuccess: onAuthSuccess });
    }
  }

  return <ConfirmationContainer>
    <Title
      service={store.currentService}
      sessionFormat={sessionFormat}
      text={`${store.professional?.profile.firstName || ''} ${store.professional?.profile.lastName || ''}`}
    />
    {/*<Rectification style={{ marginBottom: '10px' }} value={true} onChange={() => {}} />*/}
    {days && !days.length && <>
      <Text>
        Нет свободных дат
      </Text>
    </>}
    {days && days.map(day => {
      return <Day key={`day_${day}`}>
        <DayName>
          {getDateWithNameFromTime(day[0])}
        </DayName>
        <Times>
          {
            day?.slice()
            .sort((t1, t2) => {
              const time1 = dayjs(t1).format('HH:mm')
              const time2 = dayjs(t2).format('HH:mm')
              return time1 < time2 ? -1 : (time1 > time2 ? 1 : 0);
            })
            .map(t => {
              return <Time
                key={`time_${t}`}
                active={activeTime === t}
                onClick={() => setActiveTime(t)}
              >
                {dayjs(t).format('HH:mm')}
              </Time>;
            })
          }
        </Times>
      </Day>
    })}
    {activeTime && <CustomButton onClick={checkIsAuth} color={'gradient'} style={{height: '3rem', opacity: 1}}>
      <ButtonText>Подтвердить время</ButtonText><ArrowIcon/>
    </CustomButton>}
  </ConfirmationContainer>;
});

const Time = styled.span<{ active: boolean; }>`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px 12px;
  background: #FFFFFF;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 12px 12px 0;
  cursor: pointer;
  border: 2px solid transparent;

  ${props => props.active && css`
    border: 2px solid #4760E7;
  `}
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 165%;
`;

const Times = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DayName = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.37);
  margin-bottom: 6px;
`;

const Day = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

const ConfirmationContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

const ButtonText = styled.div`
  font-size: 0.8rem;
  letter-spacing: 0.25px;
  margin-top: 0.1rem;
`
