
import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"
import { BlogNew } from "src/modules/blog/components/BlogNew"
import store from "src/store/Store"
import { isMobile } from "src/utils"
import styled, { css } from "styled-components"
import { PersonBanner } from "./Banners/PersonBanner/PersonBanner"
import { windowsStore } from "src/store/Windows"
import { popupsStore } from "src/store/Popups"
import { Sections } from "src/store/Shutter"


export const NewsBanners = observer(() => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const hideNewsBanners =
    !store.newsBanners.showNewsBanners ||
    store.shutter.activeSection !== Sections.MAIN ||
    windowsStore.getWindows().find(window => window.isModal) ||
    popupsStore.getPopups().length > 0

  useEffect(() => {
    store.newsBanners.setNewsBannersRef(containerRef)
    store.products.getPurchasedProducts()
  }, [])

  const getBanner = () => {
    // if (store.newsBanners.getShowTrialExtendedBanner()) {
    //   return <TrialExtendedBanner/>
    // }

    // if (store.newsBanners.getShowPersonBanner()) {
    //   return <PersonBanner/>
    // }

    return <BlogNew/>
  }

  if (hideNewsBanners) return null

  return (
    <Container ref={containerRef} className={'shutter_news'} isMobile={isMobile}>
      {getBanner()}
    </Container>
  )
})

const Container = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: center;
  align-items: end;

  transition: opacity 0.2s;
  z-index: var(--z-index-shutter);
  width: 100%;

  ${p => p.isMobile && css`
    position: fixed;
    bottom: 7rem;
  `}
`
