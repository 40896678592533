import dayjs from "dayjs"
import { debounce } from "lodash"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { isMobile } from 'src/utils';
import styled from "styled-components"
import { gtmSend } from "../../../helpers/url"
import store from "../../../store/Store"
import { BookmarkIcon } from "./BookmarkIcon"
import { RemovedRecommendation } from "./RemovedRecommendation"

interface RecommendationProps {
  className?: string
  background: string
  color: string
  text: string
  saved: boolean
  onClickBookmark(): void
  setShowToaster?(value: boolean): void
  goToEvent?(): void
}

export const Recommendation = observer(({
  className = '',
  background,
  color,
  text,
  saved,
  onClickBookmark,
  setShowToaster,
  goToEvent,
}: RecommendationProps) => {

  const [removing, setRemoving] = useState(false)

  const timerTime = useRef(0)
  // const timerCopyRef = useRef(0)
  const timerRemovingRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const recommendationRef = useRef<HTMLDivElement>(null)

  const userId = store.sessionData?.id

  useEffect(() => {
    // const touchStart = () => {
    //   timerCopyRef.current = dayjs().valueOf()
    // }

    // const touchEnd = (event: TouchEvent) => {
    //   const duration = dayjs().valueOf() - timerCopyRef.current

    //   if (duration > 2000) {
    //     event.stopPropagation()
    //     copyToClipboard(text)
    //     showToaster()

    //     gtmSend({'event': 'ind_prognosis_note_entry', 'user_id': userId ? userId : ''})
    //   }
    // }

    // recommendationRef.current?.addEventListener('touchstart', touchStart)
    // recommendationRef.current?.addEventListener('touchend', event => touchEnd(event))

    const onSelectionChange = debounce(() => {
      const selection = document.getSelection()?.toString();

      // если текст выделен и выделенное равно тексту рекомендации, то отправляем гтм
      if (selection?.length && selection.replace(/\.$/, '') === text.replace(/\.$/, '')) {
        gtmSend({'event': 'ind_prognosis_note_entry', 'user_id': userId ? userId : ''})
      }

    }, 2000);

    document.addEventListener('selectionchange', onSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', onSelectionChange)

    if (timerRemovingRef.current  && (dayjs().valueOf() - timerTime.current) < 7000) {
      clearTimeout(timerRemovingRef.current)
      onClickBookmark()
    }
    }
  }, [])

  const showToaster = () => {
    setShowToaster?.(true)
    setTimeout(() => setShowToaster?.(false), 3000)
  }

  const onClickHandler = () => {
    setRemoving(true)
    timerTime.current = dayjs().valueOf()

    timerRemovingRef.current = setTimeout(() => {
      onClickBookmark()
      setRemoving(false)
    }, 7000)
  }

  const clearTimer = () => {
    timerRemovingRef.current && clearTimeout(timerRemovingRef.current)
    setRemoving(false)
    timerRemovingRef.current = null
  }

  return (
    <>
      {
        removing
          ? <RemovedRecommendation clearTimer={clearTimer}/>
          : <RecommendationContainer
              background={background}
              className={className}
              ref={recommendationRef}
              isMobile={isMobile}
            >
              <div onClick={goToEvent}>
                <p>{text}</p>
              </div>
              <BookmarkIcon
                background={background}
                color={color}
                onClick={onClickHandler}
                saved={saved}
              />
            </RecommendationContainer>
      }
    </>
  )
})

const RecommendationContainer = styled.div<{ background: string, isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.75rem;

  padding: 0.75rem 0.5rem 0.75rem 1rem;
  border-radius: 1.25rem;
  border: 1px solid var(--color-gray-bg-transparent);
  background: ${p => p.background};
  margin-bottom: 0.5rem;
  line-height: 135%;

  p {
    user-select: text;
  }

`
