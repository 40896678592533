import { observer } from "mobx-react-lite";
import React from "react";
import { groupByDate, sortByDate } from "src/helpers/dates";
import { IPurchasedProduct } from "src/store/Products";
import store from "src/store/Store";
import { windowsStore } from "src/store/Windows";
import styled from "styled-components";
import { EMOTIONS, PERSON, productsWindowData, SOLAR, SYNASTRY } from "../helpers/constants";
import { sendProductGtm } from "../helpers/gtm";
import Emotions from "../products/Emotions/Emotions";
import Solar from "../products/Solar/Solar";
import Synastry from "../products/Synastry/Synastry";
import EmptyPurchasedProducts from "./EmptyPurchasedProducts";
import PurchasedProductCard from './purchased-product-card';
import { Person } from "../products/Person";


export const Purchase = observer(() => {
  const purchasedProductsList = store.products.purchasedProducts.slice().sort(sortByDate)
  const groupedPurchasedProductsList = groupByDate(purchasedProductsList)
  const userId = store.sessionData?.id

  const getProductComponent = (type: string, token: number) => {
    switch (type) {
      case SYNASTRY: {
        return <Synastry token={token} />
      }
      case EMOTIONS: {
        return <Emotions token={token} />
      }
      case SOLAR: {
        return <Solar token={token} />
      }
      case PERSON: {
        return <Person token={token} />
      }
    }
  }

  const onClickPurchasedProductCard = (token: number, data: IPurchasedProduct) => {
    sendProductGtm(data.code, 'products_bought', userId)
    store.products.setActiveProductToken(token)

    windowsStore.open(
      productsWindowData[data.code].purchased,
      getProductComponent(data.code, token)
    )
  }

  return (
    <Container>
      {
        groupedPurchasedProductsList.length === 0
          ? <EmptyPurchasedProducts />
          : groupedPurchasedProductsList.map((group, index) => {
              return (
                <React.Fragment key={`group_purchased_${index}`}>
                  <GroupTitle>{group[0]}</GroupTitle>
                  {
                    group[1].map(product => {
                      return (
                        <PurchasedProductCard
                          key={product.id}
                          data={product}
                          onClick={() => { onClickPurchasedProductCard(product.id, product) }}
                        />
                      )}
                    )
                  }
                </React.Fragment>
              )
            })
      }
    </Container>
  )
})

const Container = styled.div`
`

const GroupTitle = styled.div`
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--text-third);
  margin-bottom: 0.5rem;
`
