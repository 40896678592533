import { makeAutoObservable } from "mobx";
import { RefObject } from "react";


export class Ui {
  constructor() {
    makeAutoObservable(this)
  }

  containerRef: RefObject<HTMLDivElement> | null = null
  counterBlogNewRef: RefObject<HTMLDivElement> | null = null
  mainPageInfoBannerRef: RefObject<HTMLDivElement> | null = null

  setContainerRef(containerRef: RefObject<HTMLDivElement> | null) {
    this.containerRef = containerRef
  }

  setCounterBlogNewRef(containerRef: RefObject<HTMLDivElement> | null) {
    this.counterBlogNewRef = containerRef
  }

  setMainPageInfoBannerRef(containerRef: RefObject<HTMLDivElement> | null) {
    this.mainPageInfoBannerRef = containerRef
  }
}
