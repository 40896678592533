import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { history } from 'src/MainRouter';
import { Logging } from 'src/libs';
import { windowsStore } from 'src/store/Windows';
import { isMobile } from 'src/utils';
import styled, { css } from 'styled-components';
import Overlay from '../../components/Overlay';
import { DESKTOP_GAP, DESKTOP_WIDTH } from '../../helpers/ui';
import { gtmSend, searchParams } from '../../helpers/url';
import { useAppInstall } from '../../hooks/app-install-hooks';
import { useMainPopupScroll } from '../../hooks/popup-hooks';
import store from '../../store/Store';
import { getMainPageLS, setMainPageLS } from '../../store/localStorageHelpers';
import { OnboardingDataFilling } from '../data-filling-form/data-filling-form';
import { InstallPWAPopup } from '../install-pwa/InstallPWAPopup';
import { ShutterOnboarding } from '../shutter/Onboarding/shutter-onboarding';
import { Shutter } from '../shutter/Shutter';
import { MainPageOnboarding } from './Onboarding/main-page-onboarding';
import { MainPageActiveSubscriptionScreen } from './components/active-subscription/main-page-active-subscription-screen';
import { MainPageBlocks } from './components/main-page-blocks';
import { MainPageHeader } from './components/main-page-header';
import { TUserStateKeys, states } from './helpers/states';
import { NewsBanners } from '../NewsBanners/NewsBanners';


export const MainPage = observer(() => {
  const [selectedDay, setSelectedDay] = useState(dayjs().startOf('day'))
  const [scrolled, setScrolled] = useState(false)
  const [showOnboarding, setShowOnboarding] = useState(false)
  const [showShutterOnboarding, setShowShutterOnboarding] = useState(false)

  const overlayRef = useRef<HTMLDivElement>(null);
  const mainPageRef = useRef<HTMLDivElement>(null);

  const status = store.events.status;
  const userId = store.sessionData?.id || '';
  const shutterRef = store.shutter.shutterRef
  const newsBannersRef = store.newsBanners.newsBannersRef
  const {startPlan} = store.getIndiDates()
  const profile = store.profile
  const fullPersonalData = profile.firstName && profile.birth.dateTime && profile.birth.place?.name
  const windows = windowsStore.getWindows()
  const showActiveSubscriptionScreen = status === 'pause'
    ? Boolean(store.sessionData?.indi?.trialExpiryDate || store.sessionData?.indi?.expiryDate) || !!store.sessionData?.indi?.startDate
    : status === 'active'

  const { rollDown } = useMainPopupScroll(
    shutterRef,
    overlayRef,
    newsBannersRef,
    userId.toString(),
  );

  useEffect(() => {
    store.logger.startLoggingSession(Logging.Screens.MAIN)

    return () => {
      store.logger.createLoggingSession(Logging.Screens.MAIN)
    }
  }, [])

  useEffect(() => {
    store.mainPage.setSelectedDay(selectedDay)
  }, [selectedDay])

  const checkGtm = useCallback(() => {
    const sp = searchParams()
    const gtmParam = sp.get('gtm')

    if (gtmParam) {
      gtmSend({ 'event': gtmParam, 'user_id': userId ? userId : '' })
      sp.delete('gtm')
      history.push({ pathname: window.location.pathname, search: sp.toString() })
    }
  }, [userId])

  const checkNotifications = useCallback(async () => {
    if (!userId) return

    const showedModal = await store.webPush.getModal(userId)

    if (
      store.pwa.isPWA() &&
      (
        store.webPush.detectOldUser() || dayjs().diff(dayjs(startPlan), 'day', true) > 1
      )  &&
      !showedModal
    ) {
      store.modal.showHandler.notificationsModal = true
    }

    store.webPush.remindAboutWebPush(userId)
  }, [userId, startPlan])

  useEffect(() => {
    checkGtm()
    checkNotifications()
  }, [checkGtm, checkNotifications])

  const hideOnboarding = useCallback(() => {
    setShowOnboarding(false)
    if (mainPageRef.current && !isMobile) mainPageRef.current.style.height = '100vh'
  }, [setShowOnboarding])

  const hideShutterOnboarding = useCallback(() => {
    setShowShutterOnboarding(false)

    const mainPageLS = getMainPageLS()

    const newData = {
      ...mainPageLS,
      onboarding: {
        ...mainPageLS.onboarding,
        showedShutterOnboarding: true,
      }
    }

    setMainPageLS(newData)
  }, [setShowShutterOnboarding])

  useEffect(() => {
    return () => {
      !showOnboarding && showShutterOnboarding && hideShutterOnboarding()
    }
  }, [showOnboarding, showShutterOnboarding, hideShutterOnboarding])

  // const checkOnboarding = useCallback(() => {
  //   if (status === 'active') {
  //     const mainPageLS = getMainPageLS()

  //     if (mainPageLS.onboarding?.showedOnboarding) return

  //     if (store.onboarding.detectOldUser()) {
  //       const newData = {
  //         ...mainPageLS,
  //         onboarding: {
  //           ...mainPageLS.onboarding,
  //           showedOnboarding: true,
  //         }
  //       }

  //       setMainPageLS(newData)
  //     } else {
  //       setShowOnboarding(true)
  //       if (mainPageRef.current && !isMobile) mainPageRef.current.style.height = 'auto'
  //     }
  //   }
  // }, [status])

  // const checkShutterOnboarding = useCallback(() => {
  //   if (status === 'active') {
  //     const mainPageLS = getMainPageLS()
  //     const sessionCount = getAppLS()?.sessionCount ?? 0

  //     if (mainPageLS.onboarding?.showedShutterOnboarding) return

  //     if (store.onboarding.detectOldUser()) {
  //       hideShutterOnboarding()
  //       return
  //     }

  //     if (sessionCount > 2 && !mainPageLS?.usedShutter) {
  //       setTimeout(() => {
  //         setShowShutterOnboarding(true)
  //       }, 2000)
  //     }
  //   }
  // }, [status, setShowShutterOnboarding, hideShutterOnboarding])

  // useEffect(() => {
  //   checkOnboarding()
  //   checkShutterOnboarding()
  // }, [status, checkOnboarding, checkShutterOnboarding])

  const { isVisible, installApp } = useAppInstall();
  const appInstallVisible = status === 'active' && isVisible;

  let stateName: TUserStateKeys | null = store.events.status || 'unlogin';

  if (!stateName) return null;

  const state = states[stateName];
  const Container = isMobile ? Mobile : Desktop;

  if (status === 'active' && profile.id !== -1 && !fullPersonalData) {
    return <OnboardingDataFilling />
  }

  return (
    <>
      <Container ref={mainPageRef}>
        <MainPageHeader
          state={state}
          selected={selectedDay}
          scrolled={scrolled}
          setSelected={setSelectedDay}
        />

        <Content
          isMobile={isMobile}
          isActive={status === 'active'}
        >

          {
           showActiveSubscriptionScreen
              ? <MainPageActiveSubscriptionScreen
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                  scrolled={scrolled}
                  setScrolled={setScrolled}
                />
              : <MainPageBlocks stateName={stateName} />
          }
        </Content>

        <NewsBanners />
        <Shutter rollDown={rollDown} />
        <Overlay
          className={'main-page_overlay'}
          overlayRef={overlayRef}
        />

        {/* {
          showOnboarding && (
            <MainPageOnboarding
              hideOnboarding={hideOnboarding}
              mainPageRef={mainPageRef}
            />
          )
        }

        {
          !showOnboarding && showShutterOnboarding && windows.length === 0 && isMobile &&
          <ShutterOnboarding hideOnboarding={hideShutterOnboarding}/>
        } */}
      </Container>


        {/* {appInstallVisible && <div className='no-print'>
          <InstallPWAPopup onClick={installApp} />
        </div>} */}
    </>
  )
});

const Mobile = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100%;
`
const Desktop = styled.div`
  width: ${DESKTOP_WIDTH}px;
  height: 100vh;
  overflow: auto;
  position: absolute;
  left: 50%;
  transform: translateX(calc(-100% - ${DESKTOP_GAP / 2}px));
`

const Content = styled.div<{ isMobile: boolean, isActive?: boolean }>`
  ${p => p.isMobile && css`
    height: 100%;
    overflow-y: scroll;
  `};

  @media (max-height: 700px) {
    ${p => !p.isActive && css`
      padding-bottom: 8rem;
    `}
  }
`
