import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from './close.svg';
import { getMap } from "./api";
import { getUTMString, gtmSend } from "src/helpers/url";
import bannerImage from './banner.jpg';

interface IRouterProps {
    id: string;
}
interface INatalProps extends RouteComponentProps<IRouterProps> {
}

export function NatalPage(props: INatalProps) {
    const [allData, setData] = useState<any>(null);
    const [map, setMap] = useState<any>(null);

    const id = props.match.params.id;

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND}/api/natal-calc-telegram/${id}`)
            .then(data => data.json())
            .then(data => {
                document.body.style.pointerEvents = 'initial';
                getMap(data.data.user_data, () => {}).then(map => setMap(map))
                setData(data.data)
            })
    }, [id])

    if(!allData || !map) return <></>;


    const user = allData.user_data;
    const data = allData.natal_data;

    const goToIndi: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
        e.preventDefault();
        gtmSend({'event': `indi_freenatal_ad_14days`, 'user_id': ''})
        setTimeout(() => { window.location.href = `https://indi.chronos.mg/trial?d=14&${getUTMString()}`; }, 500 );
      }

    return <Body>
        <Container>
            <Header>
                <TopHeader>
                    <Info>
                        <Name>Здравствуйте, {user.name}</Name>
                        <Date>{user.date}, {user.time}, {user.place} </Date>
                    </Info>
                    {/* <Close href="/"><CloseSvg /></Close> */}
                </TopHeader>

            </Header>

            <Content>
                <Title>Описание личности на основе натальной карты</Title>
                <MapImage src={map} />
                <Gift color="rgb(255 197 32 / 4%);">
                    <GiftIcon>🎁</GiftIcon>
                    <GiftText>
                        <span> Вы получили подарок от Chronos!</span>
                        Проверьте свою электронную почту
                    </GiftText>

                </Gift>

                {data.map((interpretation: any, i: number ) => <>

                    {[interpretation?.sign, interpretation?.house].map((item) => {
                        return <> {item.text && <Block >
                                <h2>{item.title}</h2>
                                <Text dangerouslySetInnerHTML={{__html: item.text || ''}}></Text>
                                <Facts>
                                    {item.facts.map((item: any) => {
                                        return <Fact>
                                            <FactIcon>{item.icon}</FactIcon>
                                            <FactText>{item.text}</FactText>
                                        </Fact>
                                    })}
                                </Facts>
                        </Block>} </>
                    })}

                    {i % 2 === 0  && <a href="/" onClick={goToIndi} target="_blank" rel="noreferrer" >
                        <img style={{width: "100%", display: 'block', marginBottom: '1rem'}} src={bannerImage} alt="banner" />
                    </a>}
                </> )}
            </Content>
        </Container>
    </Body>
}


const Body = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    background: rgba(250, 250, 250, 1);;

    @media (max-width: 400px) {
        background: radial-gradient(370.13% 86.01% at -19.62% 2.65%, #FFF9EF 0%, #FFF0EA 21.82%, #FFF0F4 37.82%, #F2E5FF 66.82%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;;
    }
`

const Container = styled.div`
    width: 100%;
    color: var(--text-secondary);
    font-size: 1rem;

    @media (min-width: 400px) {
       max-width: 620px;
    }

    strong {
        font-size: 1.125rem;
        color: var(--text-primary);
    }
`


const Header = styled.div`
    padding: 1rem 1rem 1.5rem;
    position: relative;
`

const TopHeader = styled.div`
    display: flex;
    align-items: center;
`

const Info = styled.div`
    flex: 1;
`

const Close = styled.a`
    cursor: pointer;
`

const Name = styled.div`
    margin-bottom: 0.375rem;
`

const Date = styled.div`
    font-size: 0.875rem;
    color: var(--text-third);
`

const Content = styled.div`
    background: rgba(250, 250, 250, 1);
    padding: 0rem 1rem 2rem;
    line-height: 1.5rem;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;

    @media (max-width: 400px) {
        padding-top: 1.5rem;
    }
`

const Title = styled.div`
    font-size: 2rem;
    line-height: 2.3rem;
    font-weight: 700;

    margin-bottom: 1.25rem;

    @media (max-width: 400px) {
        font-size: 1.125rem;
        line-height: 1.25rem;
        font-weight: 500;
    }
`

const MapImage = styled.img`
    width: 70%;
    margin: 0 auto;
    display: block;

    @media (max-width: 400px) {
        width: 100%;
    }
`

const Gift = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
    width: 70%;
    margin: 0 auto 2rem;

    @media (max-width: 400px) {
        width: 100%;
        gap: 0.75rem;
        padding: 0.75rem;
    }
`

const GiftIcon = styled.div`
    font-size: 2rem;
`

const GiftText = styled.div`
    font-size: 0.875rem;
    span {
        display: block;
        font-size: 1rem;
        font-weight: 700;

        @media (max-width: 400px) {
            font-size: 0.875rem;
        }
    }

`




const Block = styled.div`
    margin-bottom: 2rem;
    color: var(--text-secondary);
    line-height: 1.5rem;

    > h2 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
        margin-bottom: 0.5rem;
    }
`

const Text = styled.div`
    font-size: 1rem;
    margin-bottom: 1.25rem;
`

const Facts = styled.div`
    display: grid;
    grid-gap: 0.75rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 460px) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
`

const Fact = styled.div`
    background: var(--color-white);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-radius: 1rem;
`

const FactIcon = styled.div`

`

const FactText = styled.div`

`
