import styled, { css } from "styled-components";
import { ReactComponent as MoreSvg } from '../assets/more.svg';
import Cell from "./Cell";
import { windowsStore } from "src/store/Windows";
import { WINDOW_ID_PERSON_2, WINDOW_ID_PERSON_3 } from "src/modules/products/helpers/constants";
import { hashCode, relationsPartnerName, utfIcon } from "../helpers";

import { ReactComponent as StrongSvg } from '../assets/personality/strong.svg';
import { ReactComponent as SituationsSvg } from '../assets/personality/situations.svg';
import { ReactComponent as TalentsSvg } from '../assets/personality/talents.svg';
import { ReactComponent as ValuesSvg } from '../assets/personality/values.svg';
import { ReactComponent as PeopleSvg } from '../assets/personality/people.svg';
import { ReactComponent as TypeSvg } from '../assets/personality/type.svg';

import { ReactComponent as PositionSvg } from '../assets/finance/position.svg';
import { ReactComponent as RisksSvg } from '../assets/finance/risks.svg';
import { ReactComponent as IncomeSvg } from '../assets/finance/income.svg';
import { ReactComponent as FormatSvg } from '../assets/finance/format.svg';

import { ReactComponent as RelaxSvg } from '../assets/energy/relax.svg';
import { ReactComponent as BlocksSvg } from '../assets/energy/blocks.svg';
import { ReactComponent as LevelsSvg } from '../assets/energy/levels.svg';
import { ReactComponent as ZonesSvg } from '../assets/energy/zones.svg';

import { ReactComponent as ExpectationsSvg } from '../assets/relations/expectations.svg';
import { ReactComponent as PartnerSvg } from '../assets/relations/partner.svg';
import { ReactComponent as MeetSvg } from '../assets/relations/meet.svg';
import { ReactComponent as EnemiesSvg } from '../assets/relations/enemies.svg';
import Person2Personality from "../Person-2-Personality";
import Person2Finance from "../Person-2-Finance";
import Person2Energy from "../Person-2-Energy";
import Person2Relations from "../Person-2-Relations";
import Person2Purpose from "../Person-2-Purpose";
import { sendProductGtm } from "src/modules/products/helpers/gtm";
import store from "src/store/Store";
import { observer } from "mobx-react-lite";
import { IndiProductCode } from "../../../../../libs";
import { gtmSend } from "src/helpers/url";
import { useCallback, useEffect, useRef, useState } from "react";
import { openNotes, WINDOW_ID_NOTES } from "src/modules/notes/Notes";
import { Toaster } from "src/components/Toaster";
import { BookmarkIcon } from "src/modules/notes/components/BookmarkIcon";
import { createPortal } from "react-dom";
import Person3 from "../Person-3";
import { FontSizes } from "src/helpers/ui";

type TType = 'personality' | 'finance' | 'energy' | 'relations' | 'purpose';

export default observer(function PersonMainBlock({
  type,
  data,
  allData
}: {
  type: TType,
  data: any,
  allData: any
}) {
  const [toasterTitle, setToasterTitle] = useState<string | null>(null)

  const containerRef = store.ui.containerRef
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null)

  const userId = store.sessionData?.id
  const windows = windowsStore.getWindows()
  const dataOpen = store.notes.dataOpen

  useEffect(() => {
    const hideToaster = () => {
      setToasterTitle(null)
    }

    containerRef?.current?.addEventListener('scroll', hideToaster)

    return () => {
      containerRef?.current?.removeEventListener('scroll', hideToaster)
    }
  }, [containerRef])

  const openNotesHandler = () => {
    const fromNotes = windows.find(window => window.id === WINDOW_ID_NOTES)

    if (fromNotes) {
      windowsStore.close(WINDOW_ID_NOTES)
    }

    openNotes(true, true)
    setToasterTitle(null)
  }

  const onClickBookmark = useCallback((
    screenLevel: number,
    title: string,
    text: string,
    isSaved: boolean
  ) => {
    const userId = store.sessionData?.id
    const productToken = store.products.activeProductToken

    if (!userId || !productToken) return

    const data = {
      userId,
      title,
      text,
      product: IndiProductCode.PERSON,
      productId: productToken,
      type,
      screenLevel,
    }

    store.notes.onClickBookmark(data, isSaved)

    setToasterTitle(isSaved ? 'Удалено из Моих заметок' : 'Добавлено в Мои заметки')
    timerId.current && clearTimeout(timerId.current)
    timerId.current = setTimeout(() => setToasterTitle(null), 7000);

    !isSaved && gtmSend({ 'event': 'indi_persdescr_note_adding', 'user_id': userId ?? '' })
  }, [type])

  const onOpen3Level = useCallback((data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => {
    windowsStore.open(
      WINDOW_ID_PERSON_3,
      <Person3
        data={data}
        scrollTo={hash}
        parentTitle={parentTitle}
        allData={allData}
        onClickBookmark={(title: string, text: string, isSaved: boolean) => onClickBookmark(3, title, text, isSaved)}
      />,
      undefined,
      true
    );

    gtmEvent && sendProductGtm('PERSON', gtmEvent, userId);
  }, [onClickBookmark])

  const onClick = useCallback((name?: string) => {
    const hash = name ? hashCode(name) : undefined;
    let component, gtmEvent;

    if (type === 'personality') {
      component = <Person2Personality
        data={data}
        scrollTo={hash}
        onOpen3Level={(data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => onOpen3Level(data, hash, gtmEvent, userId, parentTitle, allData)}
      />;
      gtmEvent = !name ? 'personality_more' : 'personality_section_click';
    }

    if (type === 'finance') {
      component = <Person2Finance
        data={data}
        scrollTo={hash}
        onClickBookmark={(title: string, text: string, isSaved: boolean) => onClickBookmark(2, title, text, isSaved)}
        onOpen3Level={(data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => onOpen3Level(data, hash, gtmEvent, userId, parentTitle, allData)}
      />;
      gtmEvent = !name ? 'finance_career_more' : 'finance_career_section_click';
    }

    if (type === 'energy') {
      component = <Person2Energy
        data={data}
        scrollTo={hash}
        onClickBookmark={(title: string, text: string, isSaved: boolean) => onClickBookmark(2, title, text, isSaved)}
        onOpen3Level={(data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => onOpen3Level(data, hash, gtmEvent, userId, parentTitle, allData)}
      />;
      gtmEvent = !name ? 'health_energy_more' : 'health_energy_section_click';
    }

    if (type === 'relations') {
      component = <Person2Relations
        data={data}
        scrollTo={hash}
        gender={allData.clientOpts.gender}
        onOpen3Level={(data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => onOpen3Level(data, hash, gtmEvent, userId, parentTitle, allData)}
      />;
      gtmEvent = !name ? 'love_more' : 'love_section_click';
    }

    if (type === 'purpose') {
      component = <Person2Purpose
        data={data} scrollTo={hash}
        onClickBookmark={(title: string, text: string, isSaved: boolean) => onClickBookmark(2, title, text, isSaved)} />;
      gtmEvent = 'purpose_more';
    }

    gtmEvent && sendProductGtm('PERSON', gtmEvent, userId);

    windowsStore.open(WINDOW_ID_PERSON_2, component, undefined, true)
  }, [allData.clientOpts.gender, data, onClickBookmark, onOpen3Level, type, userId])

  useEffect(() => {
    if (!dataOpen || dataOpen?.type !== type) return

    onClick(dataOpen?.screenLevel === 2 ? dataOpen.title : '')
    dataOpen?.screenLevel === 2 && store.notes.resetDataOpen()
  }, [dataOpen, type, onClick])

  return (
    <>
      <Container>
        <MainIcon>{utfIcon(data.icon)}</MainIcon>
        <MainTitle>{data.title}</MainTitle>
        <MainText>{data.description}</MainText>
        <MoreButton onClick={() => onClick(undefined)} id={`${type}-more-button`}>Подробнее <MoreSvg /></MoreButton>

        {type === 'personality' && <Grid>
          <Cell image={<PeopleSvg />} title={data.howPeoplesSee.title} onClick={() => onClick(data.howPeoplesSee.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<TypeSvg />} title={data.personType.title} onClick={() => onClick(data.personType.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<StrongSvg />} title={'Сильные и слабые стороны'} onClick={() => onClick(data.strongSides.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<SituationsSvg />} title={data.situations.title} onClick={() => onClick(data.situations.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<TalentsSvg />} title={data.talents.title} onClick={() => onClick(data.talents.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<ValuesSvg />} title={data.values.title} onClick={() => onClick(data.values.title)} fontSize={FontSizes.PRIMARY} />
        </Grid>}

        {type === 'finance' && <Grid>
          <Cell image={<PositionSvg />} title={data.financePosition.title} onClick={() => onClick(data.financePosition.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<RisksSvg />} title={data.financeRisks.title} onClick={() => onClick(data.financeRisks.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<IncomeSvg />} title={data.income.title} onClick={() => onClick(data.income.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<FormatSvg />} title={data.format.title} onClick={() => onClick(data.format.title)} fontSize={FontSizes.PRIMARY} />
        </Grid>}

        {type === 'energy' && <Grid>
          <Cell image={<RelaxSvg />} title={data.relax.title} onClick={() => onClick(data.relax.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<BlocksSvg />} title={data.energyBlocks.title} onClick={() => onClick(data.energyBlocks.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<LevelsSvg />} title={data.energyLevels.title} onClick={() => onClick(data.energyLevels.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<ZonesSvg />} title={data.zones.title} onClick={() => onClick(data.zones.title)} fontSize={FontSizes.PRIMARY} />
        </Grid>}

        {type === 'relations' && <Grid>
          <Cell image={<ExpectationsSvg />} title={data.expectation.title} onClick={() => onClick(data.expectation.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<PartnerSvg />} title={relationsPartnerName} onClick={() => onClick(relationsPartnerName)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<MeetSvg />} title={data.whereToMeet.title} onClick={() => onClick(data.whereToMeet.title)} fontSize={FontSizes.PRIMARY} />
          <Cell image={<EnemiesSvg />} title={data.enemiesOfHappiness.title} onClick={() => onClick(data.enemiesOfHappiness.title)} fontSize={FontSizes.PRIMARY} />
        </Grid>}
      </Container>

      {
        toasterTitle && (
          createPortal(
            <ToasterWrapper>
              <StyledToaster
                icon={<StyledBookmarkIcon color='#F49550' background='rgba(244, 149, 80, 0.1)' />}
                title={toasterTitle}
                btnTitle='Перейти'
                onClick={openNotesHandler}
                color={'#F49550'}
              />
            </ToasterWrapper>,
            containerRef?.current ?? document.body
          )
        )
      }
    </>
  )
})

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
`

const MainIcon = styled.div`
    font-size: 3.5rem;
    margin-bottom: 0.675rem;
`

const MainTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
`

const MainText = styled.div`
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    line-height: 1.5rem;
`

export const MoreButton = styled.div<{ disabled?: boolean, color?: string}>`
    display: inline-flex;
    align-items: center;
    background: ${p => p.color ? p.color : 'var(--color-gray-light)'};
    padding: 0.875rem;
    font-size: 0.875rem;
    border-radius: 20px;
    margin-top: 1rem;
    cursor: pointer;

    > svg {
        margin-left: 0.5rem;
        margin-top: 2px;
    }

    ${p => p.disabled && css`
        pointer-events: none;
        opacity: 0.6;
        background: var(--color-gray-light);
    `}
`

export const Grid = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 1.5rem;
`

const ToasterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1rem;
`

const StyledToaster = styled(Toaster)`
  width: 90%;
`

const StyledBookmarkIcon = styled(BookmarkIcon)`
  padding: 0;
`
export const MainBlock = styled.div`
    margin-bottom: 2.25rem;
`

export const MainBlockText = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
`

export const MainBlockHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.675rem;
`

export const MainBlockHeaderTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
`

export const MainBlockHeaderIcon = styled.div`
    font-size: 1.75rem;
`

export const SubTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.375rem;
  color: var(--text-third);
`

export const RangesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  margin-top: 1.5rem;
`

export const List = styled.div`
  margin-top: 1.5rem;
`

export const Item = styled.div`
  padding: 0.75rem;
  border-radius: 12px;
  background: rgba(246, 244, 242, 1);
  margin-bottom: 0.25rem;
`
