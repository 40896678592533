import styled from "styled-components";
import store from '../../../../store/Store';
import { WINDOW_ID_PERSON, WINDOW_ID_PERSON_2 } from '../../helpers/constants';
import { windowsStore } from '../../../../store/Windows';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import PersonHeader from './components/PersonHeader';
import { history } from '../../../../MainRouter';
import { observer } from 'mobx-react-lite';
import { searchParams } from '../../../../helpers/url';
import { Grid, Item, List, MainBlock, MainBlockHeader, MainBlockHeaderIcon, MainBlockHeaderTitle, MainBlockText, MoreButton, RangesWrapper } from './components/PersonMainBlock';
import { ReactComponent as MoreSvg } from './assets/more.svg';
import Cell from './components/Cell';
import { Indi } from '@chronos/api';
import { hashCode, relationsHashCode, useScrollToBlock, utfIcon } from './helpers';
import { sendProductGtm } from "../../helpers/gtm";
import PersonFeedback from './components/PersonFeedback';
import { BackButton } from './Person-3';
import { ReactComponent as BackSvg } from './assets/back.svg';
import { Gender } from "src/libs";
import { useEffect, useRef, useState } from "react";
import Range from "./components/Range";
import { SubTitle } from "./components/PersonMainBlock";
import { Switcher } from "src/components/ui/Switcher";
import { ToTopButton } from "src/components/ToTopButton";
import { SignUpConsultationBanner } from "src/components/banners/SignUpConsultationBanner/SignUpConsultationBanner";
import { Stub } from "./components/Stub";
import { useBackWindow } from "src/hooks/router-hooks";

type GenderType = 'Female' | 'Male'

const genderItems = [
  {
    value: 'Female',
    title: 'Женский',
    icon: utfIcon('U+1F469U+200DU+1F9B0'),
  },
  {
    value: 'Male',
    title: 'Мужской',
    icon: utfIcon('U+1F468U+200DU+1F9B1'),
  },
]

interface IPersonProps {
  data: Indi.GetPerson.IPersonRelations,
  scrollTo?: string;
  gender?: Gender
  onOpen3Level: (data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => void
}

export default observer(function Person2Relations({data, scrollTo, gender, onOpen3Level}: IPersonProps) {
  useBackWindow(window.location.pathname, WINDOW_ID_PERSON_2)

  const getGender = () => {
    return genderItems.find(item => item.value.toLowerCase() !== gender) ?? genderItems[0]
  }

  const [currentGender, setCurrentGender] = useState(getGender())

  const containerRef = useRef<HTMLDivElement | null>(null)

  useScrollToBlock(scrollTo);

  const sp = searchParams();

  const userId = store.sessionData?.id
  const dataOpen = store.notes.dataOpen

  const enemiesOfHappinessItems = data.enemiesOfHappiness.texts ?? []

  useEffect(() => {
    if (dataOpen?.screenLevel === 3) {
      const element = containerRef.current?.getElementsByClassName(`cell_${dataOpen?.title}`)[0]

      if (element && element instanceof HTMLElement) {
        element.click()
        store.notes.resetDataOpen()
      }
    }
  }, [dataOpen])

  const onBack = () => {
    sendProductGtm('PERSON', 'back_header', userId);
    windowsStore.close(WINDOW_ID_PERSON_2)
  }

  const onClose = () => {
    sendProductGtm('PERSON', 'close', userId);
    windowsStore.close(WINDOW_ID_PERSON)
    windowsStore.close(WINDOW_ID_PERSON_2)
    history.push({ pathname: '', search: sp.toString() })
  }

  const onNext = () => {
    sendProductGtm('PERSON', 'next_sphere', userId);
    windowsStore.close(WINDOW_ID_PERSON_2);
    document.getElementById(`purpose-more-button`)?.click();
  }

  const getGenderKey = () => {
    return genderItems.find(item => item.value !== currentGender.value)?.value as GenderType ?? genderItems[0].value as GenderType
  }

  return (
    <WindowContainer className={'window-container'} id="Person2Window" ref={containerRef}>
      <PersonHeader onBack={onBack} onClose={onClose} title={data.title} />
      <Container>
        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderIcon>{utfIcon(data.icon)}</MainBlockHeaderIcon>
                <MainBlockHeaderTitle>{data.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>{data.description}</MainBlockText>
        </MainBlock>


        <MainBlock>
            <SubTitle>{data.metrics.partnerLoner.title}</SubTitle>

            <MainBlockText>
                {data.metrics.partnerLoner.description}
            </MainBlockText>

            <RangesWrapper>
              <Range
                from={data.metrics.partnerLoner.metrics1}
                to={data.metrics.partnerLoner.metrics2}
                position={data.metrics.partnerLoner.value}
                color={'var(--range-color-pink)'}
              />
            </RangesWrapper>

            <SwitcherContainer>
              <Label>Пол вашего партнёра</Label>
              <Switcher
                items={genderItems}
                value={currentGender.value}
                onChange={setCurrentGender}
                icon={currentGender.icon}
              />
            </SwitcherContainer>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.expectation.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.expectation.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.expectation.description}
            </MainBlockText>

            <List>
                {
                //@ts-ignore
                data.expectation.texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </List>
        </MainBlock>


        <MainBlock id={`scrollTo${relationsHashCode}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data[`importantInPartner${getGenderKey()}`].title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data[`importantInPartner${getGenderKey()}`].description}
            </MainBlockText>

            <List>
                {
                //@ts-ignore
                data[`importantInPartner${getGenderKey()}`].texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </List>
        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data[`needInMarriage${getGenderKey()}`].title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data[`needInMarriage${getGenderKey()}`].description}
            </MainBlockText>

            <List>
                {
                //@ts-ignore
                data[`needInMarriage${getGenderKey()}`].texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </List>
        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data[`getWorldOffer${getGenderKey()}`].title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data[`getWorldOffer${getGenderKey()}`].description}
            </MainBlockText>

            <List>
                {
                //@ts-ignore
                data[`getWorldOffer${getGenderKey()}`].texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </List>
        </MainBlock>



        <MainBlock id={`scrollTo${hashCode(data.whereToMeet.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.whereToMeet.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.whereToMeet.description}
            </MainBlockText>

            <List>
                {
                //@ts-ignore
                data.whereToMeet.texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </List>
        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.friendOfHappiness.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.friendOfHappiness.description}
            </MainBlockText>

            <List>
                {
                //@ts-ignore
                data.friendOfHappiness.texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </List>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.enemiesOfHappiness.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.enemiesOfHappiness.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.enemiesOfHappiness.description}
            </MainBlockText>

            {
              enemiesOfHappinessItems.length > 0
                ? <>
                    <MoreButton onClick={() => onOpen3Level(enemiesOfHappinessItems, undefined, 'love_blocks', userId, 'Личная жизнь')}>Подробнее <MoreSvg /></MoreButton>
                    <Grid>
                      {enemiesOfHappinessItems.map((item: any, index: number) =>
                          //@ts-ignore
                          <Cell key={`cell_${index}_${item.title}`} icon={utfIcon(item.icon)} title={item.title} onClick={() => onOpen3Level(enemiesOfHappinessItems, hashCode(item.title), 'love_blocks', userId, 'Личная жизнь')} />
                      )}
                    </Grid>
                  </>
                : <Stub/>
            }
        </MainBlock>

        <SignUpConsultationBanner />

        <PersonFeedback />

        <BackButton onClick={onNext} down>
            Перейти к предназначению
            <BackSvg />
        </BackButton>


      </Container>

      <ToTopButton containerRef={containerRef}/>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`

const SwitcherContainer = styled.div`
  margin-top: 2rem;
`

const Label = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`
