import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'src/utils';
import styled, { css } from 'styled-components';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '../../../assets/icons/calendar';
import { blockScroll } from '../../../helpers/ui';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';
import { PopupHeader } from '../../popups/components/PopupHeader';
import { Day, getCalendarTitle, getMonthData } from '../helpers/calendar';
import { Calendar } from 'src/components/calendar/Сalendar';


export const MyDayHeader = observer(({
  selected,
  setSelected
}: {
  selected: dayjs.Dayjs,
  setSelected(value: dayjs.Dayjs): void
}) => {
	const [showCalendar, setShowCalendar] = useState(false)
	const [month, setMonth] = useState(dayjs(selected).month())
	const [year, setYear] = useState(dayjs(selected).year())

	const isToday = dayjs().startOf('day').isSame(selected.startOf('day'));
	const isTomorrow = dayjs().startOf('day').diff(selected.startOf('day'), 'day') === -1;
	const isPreviousMonth = dayjs().set('year', year).set('month', month).startOf('day').isBefore(selected.startOf('day'))
	const isNextMonth = dayjs().set('year', year).set('month', month).startOf('day').isAfter(selected.startOf('day'))

	const [sliderItems, setSliderItems] = useState<Day[][][]>([])
	const calendarContainerRef = useRef<HTMLDivElement>(null);
	const sliderContainerRef = useRef<HTMLDivElement>(null);
	const sliderItemRef = useRef<HTMLTableElement>(null);

	const userId = store.sessionData?.id;

	const updateSliderItems = (year: number, month: number, selected: dayjs.Dayjs) => {
		const monthData = getMonthData(year, month, selected)

		const previousMonth = dayjs(selected).add(-1, 'month').month()
		const previousYear = previousMonth === 11 ? year - 1 : year
		const previousMonthData = getMonthData(previousYear, previousMonth, selected)

		const nextMonth = dayjs(selected).set('month', month).add(1, 'month').month()
		const nextYear = nextMonth === 0 ? year + 1 : year
		const nextMonthData = getMonthData(nextYear, nextMonth, selected)

		setSliderItems([ previousMonthData, monthData, nextMonthData ])
	}

	useEffect(() => {
		updateSliderItems(year, month, selected)
	}, [selected])

	useEffect(() => {
		blockScroll(showCalendar)
		setSelectedDate()
	}, [selected, showCalendar])

	const setTodayDate = () => {
    setSelected(dayjs().startOf('day'));
		setMonth(dayjs().startOf('day').month())
		setYear(dayjs().startOf('day').year())
  }

	const setSelectedDate = () => {
		if (dayjs(selected).month() !== month || dayjs(selected).year() !== year) {
			setMonth(dayjs(selected).month())
			setYear(dayjs(selected).year())
		}
	}

	const onClose = () => {
		setTimeout(() => {
			setShowCalendar(false)
		}, 500)
	}

	const toggleCalendar = () => {
		if (showCalendar) {
			calendarContainerRef.current!.style.transform = `translateY(-100%)`
			onClose()
		} else {
				setShowCalendar(true)
				calendarContainerRef.current!.style.transform = `translateY(-1px)`
				gtmSend({'event': 'indi_my-day_open-calendar_click', 'user_id': userId ? userId : ''})
		}
  }

	const moveSliderItems = useCallback((next: boolean,year: number, month: number, selected: dayjs.Dayjs) => {
		if (sliderContainerRef.current) {
			sliderContainerRef.current!.querySelector('div')!.style.transition = `transform 0.5s`;
    	sliderContainerRef.current!.querySelector('div')!.style.transform = `translateX(${next ? '-' : ''}${sliderItemRef.current!.offsetWidth}px)`;

			setTimeout(() =>{
				updateSliderItems(year, month, selected)
				sliderContainerRef.current!.querySelector('div')!.style.transition = `transform 0s`;
				sliderContainerRef.current!.querySelector('div')!.style.transform = `translateX(0px)`;
			}, 250)
		}
	}, [sliderContainerRef])

	const toPreviousMonth = useCallback((_selected?: dayjs.Dayjs) => {
		let _year = year
		let _month = month

		if (_month === 0) {
			_year = _year - 1
			_month = 11
		} else {
			_month = _month - 1
		}

		setYear(_year)
		setMonth(_month)

		moveSliderItems(false, _year, _month, _selected ?? selected)
	}, [year, month, selected, moveSliderItems])

	const toNextMonth = useCallback((_selected?: dayjs.Dayjs) => {
		let _year = year
		let _month = month

		if (_month === 11) {
			_year = _year + 1
			_month = 0
		} else {
			_month = _month + 1
		}

		setYear(_year)
		setMonth(_month)

		moveSliderItems(true, _year, _month, _selected ?? selected)
	}, [year, month, selected, moveSliderItems])

	const setSelectedHandler = (date: dayjs.Dayjs) => {
		setSelected(date)

		const selectedMonth = date.month()
		if (selectedMonth < month) toPreviousMonth(date)
		if (selectedMonth > month) toNextMonth(date)

		gtmSend({'event': 'indi_my-day_change-date-calendar_click', 'user_id': userId ? userId : ''})

		setTimeout(() => {
			toggleCalendar()
		}, 300)
	}

	const rightBlock = (
		<MyDayHeaderRightBlockContainer>
			{!isToday && !showCalendar && !isTomorrow && <Today onClick={setTodayDate}>К сегодня</Today>}

			{
				showCalendar && (
					<Chevrons>
						<IconContainer><ChevronLeftIcon onClick={() => toPreviousMonth()}/></IconContainer>
						<IconContainer><ChevronRightIcon onClick={() => toNextMonth()}/></IconContainer>
					</Chevrons>)
			}

			<IconContainer onClick={toggleCalendar} showCalendar={showCalendar}>
				<CalendarIcon/>
			</IconContainer>
		</MyDayHeaderRightBlockContainer>
	)

  return (
    <Container id="my-day-calendar" showCalendar={showCalendar}>
    	<StyledPopupHeader
	      title={getCalendarTitle(selected, month, year)}
	      rightElement={rightBlock}
				isPreviousMonth={isPreviousMonth}
				isNextMonth={isNextMonth}
				isMobile={isMobile}
	    />

			<Calendar
				showCalendar={showCalendar}
				sliderItems={sliderItems}
				calendarContainerRef={calendarContainerRef}
				sliderContainerRef={sliderContainerRef}
				sliderItemRef={sliderItemRef}
				setSelected={setSelectedHandler}
				toPreviousMoth={toPreviousMonth}
				toNextMoth={toNextMonth}
				onClose={onClose}
			/>

			{
				showCalendar && <Overlay onClick={toggleCalendar}/>
			}
    </Container>
  )
});

const Container = styled.div<{showCalendar: boolean}>`
  background: initial;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 4rem;
  z-index: 100;
	overflow-y: hidden;
	border-radius: 32px 32px 0px 0px;

  &.lined {
    box-shadow: 0px 4px 14px 0px rgba(129, 136, 157, 0.08);
  }

  &.minimize .my-day-calendar-days{
    height: 0;
    overflow: hidden;
  }

	${p => !p.showCalendar && css`
		height: 3.5rem;
	`}
`

const StyledPopupHeader = styled(PopupHeader)<{isPreviousMonth: boolean, isNextMonth: boolean, isMobile: boolean}>`
	background: ${p => p.isMobile ? 'var(--color-white)' : 'var(--desktop-bg)'};
	padding-top: 1.5rem;

  position: relative;

	${p => p.isPreviousMonth && css`
		color: var(--text-disabled);
	`}

	${p => p.isNextMonth && css`
		color: var(--text-third);
	`}
`

const MyDayHeaderRightBlockContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const Today = styled.div`
  border-radius: 41px;
  background: var(--popup-line-background);
  font-size: 0.875rem;
  color: var(--text-primary);
  padding: 0.15rem 0.6rem;
`

const IconContainer = styled.div<{showCalendar?: boolean}>`
	padding: 0.3rem;
	border-radius: 0.5rem;

	${p => p.showCalendar && css`
		background: rgba(0, 0, 0, 0.08);`
	}

	&:active {
		background: rgba(0, 0, 0, 0.08);
	}
`

const Overlay = styled.div`
	position: fixed;
  width: 100%;
  height: 100%;
  background: var(--overlay-bg-37);
  top: 0;
	z-index: -1;
`

const Chevrons = styled.div`
	display: flex;
	gap: 1rem;
`
