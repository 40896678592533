import { makeAutoObservable } from "mobx";


export class Blog {
  constructor() {
    makeAutoObservable(this)
  }

  showBlogNew = true

  setShowBlogNew(value: boolean) {
    this.showBlogNew = value
  }
}
