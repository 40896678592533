import { makeAutoObservable } from "mobx";
import { RefObject } from "react";
import { PERSON } from "src/modules/products/helpers/constants";
import { getBannersLS, getMainPageLS } from "./localStorageHelpers";
import store from "./Store";

export class NewsBanners {
  constructor() {
    makeAutoObservable(this)
  }

  showNewsBanners: boolean = true
  newsBannersRef: RefObject<HTMLDivElement> | null = null

  // getShowTrialExtendedBanner() {
  //   const banners = getBannersLS()
  //   return banners.extendedTrialDateBanner?.expiryDate
  // }

  getShowPersonBanner() {
    const banners = getBannersLS()
    const mainPageLS = getMainPageLS()
    const purchasedProducts = store.products.purchasedProducts

    return (
      !banners?.personBanner?.hidden &&
      store.events.status === 'active' &&
      mainPageLS.onboarding?.showedOnboarding &&
      (
        mainPageLS.usedShutter ||
        mainPageLS.onboarding?.showedShutterOnboarding
      ) &&
      !purchasedProducts.find(product => product.code === PERSON)
    )
  }

  setShowNewsBanners(value: boolean) {
    this.showNewsBanners = value
  }

  setNewsBannersRef(containerRef: RefObject<HTMLDivElement> | null) {
    this.newsBannersRef = containerRef
  }
}
