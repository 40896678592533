import { Indi } from '../../../../libs';
import { observer } from 'mobx-react-lite';
import styled from "styled-components";
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import { searchParams } from '../../../../helpers/url';
import { history } from '../../../../MainRouter';
import store from '../../../../store/Store';
import { windowsStore } from '../../../../store/Windows';
import { WINDOW_ID_PERSON, WINDOW_ID_PERSON_2 } from '../../helpers/constants';
import { sendProductGtm } from '../../helpers/gtm';
import { ReactComponent as BackSvg } from './assets/back.svg';
import { ReactComponent as MoreSvg } from './assets/more.svg';
import Cell from './components/Cell';
import PersonFeedback from './components/PersonFeedback';
import PersonHeader from './components/PersonHeader';
import { Grid, Item, List, MainBlock, MainBlockHeader, MainBlockHeaderIcon, MainBlockHeaderTitle, MainBlockText, MoreButton } from './components/PersonMainBlock';
import { hashCode, useScrollToBlock, utfIcon } from './helpers';
import { BackButton } from './Person-3';
import { useEffect, useMemo, useRef } from 'react';
import InfoBlock from './components/InfoBlock';
import Range from './components/Range';
import { RangesWrapper, SubTitle } from './components/PersonMainBlock';
import { ToTopButton } from 'src/components/ToTopButton';
import { SignUpConsultationBanner } from 'src/components/banners/SignUpConsultationBanner/SignUpConsultationBanner';
import { Stub } from './components/Stub';
import { useBackWindow } from 'src/hooks/router-hooks';

interface IPersonProps {
  data: Indi.GetPerson.IPersonPersonality,
  scrollTo?: string;
  onOpen3Level: (data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => void
}

export default observer(function Person2Personality({ data, scrollTo, onOpen3Level }: IPersonProps) {
  useBackWindow(window.location.pathname, WINDOW_ID_PERSON_2)

  const containerRef = useRef<HTMLDivElement | null>(null)

  useScrollToBlock(scrollTo);

  const userId = store.sessionData?.id
  const dataOpen = store.notes.dataOpen

  const sp = searchParams();

  const weakSidesItems = data.weakSides.texts ?? []

  useEffect(() => {
    if (dataOpen?.screenLevel === 3) {
      const element = containerRef.current?.getElementsByClassName(`cell_${dataOpen?.title}`)[0]

      if (element && element instanceof HTMLElement) {
        element.click()
        store.notes.resetDataOpen()
      }
    }
  }, [dataOpen])

  const onBack = () => {
    sendProductGtm('PERSON', 'back_header', userId);
    windowsStore.close(WINDOW_ID_PERSON_2)
  }

  const onClose = () => {
    sendProductGtm('PERSON', 'close', userId);
    windowsStore.close(WINDOW_ID_PERSON)
    windowsStore.close(WINDOW_ID_PERSON_2)
    history.push({ pathname: '', search: sp.toString() })
  }

  const onNext = () => {
    sendProductGtm('PERSON', 'next_sphere', userId);
    windowsStore.close(WINDOW_ID_PERSON_2);
    document.getElementById(`finance-more-button`)?.click();
  }

  const personTypeRanges = useMemo(() => {
    return Object.keys(data.metrics).map(key => {
      const range = data.metrics[key as keyof Indi.GetPerson.IPersonPersonalityMetrics]

      const getColor = () => {
        let color = ''
        switch (key) {
          case 'emotionsLogic':
            color = 'purple'
            break
          case 'fastSlow':
            color = 'orange'
            break
          case 'introvertExtrovert':
            color = 'blue'
            break
          case 'standalone':
            color = 'green'
            break
        }

        return `var(--range-color-${color})`
      }


      return { ...range, color: getColor() }
    })
  }, [data.metrics])

  return (
    <WindowContainer className={'window-container'} id="Person2Window" ref={containerRef}>
      <PersonHeader onBack={onBack} onClose={onClose} title={data.title} />
      <Container>
        <MainBlock>
          <MainBlockHeader>
            <MainBlockHeaderIcon>{utfIcon(data.icon)}</MainBlockHeaderIcon>
            <MainBlockHeaderTitle>{data.title}</MainBlockHeaderTitle>
          </MainBlockHeader>
          <MainBlockText>{data.description}</MainBlockText>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.howPeoplesSee.title)}`}>
          <SubTitle>{data.howPeoplesSee.title}</SubTitle>

          {
            data.howPeoplesSee.texts?.[0] && (
              <>
                <MainBlockHeader>
                  <MainBlockHeaderIcon>{utfIcon(data.howPeoplesSee.texts?.[0].icon)}</MainBlockHeaderIcon>
                  <MainBlockHeaderTitle>{data.howPeoplesSee.texts?.[0].title}</MainBlockHeaderTitle>
                </MainBlockHeader>

                <InfoBlock type={'info'} text={data.howPeoplesSee.description} />

                <MainBlockText>
                  {data.howPeoplesSee.texts?.[0].description}
                </MainBlockText>
              </>
            )
          }

          {
            data.howPeoplesSee.texts && data.howPeoplesSee.texts?.length > 1 && (
              <Grid>
                {
                  data.howPeoplesSee.texts.map((item: any, index: number) => {
                    if (index > 0) {
                      return (
                        <Cell
                          key={`personalityType_${index}`}
                          //@ts-ignore
                          icon={utfIcon(item.icon)}
                          title={item.title}
                          onClick={() => onOpen3Level(data.howPeoplesSee.texts, hashCode(item.title), 'personality_how_people_see', userId, data.title)}
                        />
                      )
                    } else {
                      return null
                    }
                  })
                }
              </Grid>
            )
          }
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.personType.title)}`}>
          <SubTitle>{data.personType.title}</SubTitle>
          {
            data.personType.texts?.[0] && (
              <>
                <MainBlockHeader>
                  <MainBlockHeaderIcon>{utfIcon(data.personType.texts?.[0].icon)}</MainBlockHeaderIcon>
                  <MainBlockHeaderTitle>{data.personType.texts?.[0].title}</MainBlockHeaderTitle>
                </MainBlockHeader>

                <InfoBlock type={'info'} text={data.personType.description} />

                <MainBlockText>
                  {data.personType.texts?.[0].description}
                </MainBlockText>

                <MoreButton onClick={() => onOpen3Level(data.personType.texts, undefined, 'personality_person_type', userId, data.title)}>
                  Подробнее <MoreSvg />
                </MoreButton>
              </>
            )
          }

          {
            data.personType.texts && data.personType.texts.length > 1 && (
              <Grid>
                {
                  data.personType.texts.map((item: any, index: number) => {
                    if (index > 0) {
                      return (
                        <Cell
                          key={`personalityType_${index}`}
                          //@ts-ignore
                          icon={utfIcon(item.icon)}
                          title={item.title}
                          onClick={() => onOpen3Level(data.personType.texts, hashCode(item.title), 'personality_person_type', userId, data.title)}
                        />
                      )
                    } else {
                      return null
                    }
                  })
                }
              </Grid>
            )
          }

          <RangesWrapper>
            {
              personTypeRanges.map(range => (
                <Range from={range.metrics1} to={range.metrics2} position={range.value} color={range.color} />
              ))
            }
          </RangesWrapper>
        </MainBlock>


        <MainBlock>
          <MainBlockHeader>
            <MainBlockHeaderTitle>{data.strongSides.title}</MainBlockHeaderTitle>
          </MainBlockHeader>
          <MainBlockText>
            {data.strongSides.description}
          </MainBlockText>

          <List>
            {data.strongSides.texts!.map(item =>
              //@ts-ignore
              <Item>{item}</Item>
            )}
          </List>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.weakSides.title)}`}>
          <MainBlockHeader>
            <MainBlockHeaderTitle>{data.weakSides.title}</MainBlockHeaderTitle>
          </MainBlockHeader>
          <MainBlockText>
            {data.weakSides.description}
          </MainBlockText>

          {
            weakSidesItems.length > 0
              ? <>
                  <MoreButton onClick={() => onOpen3Level(weakSidesItems, undefined, 'personality_weaknesses', userId, 'Личность')}>Подробнее <MoreSvg /></MoreButton>
                  <Grid>
                    {weakSidesItems.map(item =>
                        //@ts-ignore
                        <Cell icon={utfIcon(item.icon)} title={item.title} onClick={() => onOpen3Level(weakSidesItems, hashCode(item.title), 'personality_weaknesses', userId, 'Личность')} />
                    )}
                  </Grid>
                </>
              : <Stub/>
          }
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.situations.title)}`}>
          <MainBlockHeader>
            <MainBlockHeaderTitle>{data.situations.title}</MainBlockHeaderTitle>
          </MainBlockHeader>
          <MainBlockText>
            {data.situations.description}
          </MainBlockText>

          <MoreButton onClick={() => onOpen3Level(data.situations.texts, undefined, 'personality_self_expression', userId, 'Личность')}>Подробнее <MoreSvg /></MoreButton>

          <Grid>
            {data.situations.texts!.map(item =>
              //@ts-ignore
              <Cell icon={utfIcon(item.icon)} title={item.title} onClick={() => onOpen3Level(data.situations.texts, hashCode(item.title), 'personality_self_expression', userId, 'Личность')} />
            )}
          </Grid>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.talents.title)}`}>
          <MainBlockHeader>
            <MainBlockHeaderTitle>{data.talents.title}</MainBlockHeaderTitle>
          </MainBlockHeader>
          <MainBlockText>
            {data.talents.description}
          </MainBlockText>

          <List>
            {data.talents.texts!.map(item =>
              //@ts-ignore
              <Item>{item}</Item>
            )}
          </List>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.values.title)}`}>
          <MainBlockHeader>
            <MainBlockHeaderTitle>{data.values.title}</MainBlockHeaderTitle>
          </MainBlockHeader>
          <MainBlockText>
            {data.values.description}
          </MainBlockText>

          <div>
            {data.values.texts!.map(item =>
              //@ts-ignore
              <Item>{item}</Item>
            )}
          </div>
        </MainBlock>

        <SignUpConsultationBanner />

        <PersonFeedback />

        <BackButton onClick={onNext} down>
          Перейти к финансам и карьере
          <BackSvg />
        </BackButton>

      </Container>

      <ToTopButton containerRef={containerRef}/>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`
