import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { Button, Indi } from "src/libs"
import styled, { css } from "styled-components"
import { HalfMoonIcon } from "../../../../assets/icons/astrology"
import { MyDayBadIcon, MyDayGoodIcon } from "../../../../assets/icons/myday"
import { ChevronRightRoundBold } from "../../../../assets/icons/system/system-icons"
import Overlay from "../../../../components/Overlay"
import { FlexContainer } from "../../../../components/layout/elements"
import { TButtonColor } from "../../../../components/ui/Button/Button"
import { List } from "../../../../components/ui/List"
import { WidgetsAccordion } from "../../../../components/ui/WidgetsAccordion"
import { gtmSend } from "../../../../helpers/url"
import { IEvents } from "../../../../store/Events"
import { Sections } from "../../../../store/Shutter"
import store from "../../../../store/Store"
import { WidgetAstroEvents } from "../../../astro-events/WidgetAstroEvents"
import { MyDayFeedback } from "../../../my-day/components/my-day-feedback"
import { openMyDay } from "../../../my-day/my-day"
import MissingDateOrPayment from "../../../subscription/MissingDateOrPayment"
import { MainPageWidgetMoon } from "../main-page-widget-moon"
import { openPaymentSubscription } from "src/modules/payment/components/payment-subscription"
import { openSubscribeBanner } from "src/modules/subscription/SubscribeBanner/SubscribeBanner"


interface ItemProps {
  className?: string
  events: IEvents
  day: string
  todoRef: React.RefObject<HTMLDivElement>
  index: number
}
interface IErrorContent {
  title: string
  buttonTitle?: string
  buttonColor?: TButtonColor
  onClick?: () => void
}

const MOCK_TODO = [
  "Информация недоступна, продлите подписку",
  "Доступ ограничен, оформите подписку",
  "Оформите подписку для просмотра информации",
  "Разблокируйте доступ, оформив подписку",
  "Подписка истекла, продлите для доступа",
]
const MOCK_NOT_TODO = [
  "Скрыто, оформите подписку",
  "Доступ заблокирован, оформите подписку",
  "Оформите подписку для просмотра",
  "Скрыто, оформите подписку",
  "Информация доступна только по подписке",
]

export const ActiveSubscriptionScreenItem = observer((props: ItemProps) => {
  const {
    className,
    events,
    day,
    todoRef,
    index,
  } = props

  const [moonDataError, setMoonDataError] = useState(false)

  const textRef = useRef<HTMLDivElement>(null)

  const percent = store.events.percents[dayjs(day).format('DD.MM.YYYY')]
  const userId = store.sessionData?.id
  const stub = store.events.events[Object.keys(store.events.events).reverse()[0]]
  const _events = events ?? stub
  const isTrialEnded = store.events.status === 'pause'
  const expiryDate = store.sessionData?.indi?.expiryDate
  const isExpiryDay = dayjs(expiryDate).startOf('day') < dayjs(day).startOf('day')
  const astroEventsStore = store.astroEvents.astroEvents

  const widgets = () => {
    const astroEvents = []

    let key: keyof Indi.GetAstroEvents.AstroEvents
    for (key in astroEventsStore[day]) {
      const astroEvent = astroEventsStore[day][key] as Indi.GetAstroEvents.PlanetEvents & Indi.GetAstroEvents.Interval

      const pushWidget = (key: keyof Indi.GetAstroEvents.AstroEvents) => {
        if (key === Indi.GetAstroEvents.AstroEventsKey.ECLIPSE_CORRIDOR) {
          if (
            astroEvent?.from &&
            astroEvent?.to &&
            dayjs(day).isAfter(dayjs(astroEvent?.from).startOf('day')) &&
            dayjs(day).isBefore(dayjs(astroEvent?.to))
          ) {
            return true
          } else {
            return false
          }
        }

        if (
          dayjs(astroEvent?.retro?.from || astroEvent?.from).diff(dayjs(day), 'day') < 15 &&
          dayjs(day).isBefore(dayjs(astroEvent?.staticAfter?.to || astroEvent?.to).endOf('day'))
        ) {
          return true
        }

        return false
      }

      if (pushWidget(key)) {
        astroEvents.push({...astroEvent, key})
      }
    }

    astroEvents.sort((w1, w2) => {
      const from1 = dayjs(w1?.retro?.from || w1?.from).millisecond()
      const from2 = dayjs(w2?.retro?.from || w2?.from).millisecond()

      return from1 - from2
    })

    return [
      <MainPageWidgetMoon
        day={day}
        onClick={() => setMoonDataError(true)}
      />,
      ...astroEvents.map(astroEvent =>  (
        <WidgetAstroEvents
          day={day}
          type={astroEvent.key}
          retro={astroEvent?.retro}
          staticBefore={astroEvent?.staticBefore}
          staticAfter={astroEvent?.staticAfter}
          eclipse={astroEvent?.from && astroEvent?.to ? {from: astroEvent?.from, to: astroEvent?.to} : undefined}
        />
      ))
    ]
  }

  useEffect(() => {
    if (!percent) {
      store.events.loadMonth(day)
    }

    store.astroEvents.getAstroEvents(day)
  }, [percent, day])

  useEffect(() => {
    if (isExpiryDay) {
      gtmSend({ 'event': 'indi_main_inactive-period-shown', 'user_id': userId ? userId : '' })
    }
  }, [isExpiryDay, userId])

  useEffect(() => {
    if (textRef.current && index === 0) {
      store.onboarding.setActiveSubscriptionScreenTextRef(textRef)
      store.onboarding.setWidgetsLength(widgets.length)
    }
  }, [index, textRef, widgets.length])

  const goToEvents = (showEvents?: boolean) => {
    showEvents && store.events.setShowEvents(true)
    !isTrialEnded && openMyDay(dayjs(day))
    store.shutter.setActiveSection(Sections.MY_EVENTS)
  }

  const onClickPercent = () => {
    gtmSend({ 'event': 'ind_prognosis_main-page_persent-click', 'user_id': userId ? userId : '' })
    goToEvents()
  }

  const onClickLearnMore = () => {
    if (isTrialEnded) return openSubscribeBanner()
    gtmSend({ 'event': 'ind_prognosis_main-page_learn-more-click', 'user_id': userId ? userId : '' })
  }

  const onClickGoToEvents = () => {
    if (isTrialEnded) return openSubscribeBanner()
    gtmSend({ 'event': 'ind_prognosis_main-page_go-to-events-click', 'user_id': userId ? userId : '' })
    goToEvents(true)
  }

  const onClickOnTodo = () => openSubscribeBanner()

  const Icon = store.events.getIcon(dayjs(day))

  const getErrorContent = (): IErrorContent | null => {
    if (moonDataError) {
      return {
        title: 'Данные по лунному календарю на выбранную дату пока недоступны',
        buttonTitle: 'Вернуться на главную',
        buttonColor: 'black',
        onClick: () => setMoonDataError(false),
      }
    }

    if (isExpiryDay) {
      return {
        title: 'События на оплаченный период закончились',
        buttonTitle: 'Продлить подписку',
        buttonColor: 'gradient',
        onClick: openPaymentSubscription,
      }
    }

    return null
  }
  const errorObj = getErrorContent()

  return (
    <Container
      className={className ?? ''}
      background={store.events.getColor(dayjs(day)).background}>

      {
        (!events || moonDataError) && errorObj && (
          <StyledOverlay>
            <MissingDateOrPayment
              title={errorObj!.title}
              buttonTitle={errorObj!.buttonTitle}
              buttonColor={errorObj!.buttonColor as TButtonColor}
              onClick={errorObj!.onClick} />
          </StyledOverlay>
        )
      }

      <Description background={store.events.getColor(dayjs(day)).highligh}>
        {Icon && <Icon className={'potential-day_icon'}/>}
        <Percent
          className={'potential-day_percent'}
          onClick={onClickPercent}
          color={store.events.getColor(dayjs(day)).color}
        >
          Потенциал дня {percent}%
        </Percent>

        <ClickArea onClick={() => goToEvents()}>
          <Text ref={index === 0 ? textRef : undefined}>
            Ваш день будет наполнен {_events?.day?.atmosphere}
          </Text>
          <StyledButton
            onClick={() => { }}
            color='transparentWithBorder'>
            <ButtonContent onClick={onClickLearnMore}>Узнать прогноз <ChevronRightRoundBold /></ButtonContent>
          </StyledButton>
        </ClickArea>
      </Description>

      <WidgetsAccordion
        widgets={widgets()}
        title='Астрособытия'
        icon={<HalfMoonIcon/>}
        index={index}
        isBlur={isTrialEnded}
      />

      <Todo ref={todoRef}>
        {isTrialEnded ? (
          <>
            <List
              icon={<MyDayGoodIcon />}
              title={'Делайте сегодня'}
              items={MOCK_TODO}
              isBlur={true}
              onClick={onClickOnTodo}
            />
            <List
              icon={<MyDayBadIcon />}
              title={'Избегайте'}
              items={MOCK_NOT_TODO}
              isBlur={true}
              onClick={onClickOnTodo}
            />
          </>
        ) : (
          <>
            {_events?.day?.toDo && _events?.day?.toDo?.length > 0 && (
              <List
                icon={<MyDayGoodIcon />}
                title={'Делайте сегодня'}
                items={_events.day.toDo}
              />
            )}

            {_events?.day?.toDo && _events?.day?.notToDo?.length > 0 && (
              <List
                icon={<MyDayBadIcon />}
                title={'Избегайте'}
                items={_events.day.notToDo}
              />
            )}
          </>
        )}
      </Todo>

      <StyledButton
        onClick={onClickGoToEvents}
        color='transparentWithBorder'>
        <ButtonContent>Перейти к событиям <ChevronRightRoundBold /></ButtonContent>
      </StyledButton>

      <StyledMyDayFeedback
        date={day}
        feedback={_events?.feedback}
        hasFeedback={!!_events?.feedback} />
    </Container>
  )
})

const Container = styled(FlexContainer) <{ background: string }>`
  flex-direction: column;
  align-items: center;

  padding: 4rem 1rem 4rem 1rem;
  background: ${p => `url(${p.background}) 0 0/cover no-repeat`};
`

const Description = styled(FlexContainer)<{ background: string }>`
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  svg {
    width: 2rem;
    height: 2rem;
  }

  .onboarding_text {
    z-index: var(--z-index-onboarding-showed-element);
    ${p => p.background && css`
      background: ${p.background};
      border-radius: 20px;
      margin: 1rem;
      padding: 1rem;
      pointer-events: none;
    `}
  }
`

const ClickArea = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
`

const Percent = styled.div<{ color: string }>`
  background-color: var(--color-white);
  border-radius: 50px;
  padding: 0.5rem 0.75rem;
  margin-top: 1.25rem;
  color: ${p => p.color};
`

const Text = styled.div`
  position: relative;
  color: var(--text-secondary);
  text-align: center;
  margin-top: 1rem;
  padding: 0 1rem;
  line-height: 24.3px;
  font-size: 1rem;
  font-weight: 400;
`

const StyledButton = styled(Button)`
  border: 1px solid var(--text-third);
  border-radius: 50px;
  margin: 1.5rem 0;
  padding: 0.25rem 0.15rem 0.25rem 1rem;
`

const ButtonContent = styled(FlexContainer)`
  align-items: center;

  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 500;
`

const Todo = styled.div`
  margin-top: 1.5rem;

  & > :first-child {
    margin-bottom: 1.5rem;
  }
`

const StyledMyDayFeedback = styled(MyDayFeedback) <{ hasFeedback: boolean }>`
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  ${p => p.hasFeedback && css`
    text-align: center;
  `}
`

const StyledOverlay = styled(Overlay)`
  left: auto;
  top: 4rem;
  backdrop-filter: brightness(0.9) blur(3px);
  align-items: start;

  .overlay-sibling {
    background: linear-gradient(180deg, #F3F7EF 0%, #FFFFFF 100%);
  }
;
`
